<script>
import { cinqueStagioniBasicMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { RecaptchaV3 } from "src/modules/recaptcha_v3_module.js";


export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  computed: {
    showNewsletter() {
      return globalState.showNewsletter;
    },
  },
  methods: {
    recaptchaIsActive() {
      return !!globalState.pageModel.aux.recaptcha_enterprise;
    },
    showElmoBanner() {
      Elmo.show();
    }
  }
};
</script>

<template>
  <div class="bg-white" footer-component>
    <stripe-newsletter :content="content" v-if="showNewsletter"></stripe-newsletter>
    <div class="container">
      <div class="row m-0 py-4 my-1 justify-content-md-between flex-column-reverse flex-md-row">
        <div class="col-12 col-md-8 px-0 d-flex flex-column flex-md-row flex-md-wrap align-items-center">
          <div v-for="(item, index) in content.menu" class="my-2" :key="`url-${index}`">
            <a :href="applyContextToUrl(item.url.url)" :target="getTarget(item.url)" class="link-black mr-3 font-sm"> {{item.url.title}} </a>
          </div>
          <div class="my-2">
            <a href="javascript:void(0)" class="link-black mr-3 font-sm" @click="showElmoBanner()">{{ ft("globals.footer.edit_cookies_preferences") }}</a>
          </div>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-md-end align-items-center justify-content-center mb-3 mb-md-0 px-0">
          <div v-for="(social, index) in content.social_icons" class="ml-md-4 mx-2 px-1 mr-md-0 mr-md-0" :key="`icon-${index}`">
            <a
              v-if="social.url"
              :href="social.url.url"
              :target="getTarget(social.url)"
              class="link-black social-icon"
            >
              <i v-if="social.icon" :class="social.icon" class="fab"></i>
            </a>
          </div>
        </div>
        <div class="col-12 px-0 pt-1">
          <p class="text-light-grey" v-if="recaptchaIsActive()">
            <small class="font-xs">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[footer-component] {

  position: relative;
  z-index: 5;

  .social-icon {

    @include font-size(1.375rem);

    @include media-breakpoint-down(sm) {
      @include font-size(2.5rem);
    }
  }

}
</style>
