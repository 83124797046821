<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    mayToggleVideoPopup() {
      if (this.hasPlayInteraction) {
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
      }
    },
    playVideo() {
      if (this.hasVideo) {
        this.$refs["video"].muted = true;
        this.$refs["video"].play();
      }
    },
    pauseVideo() {
      if (this.hasVideo) {
        this.$refs["video"].pause();
      }
    }
  },
  computed: {
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    hasMedia() {
      return Fandom.exists(this.content.image) || this.hasVideo;
    },
    hasVideo() {
      return Fandom.exists(this.content.video);
    },
    hasPlayInteraction() {
      return Fandom.exists(this.content.play);
    },
    height() {
      return get(this, ["content", "height"], "");
    },
    aspectRatioClasses() {
      return this.height.indexOf("$") === 0 ? this.height.replace("$", "ar-") : "h-100 ar-sm-1by1";
    },
    containerStyle() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : false
      };
    },
    containerClasses() {
      const paddingY = get(this, ["content", "padding_y"], 0);
      return [
        `py-${paddingY}`,
        this.propagateBackground ? "themed-item" : ""
      ];
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    contentClasses() {
      const mobileDirection = this.content.reverse_mobile ? "flex-column-reverse" : "flex-column";
      const desktopDirection = this.content.reverse ? "flex-lg-row-reverse" : "flex-lg-row";
      return [
        mobileDirection,
        desktopDirection
      ];
    },
    contentStyle() {
      const height = this.height.indexOf("$") !== 0 ? this.height : null;
      return {
        "min-height": height,
        "background-image": this.propagateBackground ? false : this.backgroundImage
      };
    },
    textColumnWidth() {
      const size = get(this, ["content", "text_column_width"], 6);
      return parseInt(size);
    },
    mediaColumnClasses() {
      const mediaClasses = this.hasMedia ? "" : "d-none d-lg-block";
      const playClasses = this.content.play ? "cursor-pointer" : "";
      return `col-lg-${12 - this.textColumnWidth} ${mediaClasses} ${playClasses}`;
    },
    imageInsetWidth() {
      const size = get(this, ["content", "image_inset_width"], 12);
      return `col-lg-${size} col-sm-8`;
    },
    textColumnClasses() {    
      return `col-lg-${this.textColumnWidth}`;
    },
    mediaImage() {
      return this.getContentResponsiveImageURL("image");
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    containerClass() {
      const propagateContainer = get(this, ["content", "propagate_container"], false);
      return propagateContainer ? "container-fluid" : "container";
    }
  }
};
</script>

<template>
  <intersect @enter="playVideo" @leave="pauseVideo">
    <div v-easyadmin="easyadminId" :class="contentType" :style="theme" v-if="isContentVisibleToUser()" two-col-component :id="anchorId">
      <div class="w-100 container-bg" :class="containerClasses" :style="containerStyle">
        <div class="position-relative overflow-hidden" :class="containerClass">
          <div class="row bg-cover-center themed-item" :class="contentClasses" :style="contentStyle">
            <div class="col-12 p-0 media-column" :class="mediaColumnClasses" @click="mayToggleVideoPopup">
              <div class="row h-100 align-items-center justify-content-center">
                <div class="col-12 h-100 d-flex align-items-center" :class="imageInsetWidth">
                  <div v-if="hasVideo" :class="aspectRatioClasses">
                    <div class="video-1by1 position-absolute-center">
                      <video loop webkit-playsinline playsinline ref="video">
                        <source :src="content.video.url" type="video/mp4">
                      </video>
                    </div>
                  </div>
                  <div
                    v-if="mediaImage"
                    class="bg-cover-center w-100 position-relative"
                    :title="content.image.alt"
                    :style="{'background-image': mediaImage}" :class="aspectRatioClasses"
                    >
                    <span class="position-absolute-center d-flex flex-column justify-content-center align-items-center" v-if="hasPlayInteraction">
                      <i class="fal fa-play-circle play-icon"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center py-4 text-column" :class="textColumnClasses">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-10 px-4">
                  <text-section :content="content" :opt-container-content="containerContent" @open-video-popup="mayToggleVideoPopup"></text-section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </intersect>
</template>

<style lang="scss" scoped>
.play-icon {
  font-size: 8rem;
}

.container-bg {
  background-size: cover;
  background-position: center top;
}

.ar-8by7 {
  padding-bottom: calc(7 / 8 * 100%);
}

/* Forced square rappresentation on mobile */
@include media-breakpoint-down(md) {
  .ar-16by9,
  .ar-4by3,
  .ar-sm-1by1 {
    padding-bottom: 100%;
  }
}

/* IE11 hack */
@include ie {
  > div > .row {
    max-height: 1080px;
  }

  .media-column,
  .text-column {
    min-height: inherit;
    max-height: 1080px;
  }
}
</style>
