<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    getUserMainRewardCount: Fandom.getUserMainRewardCount
  },
  computed: { 
    user() {
      if (this.isUserRegistered()) {
        return globalState.pageModel.user;
      }
    },
    userAvatar() {
      return Fandom.expandUrl(get(this.user, ["avatar", "url"], ""));
    },
    noticesPageUrl() {
      return get(globalState, ["pageModel", "layout", "content", "notices_page", "url"], "javascript: void(0)")
    },
    noticesPageTarget() {
      return Fandom.getTarget(get(globalState, ["pageModel", "layout", "content", "notices_page"], null))
    },
    profilePageUrl() {
      return get(globalState, ["pageModel", "layout", "content", "profile_page", "url"], "javascript: void(0)")
    },
    profilePageTarget() {
      return Fandom.getTarget(get(globalState, ["pageModel", "layout", "content", "profile_page"], null))
    },
  },
};
</script>

<template>
  <div navbar-user-component>
    <div v-if="isUserRegistered()" class="d-flex align-items-center">
      <div class="mr-3">
        <a :href="profilePageUrl" :target="profilePageTarget" class="deco-none">
          <div class="navbar-user-avatar bg-cover-center d-flex align-items-center justify-content-center" :style="{'background-image': `url(${userAvatar})`}">
            <i v-if="!userAvatar" class="fal fa-user"></i>
            <a v-if="user.unviewed_notices_count" :href="noticesPageUrl" :target="noticesPageTarget">
              <div  class="navbar-user-notices-counter d-flex align-items-center justify-content-center">{{user.unviewed_notices_count}}</div>
            </a>
          </div>
        </a>
      </div>
      <h3 class="h5 mb-0 mr-4 d-none d-md-block">{{user.first_name}} {{user.last_name}}</h3>
      <div class="px-2 px-3 py-1 d-inline-block navbar-user-marker text-center">
        <span>{{getUserMainRewardCount(content, containerContent)}} <i class="fal fa-star"></i></span>
      </div>
    </div>
    <div class="d-flex align-items-center jusitfy-content-end" v-else>
      <h3 class="mb-0 mr-4">{{ft("globals.take_part")}}</h3>
      <a :href="applyContextToUrl('/users/sign_up')" rel="nofollow" class="btn btn-primary btn-xs">Entra</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[navbar-user-component] {

  .navbar-user-notices-counter {
    width: 1rem;
    height: 1rem;
    background-color: $red;
    color: $white;
    border-radius: 100%;
    @include font-size(0.5rem);
    position: absolute;
    left: 0;
    top: 0;
  }

  .navbar-user-marker {
    border-radius: 2rem;
    min-width: 5rem;
    z-index: 10;
    position: relative;
    white-space: nowrap;

    background-color: $primary;
    color: $white;
  }

  .navbar-user-avatar {
    position: relative;
    width: 3rem; 
    height: 3rem; 
    border-radius: 100%;
    background-color: $gray-300;
    @include font-size(1.5rem);
  }
}
</style>

