<script>

export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  },
  data() {
    return {
      content: this.item,
    };
  },
  methods: {
  },
  computed: {}
};
</script>

<template>
  <div class="p-2 d-flex align-items-center w-100" autocomplete-item-ingredient-component>
    <div class="w-100">
      {{item.title}}
    </div>
  </div>
</template>


<style lang="scss">
[autocomplete-item-ingredient-component] {


}
</style>
