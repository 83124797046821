<script>
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getContentURL: Fandom.getContentURL,
  },
  mounted() {
    Vue.nextTick(() => {
      $(this.$el).magnificPopup({type: "image", delegate: "a"});
    });
  },
  computed: {
    isContentLocked() {
      return this.content.reward_status === "locked";
    },
    textAlign() {
      return (this.content.text_align) ? `text-${this.content.text_align}` : null;
    },
    tileUrl() {
      return Fandom.exists(this.content.thumbnail) ? Fandom.expandUrl(this.content.thumbnail.url) : "";
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="position-absolute-center bg-transition" tile-popup-component>
    <a :href="tileUrl" :target="getTarget(content.url)" class="deco-none position-absolute-center" v-if="!isContentLocked">
      <div class="bg-cover-center position-absolute-center bg" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
      <div v-if="!content.hide_gradient" class="position-absolute-center linear-gradient-y"></div>
      <reward-marker class="m-3" :content="content" :container-content="containerContent"></reward-marker>
      <div class="tile-footer p-3 row" :class="textAlign">
        <div class="col-12">
          <miniformat-badge v-if="decorator && !content.category" :miniformat="decorator" class="mb-2"></miniformat-badge>
          <div v-if="content.category" class="category text-uppercase font-weight-bold my-1">{{content.category}}</div>
          <div class="tile-footer-img-container" v-if="content.icon">
            <img class="mb-2 tile-footer-img" :src="content.icon.url">
          </div>
          <span class="d-inline-block h5 m-0">{{content.title}}</span>
          <p class="mb-0" v-if="content.abstract" v-html="content.abstract"></p>
        </div>
        <div class="d-flex flex-column justify-content-center col-12 mt-3" v-if="content.button">
          <a role="button" class="btn" :class="getPrimaryButtonClass(content.button_class)" :href="content.button.url">{{content.button.title}}</a>
        </div>
      </div>
    </a>
    <reward-layer-tile :content="content" class="lock-layer w-100 h-100" v-else></reward-layer-tile>
  </div>
</template>

<style lang="scss" scoped>
[tile-popup-component] {
  color: $white;

  .category {
    @include font-size(.75rem);
  }

  [reward-marker-component] {
    position: absolute;
    top: 0;
    right: 0;
  }

  .tile-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .tile-footer-img {
      width: 5rem;
    }
  }
}
</style>


