<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  mounted() {
    this.registerAnimation();
  },
  computed: {
    containerStyles() {
      return {
        "color": this.content.text_color
      };
    },
    centered() {
      return get(this, ["content", "centered"], false);
    },
    rowAlignment() {
      return this.centered ? "justify-content-center" : "justify-content-start";
    },
    alignment() {
      return this.centered ? "align-items-center" : "align-items-start";
    },
    titleTag() {
      return get(this, ["content", "title_tag"], "h2");
    },
    wrapperStyles() {
      return {
        "--min-height": get(this, ["content", "height"], false)
      };
    },
    titleClasses() {
      const size = get(this, ["content", "title_class"], "h1");
      const color = get(this, ["content", "title_color"], "white");
      return [size, `text-${color}`];
    }
  }
};
</script>

<template>
  <div :id="content.name" v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" class="bg-cover-center" :style="{'background-image': getContentResponsiveImageURL('background_image')}" main-banner-component>
    <div class="container" :style="containerStyles">
      <div class="row" :class="rowAlignment">
        <div class="col-12 col-md-8">
          <div class="content-wrapper w-100 p-3 p-md-4 d-flex flex-column justify-content-center" :class="alignment" :style="wrapperStyles">
            <component :is="titleTag" class="text-uppercase" :class="titleClasses">{{content.title}}</component>
            <div class="banner-description" v-html="content.description" :class="{'text-center': centered}"></div>
            <a class="btn mt-4" v-if="content.button" :class="content.button_class || 'btn-red'" :href="applyContextToUrl(content.button.url)">{{content.button.title}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-wrapper {
  --min-height: 28rem;
  min-height: 100vw;

  @include media-breakpoint-up(md) {
    min-height: var(--min-height);
  }
}

.banner-description {
  @include media-breakpoint-up(md) {
    @include font-size(2.125rem);
  }
}
</style>
