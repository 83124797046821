<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data() {
    return {
      gridContent: null,
    };
  },
  mounted() {
    if (this.content.grid_content) {
      Fandom.getContents(
        this.content.grid_content,
        {
          order_by: "distance",
          sorting_order: "asc",
          lat: this.content.lat,
          lng: this.content.lng,
          limit: 3,
          exclude_content_ids: [this.content.id],
        },
        data => {
          console.log(data);
          const gridContent = get(data, ["name_to_content", this.content.grid_content], false);
          if (gridContent) {
            if (this.content.view_more_url) {
              gridContent.url = this.content.view_more_url;
              gridContent.url.url = `${this.content.view_more_url.url}?lat=${this.content.lat}&lng=${this.content.lng}`;
            }
            Vue.set(this, 'gridContent', gridContent);
          }
        }
      );
    }
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
  },
  computed: {
    headerBackground() {
      const url = Fandom.retrieveFromGlobalState("recipe_header_background", false);
      return url ? `url("${url.url}")` : null;
    },
    aboutOwner() {
      return get(this, ["content", "about_owner"], this.ft("pizzeria.about_owner"));
    },
    share() {
      return get(this, ["content", "share"], false);
    },
    imageUrl() {
      return this.getContentResponsiveImage("image").replace("/original/", "/medium/");
    },
    owner() {
      return get(this, ["content", "owner"], {});
    },
    ownerAvatar() {
      return get(this, ["owner", "avatar"], false);
    },
    ownerPage() {
      const url = get(this, ["content", "owner", "page", "url"], false);
      return url ? Fandom.applyContextToUrl(url) : false;
    },
    ownerTag() {
      return this.ownerPage ? "a" : "div";
    },
    info() {
      return [
        {
          icon: "fas fa-map-marker-alt",
          value: this.content.address
        },
        {
          icon: "fas fa-phone",
          value: this.content.phone ? `<a class="text-black" href="tel:${this.content.phone}">${this.content.phone}</a>` : false
        },
        {
          icon: "fas fa-envelope",
          value: this.content.email ? `<a class="text-black" href="mailto:${this.content.email}">${this.content.email}</a>` : false
        },
        {
          icon: "fas fa-globe",
          value: this.content.website ? `<a class="text-black" target="${this.getTarget(this.content.website)}" href="${this.content.website.url}">${this.content.website.title}</a>` : false
        },
        {
          icon: "fas fa-clock",
          value: this.content.opening_hours
        }
      ].filter(item => !!item.value);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="[contentType]" pizzeria>
    <div class="container pt-4">
      <div class="row">
        <div class="col-12">
          <breadcrumb class="px-0"></breadcrumb>
        </div>
      </div>
    </div>
    <div class="w-100 bg-light-red py-md-3 bg-cover-top" :style="{'background-image': headerBackground}">
      <div class="container py-3">
        <div class="row justify-content-between">
          <div class="pizzeria-info col-12 col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1">
            <div class="row align-items-center justify-content-between px-3 pr-md-0">
              <div class="category-wrapper d-inline-flex align-items-center col-auto px-0 mb-3" v-if="content.location">
                <div class="font-xs text-white border border-white py-2 px-3 rounded">{{content.location}}</div>
              </div>
              <div class="pizzeria-info-title col-12 py-3 pl-0">
                <h1 class="h4 text-white">{{content.title}}</h1>
              </div>
              <component
                :is="ownerTag"
                :href="ownerPage"
                class="pizzeria-info-owner d-flex align-items-center pl-0 col-12"
                :class="ownerPage ? 'link-white' : 'text-white'"
                v-if="owner"
                >
                <div v-if="owner.avatar" class="owner-avatar rounded-circle bg-cover-center mr-3" :style="{'background-image': getBackgroundUrl(owner.avatar.url)}"></div>
                <div class="owner-name">
                  {{ft('pizzeria.owner')}}:<br>
                  <span class="font-weight-bold">{{owner.first_name}} {{owner.last_name}}</span>
                </div>
              </component>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-xl-7 pl-lg-0 order-1 order-lg-2">
            <div v-if="content.image" class="main-img position-relative rounded overflow-hidden w-100">
              <img :src="content.image.url" class="pizzeria-main-image rounded w-100 h-100 position-absolute" :title="content.title" :alt="content.title" />
            </div>
            <div v-else class="pizzeria-main-image mw-100 position-absolute ar-16by9 bg-light-grey rounded">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-3 mt-4">
      <div class="row justify-content-between"> 
        <div class="col-12 col-lg-4 mb-auto">
          <div class="pizzeria-info premium-variable-bg-transparent rounded py-3 py-lg-4">
            <div class="col-12 info-head premium-variable-text cursor-pointer px-0">
              <h3 class="font-xl px-4">{{ft("pizzeria.info")}}</h3>
            </div>
            <ul v-if="info.length > 0" class="info p-0 mt-3 mx-3">
              <li
                v-for="(info, index) in info"
                class="d-block ingredient font-lg border-bottom premium-variable-border pt-2 pl-2"
                :class="{'border-top': index === 0}"
                :key="`ingredient-${index}`"
                >
                <div class="py-2 d-flex">
                  <i class="text-red mr-3" :class="info.icon"></i> 
                  <strong v-html="info.value"></strong>
                </div>
              </li>
            </ul>
            <share-icons class="px-4" v-if="content.share" share-record-path="content.share" :content="content" :containerContent="containerContent"></share-icons>
          </div>
        </div>
        <div class="pizzeria-main-content align-self-start col-12 col-lg-8 col-xl-7 pl-lg-0 mt-4 mt-md-0">
          <div class="row"> 
            <div class="col-12">
              <component
                :is="stripe.layout"
                :content="stripe"
                v-for="stripe in content.stripes"
                :key="stripe.$id"
                :opt-container-content="containerContent"
              ></component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="owner" class="container-fluid pizzeria_quote text-white" :style="{'background-image': headerBackground}">
      <div class="row mx-0 py-4 justify-content-center">
        <div class="col-12 col-md-8 d-flex flex-column justify-content-center align-items-center py-2">
          <div 
            v-if="ownerAvatar" 
            class="quote-avatar rounded-circle mt-1 mb-3 bg-cover-center"
            :style="{'background-image': getBackgroundUrl(ownerAvatar.url)}"
          ></div>
          <div class="text-uppercase text-center pb-3 mb-0 h6">{{aboutOwner}}:<br>{{owner.first_name}} {{owner.last_name}}</div>
          <div class="d-block text-center quote-description mb-3" v-if="owner.description" v-html="owner.description"></div>
        </div>
      </div>    
    </div>
    <component :hide-load-more="true" class="py-4 py-md-5" v-if="gridContent" :is="gridContent.layout" :content="gridContent"></component>
  </div>
</template>

<style lang="scss" scoped>
$icon-size: 2.625rem;

.main-img {
  padding-bottom: 57.6%;
}

.pizzeria-main-image {
  object-fit: cover;
  top: 0;
  left: 0;
}

.category-wrapper {
  line-height: 1;
}

.owner-avatar {
  height: 4rem;
  width: 4rem;
}

.quote-avatar {
  width: 6rem;
  height: 6rem;
}

.quote-description {
  @include font-size(1.375rem);
  line-height: 1.45;
}
</style>
