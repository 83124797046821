<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  mounted() {
    this.registerAnimation();
  },
  methods: {
    expandUrl: Fandom.expandUrl,
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL
  },
  computed: {
    centered() {
      return get(this, ["content", "centered_column"], true);
    },
    columnClasses() {
      const columnWidth = Fandom.exists(this.content.width) ? this.content.width : Fandom.retrieveFromGlobalState("one_col_text_width", 10);
      return [
        `col-12 col-md-${columnWidth}`,
        `text-${get(this, ["content", "text_align"], "left")}`
      ];
    },
    containerClasses() {
      const classes = [];

      if (this.content.background_fixed && !(globalState.viewport.sm || globalState.viewport.xs || globalState.viewport.md)) {
        classes.push("bg-fixed");
      } else {
        classes.push("bg-cover-center");
      }

      classes.push(this.content.padding_y ? `py-${this.content.padding_y}` : "py-4");
      classes.push(this.content.theme ? `${this.content.theme}-theme` : "light-theme");

      return classes;
    },
    containerStyles() {
      return {
        "background-color": this.content.background_color,
        "min-height": this.content.height,
        "color": this.content.text_color
      };
    },
    leftPadding() {
      return !(Fandom.exists(this.content.without_description_indent) && this.content.without_description_indent);
    },
    outerStyle() {
      const style = {};
      if (!!this.content.outer_background_color) {
        style['background-color'] = this.content.outer_background_color;
      }
      if (!!this.content.outer_background_image) {
        style['background-image'] = this.getContentResponsiveImageURL('outer_background_image');
      }
      return style;
    }
  },
};

</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" :style="outerStyle" class="inview_animate bg-cover-center" one-col-component>
    <span v-if="content.stripe_id" class="anchor" :id="content.stripe_id"></span>
    <div class="google-seo-height d-flex flex-column container px-3 px-sm-0 justify-content-center position-relative overflow-hidden" :style="containerStyles" :class="containerClasses">
      <div class="position-absolute-center bg-cover-center w-100 h-100 inview_animate_zoom" :style="{'background-image': getContentResponsiveImageURL('background_image')}"></div>
      <div class="row" :class="{'justify-content-center': centered}">
        <div :class="columnClasses">
          <stripe-header :content="content" v-if="content.title_composed"></stripe-header>
          <text-section :content="content" :opt-container-content="containerContent" :left-padding="leftPadding"></text-section>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[one-col-component] {

  /*TODO: to remove after fixed in variables file*/
  $secondary-nav-height: 48px;

  .dark-theme {
    background-color: $dark;
    color: $white;
  }

  .anchor{
    display: block;
    height: calc(#{$header-height-sm} + #{$secondary-nav-height});
    margin-top:  calc(-#{$header-height-sm} - #{$secondary-nav-height});
    visibility: hidden;
  }

  [text-section-component] ::v-deep a.btn {
    padding: 1rem 2rem !important;
  }
}
</style>
