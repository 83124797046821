<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  computed: {
    titleTag() {
      return get(this, ["content", "title_tag"], "h2");
    },
    titleClass() {
      return [get(this, ["textColor"], "text-white"), get(this, ["content", "title_class"], "")];
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" stripe-landing-product-sticky-bg>
    <video :src="content.background_video.url" autoplay loop playsinline muted></video>
    <div class="over-video-content w-100 position-relative d-flex flex-column align-items-center justify-content-center">
      <div class="flex position-fixed d-flex align-items-center justify-content-center flex-column">
        <div class="d-flex logo mb-2">
          <img class="img-fluid logo" :src="content.logo.url" />
        </div>
        <component :class="titleClass" :is="titleTag">{{content.title}}</component>
        <!-- <h2 class="d-flex pt-4 text-white">{{content.title}}</h2> -->
        <div class="d-flex pt-4" v-html="content.description"></div>
      </div>
    </div>
    <div class="content-wrapper w-100 bg-cream position-relative">
      <component :is="stripe.layout" :content="stripe" v-for="(stripe) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .content-wrapper {
    z-index: 5;
  }

  .over-video-content {
    height: 100vh;
    //min-height: 800px;
    z-index: 5;
  }

  .logo {
    max-width: 200px;
    max-height: 200px;
  }

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  @include media-breakpoint-down(sm) {
    .over-video-content {
      height: calc(100vh - 6.25rem);
    }
  }



</style>
