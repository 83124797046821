<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    openModal() {
      $("#modal-registration").modal("show");
      const expire = new Date();
      expire.setMinutes(expire.getMinutes() + (this.content.expires_in || 15));
      Fandom.setCookie('registration-modal', 'hide', expire.toUTCString());
    },
    closeModal() {
      $("#modal-registration").modal("hide");
    }
  },
  computed: {
    backgroundColor() {
      return {
        'background-color': this.content.background_color || '#fff'
      }
    }
  },
  mounted() {
    Vue.nextTick(() => {
      if (!(Fandom.isUserRegistered()) && !Fandom.readCookie('registration-modal')) {
        const timeout = parseInt(this.content.delay) || 60000;
        setTimeout(() => {
          this.openModal();
        }, timeout);
      }
    });
  }
};
</script>

<template>
  <div modal-registration-component modal-components>
    <div id="modal-registration" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" :class="{'modal-lg': content.large}" role="document">
        <div class="modal-content rounded" :style="backgroundColor" v-easyadmin="easyadminId" :class="contentType">
          <div class="modal-header border-bottom-0 pb-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span class="close-icon italia-lt-font" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4 text-center">
            <div class="row justify-content-center">
              <div class="col-lg-10 pb-4">
                <h4 class="modal-title text-uppercase text-red" v-html="content.title"></h4>
                <div v-if="content.separator" class="w-100 position-relative py-3">
                  <hr class="separator-line" />
                  <div class="position-absolute-center d-flex justify-content-center align-items-center">
                    <span class="separator-text px-3" :style="backgroundColor">{{content.separator}}</span>
                  </div>
                </div>
                <div class="row" v-if="content.items">
                  <div class="col-12 py-3" v-for="(item, itemIndex) in content.items" :key="`item-${itemIndex}`">
                    <div class="row justify-content-center">
                      <div class="d-none d-md-block col-md-2 text-right">
                        <img :src="item.image.url" class="img-fluid item-img" />
                      </div>
                      <div class="col-12 col-md-10 text-left">
                        <div class="item-title" v-if="item.title" v-html="item.title"></div>
                        <div class="item-description" v-if="item.description" v-html="item.description"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="content.description" class="description" v-html="content.description"></div>
                <div v-if="content.image" class="w-100 mt-4">
                  <img :src="content.image.url" class="img-fluid" />
                </div>
                <div class="w-100 d-flex flex-column justify-content-center align-items-center mt-3">
                  <a
                    :href="content.url ? content.url.url : '/users/sign_up'"
                    :target="getTarget(content.url)"
                    class="btn mb-3 font-weight-bold px-5"
                    :class="content.button_class || 'btn-red'"
                  >
                    {{content.url ? content.url.title : ft('globals.sign_up')}}
                  </a>
                  <a href="javascript:void(0)" class="font-weight-bold link" @click="closeModal">{{ft('globals.later')}}</a>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[modal-registration-component] {
  .close-icon {
    font-family: $italia-lt-font;
  }

  .description {
    @include font-size(1.5rem);
  }

  .link {
    @include font-size(1.25rem);
  }

  .separator-line {
    border-top: 1px solid #725249;
    width: 100%;
  }

  .separator-text {
    @include font-size(1rem);
  }

  .item-img {
    max-width: 3rem;
  }

  .item-title {
    font-family: $italia-lt-font;
    @include font-size(1.75rem);
    font-weight: bold;
  }
}
</style>