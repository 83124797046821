<script>
import { cinqueStagioniBasicMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [cinqueStagioniBasicMixin],
  props: ["content"],
  computed: {
    titleTag() {
      return get(this, ["content", "title_tag"], "h2");
    },
    textColor() {
      return get(this, ["content", "text_color"], "red");
    },
    titleClass() {
      return [`text-${this.textColor}`, get(this, ["content", "title_class"], "")];
    }
  }
};
</script>

<template>
  <div class="container py-sm-4 py-3" stripe-header>
    <div class="row justify-content-md-center">
      <div class="col-sm-8 text-center mx-auto">
        <div class="d-flex justify-content-center">
          <span class="text-uppercase px-2 py-1 font-sm d-flex text-white mb-3" :class="`bg-${color}`" v-if="content.category">
            {{content.category}}
          </span>
        </div>
        <component class="title-composed" :is="titleTag" v-if="content.title && !content.title_composed" :class="titleClass">
          {{content.title}}
        </component>
        <component class="title-composed" :is="titleTag" v-else-if="content.title_composed">
          <span v-for="title in content.title_composed" :class="getTitleStyle(title)">
            {{title.title}}
          </span>
        </component>
      </div>
    </div>
  </div>
</template>
