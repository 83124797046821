<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { cinqueStagioniBasicMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { capitalize, get } from "lodash";

const getFromCategory = (category, field, fallback) => get(globalState.pageModel.name_to_content, [category, field], fallback);

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    capitalize
  },
  computed: {
    owner() {
      return get(this, ["pageModel", "id_to_user", this.content.owned_by], false);
    },
    avatar() {
      return get(this, ["owner", "avatar", "url"], false);
    },
    ownerName() {
      const name = get(this, ["owner", "first_name"], false);
      return Fandom.exists(name) && name ? name : false;
    },
    ownerSurname() {
      const surname = get(this, ["owner", "last_name"], false);
      return Fandom.exists(surname) && surname ? surname : "";
    },
    ownerNickname() {
      return this.ownerName ? `${this.ownerName} ${this.ownerSurname}` : false;
    },
    categoryTitle() {
      return getFromCategory(this.content.category, "title", this.content.category);
    },
    description() {
      const abstract = get(this, ["content", "abstract"], false);
      return get(this, ["content", "description"], abstract);
    },
    accessLevel() {
      return get(this, ["content", "decorators", "access-level"], false);
    },
    accessLevelClass() {
      return this.accessLevel ? `bg-alpha-${this.accessLevel}` : "bg-alpha-red";
    }
  }
};
</script>

<template>
  <div class="my-3 py-1 position-relative" v-easyadmin="easyadminId" :class="contentType" tile-learning-component>
    <a class="position-absolute-center content-url cursor-pointer" :href="applyContextToUrl(content.slug)"></a>
    <div class="row tile-row">
      <div class="col-12 col-md-6 thumbnail">
        <div class="bg-cover-center rounded w-100 h-100 bg lazy-bg pt-3 pt-md-4" :lazy-src="getContentResponsiveImageURL('thumbnail')">
          <div v-if="content.category" class="d-flex tile-row px-3 px-md-4 text-white">
            <span :class="accessLevelClass" class="category rounded px-1 py-1 px-sm-2 py-md-2 font-sm m-0 italia-lt-font">{{capitalize(categoryTitle)}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column h-100 px-md-4 pt-3">
          <template v-if="content.title_composed || content.title">
            <div v-if="content.title_composed" class="title-composed">
              <span v-for="(titleLine, index) in content.title_composed" :class="getTitleStyle(titleLine)" :key="index" class="d-inline-block h4 m-0">
                {{titleLine.title}}
              </span>
            </div>
            <span v-else class="d-inline-block h4 m-0 text-red">{{content.title}}</span>
          </template>
          <div v-if="description" v-html="description" v-shave="{ height: 140 }" class="d-none d-md-block pt-3"></div>
          <div v-if="description" v-html="description" v-shave="{ height: 280 }" class="d-md-none pt-3"></div>
          <div class="mt-auto mb-3 d-flex align-items-center" v-if="avatar || ownerNickname">
            <div v-if="avatar" class="avatar bg-cover-center mr-2 rounded-circle" :style="{'background-image': getBackgroundUrl(avatar)}"></div>
            <div class="text-medium-grey owner-title" v-if="ownerNickname">
              By <strong>{{ownerNickname}}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$icon-size: 2.625rem;

.content-url {
  z-index: 1;
}

.thumbnail {
  min-height: 12.125rem;

  @include media-breakpoint-up(md) {
    min-height: 16.5rem;
  }
}

.avatar {
  width: $icon-size;
  height: $icon-size;
}

.owner-title {
  @include font-size(1rem);
}
</style>
