<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  computed: {
    negativeSpace() {
      const space = get(this, ["content", "negative_margin"], false);
      return space ? `-${space}rem` : space;
    },
    positiveSpace() {
      const space = get(this, ["content", "positive_margin"], false);
      return space ? `${space}rem` : space;
    },
    styles() {
      return {
        "--margin": this.negativeSpace,
        "--padding": this.positiveSpace,
        "background-color": this.content.background_color
      };
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" :style="styles" stripe-spacer-component>
  </div>
</template>

<style lang="scss">
[stripe-spacer-component] {
  --margin: 0;
  --padding: 0;
  margin-top: var(--margin);
  padding-top: var(--padding);
}
</style>
