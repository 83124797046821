<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  props: {
    addLink: {
      type: Boolean,
      default: false
    },
    pageMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [fandomBasicMixin],
  computed: {
    owner() {
      return Fandom.getUserById(this.content.owned_by);
    },
    image() {
      return get(this, ["owner", "avatar", "url"], false);
    },
    name() {
      return [get(this, ["owner", "first_name"], ""), get(this, ["owner", "last_name"], "")].join(" ");
    },
    role() {
      const decorator = Fandom.getDecorator(this.content, "role");
      const role = get(decorator, ["title"], false);
      return get(this, ["content", "role"], role);
    },
    socials() {
      return get(this, ["content", "socials"], false);
    },
    titleTag() {
      return this.pageMode ? "h1" : "span";
    }
  }
};
</script>

<template>
  <div class="d-flex flex-column align-items-center mb-3" v-easyadmin="easyadminId" :class="contentType">
    <a :href="applyContextToUrl(content.slug)" class="d-flex flex-column align-items-center" v-if="addLink">
      <img class="avatar rounded-circle mw-100 mh-100" v-if="image" :src="image" />
    </a>
    <template v-else>
      <img class="avatar rounded-circle mw-100 mh-100" v-if="image" :src="image" />
    </template>
    <component :is="titleTag" class="d-inline-block font-weight-bold mt-4 pt-2 mb-2 text-center" :class="{'text-red mb-0': pageMode, 'h2': pageMode}" v-if="name !== ''">{{name}}</component>
    <span class="font-md mb-4" :class="{'text-medium-grey': pageMode}" v-if="role">{{role}}</span>
    <div class="d-flex align-items-center font-lg pt-2" v-if="socials">
      <a class="mx-2" v-if="socials.facebook" :href="socials.facebook">
        <i class="icon fab fa-facebook-f"></i>
      </a>
      <a class="mx-2" v-if="socials.twitter" :href="socials.twitter">
        <i class="icon fab fa-twitter"></i>
      </a>
      <a class="mx-2" v-if="socials.instagram" :href="socials.instagram">
        <i class="icon fab fa-instagram"></i>
      </a>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.avatar {
  width: 13.125rem;
  height: 13.125rem;
  object-fit: cover;
}

.icon {
  color: $black;
  transition: color .5s ease-in-out;

  @include hover {
    color: $red;
  }
}
</style>
