<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      userInfo: {},
      loading: false,
      errors: [],
      success: false,
      formSubmitted: false
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.success = false;
      this.errors = [];

      if (this.userInfo.confirmPassword !== this.userInfo.password) {
        this.formSubmitted = true;
        this.errors.push(this.ft("globals.form.password_mismatch"));
        this.loading = false;
      } else {
        Fandom.ajax({
          method: "POST",
          data: { user: JSON.stringify(this.userInfo) },
          url: "/api/v5/update_user_password",
          success: (data) => {
            this.formSubmitted = true;
            this.success = !!data.success;
            if (!data.success) {
              this.errors = data.errors;
            }
            this.loading = false;
          }
        });
      }
    }
  },
  computed: {
    submitTextFormatted() {
      return this.loading ? this.ft("globals.form.loading") : this.ft("globals.form.send_password");
    }
  }
};
</script>

<template>
  <form class="d-flex flex-column" user-password-editing-form-component v-on:submit.prevent="submit($event)">          
    <div class="form-group">
      <label>{{ft("globals.form.current_password")}} *</label>
      <input v-model="userInfo.current_password" class="form-control" type="password" required>
    </div>
    <div class="form-group">
      <label>{{ft("globals.form.new_password")}} *</label>
      <input v-model="userInfo.password" class="form-control" type="password" pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" required>
    </div>
    <div class="form-group">
      <label>{{ft("globals.form.password_confirmation")}} *</label>
      <input v-model="userInfo.confirmPassword" class="form-control" type="password" pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" required>
    </div>
    <div class="col-12 col-md-8 px-0 pt-3">
      <small class="form-text mt-2">{{ft("globals.form.required_fields")}}</small>
      <small
        class="d-block form-group password-edit-instructions mt-3"
        v-if="content.password_edit_instructions"
        v-html="content.password_edit_instructions"
        >
      </small>
    </div>
    <button type="submit" class="col-12 col-sm-6 col-md-5 ml-auto px-0 py-4 mb-4 btn btn-primary" :class="{'disabled': loading}">{{submitTextFormatted}}</button>

    <form-feedback v-if="formSubmitted" :success-message="ft('globals.user_edit_password.success')" :success="success" :errors="errors"></form-feedback> 
  </form>
</template>

<style lang="scss" scoped>
</style>
