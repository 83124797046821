<script type="javascript">
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get, union } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    appendContents() {
      Fandom.getSearchableContents(data => {
        const mainContent = get(data, ["name_to_content", data.main_content_name], false);
        if (mainContent) {
          this.content.children = union(this.content.children, mainContent.children);
          this.content.has_more_children = mainContent.has_more_children;
          Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
        }
      }, this.getContentsParams);
    }
  },
  computed: {
    getContentsParams() {
      return {
        content_filtering: [this.content.name],
        include_children: true,
        include_content_children: true,
        layout_extra_field_name: "$empty",
        layout: "tile-influencer",
        limit: get(this, ["content", "limit"], 8),
        exclude_content_ids: this.children.map(c => c.id)
      };
    },
    children() {
      const items = get(this, ["content", "children"], []);
      return items.map(this.getContent);
    },
    lgColumns() {
      const col = get(this, ["content", "column_count"], 3);
      return col > 12 || col < 1 ? 3 : col;
    },
    lgColumnClass() {
      const size = Math.round(12 / this.lgColumns);
      return `col-lg-${size}`;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" grid-influencer-component>
    <div class="container">
      <div class="row justify-content-center">
        <tile-influencer class="col-12 col-xs-lnd-6 col-sm-6" :class="lgColumnClass" v-for="(child, index) in children" :content="child" :key="index" :add-link="true">
        </tile-influencer>
        <div class="col-12 mt-4 d-flex justify-content-center" v-if="content.has_more_children">
          <button class="btn shadow-none" :class="getPrimaryButtonClass(content.button_class, content.theme)" @click="appendContents()">{{ft("globals.load_more")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
