<script>
import { interactionMixin, getUrlFromPath } from "src/modules/interactions_module.js";
import { fandomTranslate } from "src/modules/common_module";
import { get } from "lodash";

const textToFlex = {
  right: "end",
  left: "start",
  center: "center"
};

export default {
  mixins: [interactionMixin],
  props: {
    content: Object,
    containerContent: Object,
    customShareSlug: {
      type: String,
      default: ""
    },
    // Placed temporary as a props, maybe it is better to place it in the globalState
    isIframe: {
      type: Boolean,
      default: false
    },
    shareRecordPath: {
      type: String,
      default: "content.interactions.share"
    },
  },
  methods: {
    providerIcon(provider) {
      const providerClass = Fandom.getProviderIcon(provider);
      const providerWeight = provider === "link" ? "far" : "fab";
      return [providerClass, providerWeight];
    },
    ft: fandomTranslate,
    startShare(provider) {
      // We must copy the value to the clipboard here because if we go too deep in the call stack
      //   we lose the origin by a click event and so we can't copy anything to the clipboard
      if (["link"].includes(provider) && !this.canUseWebShare) {
        this.copyToClipboard();
      }
      this.updateShareInteraction(this.containerContent, this.content, this.shareInteraction, provider, this.sharePath, this.isIframe, this.canUseWebShare);
    },
    isOnlyMobile(provider) {
      return ["whatsapp"].includes(provider);
    },
    copyToClipboard() {
      this.$refs["copy-text-modal"].$emit("copy");
    }
  },
  computed: {
    shareUrl() {
      return getUrlFromPath(this.sharePath, this.isIframe, this.content.name);
    },
    canUseWebShare() {
      return "share" in navigator;
    },
    shareInteraction() {
      return get(this, `${this.shareRecordPath}.interaction`, {});
    },
    shareProviders() {
      return get(this, `${this.shareRecordPath}.providers`, "").split(",");
    },
    sharePath() {
      return this.customShareSlug !== "" ? this.customShareSlug : false;
    },
    iconsAlignment() {
      const horizontalAlign = textToFlex[get(this, "content.text_align", "left")];
      return `align-items-center justify-content-${horizontalAlign}`;
    },
  }
};
</script>

<template>
  <div class="d-flex" :class="iconsAlignment">
    <template v-if="!canUseWebShare">
      <span
        class="cursor-pointer share-icon d-inline-flex align-items-center justify-content-center"
        v-for="(provider, index) in shareProviders"
        :key="index"
        :class="{'d-inline-block d-lg-none': isOnlyMobile(provider), 'mr-2 mr-lg-3': index + 1 < shareProviders.length}"
        @click="startShare(provider)"
        >
        <i class="fa-lg" :class="providerIcon(provider)"></i>
      </span>
      <copy-text-modal :title="ft('globals.share_with_friends')" :share-text="shareUrl" ref="copy-text-modal"></copy-text-modal>
    </template>
    <span class="cursor-pointer share-icon d-inline-flex align-items-center justify-content-center" @click="startShare('webShare')" v-else>
      <i class="fa-lg far fa-share-alt"></i>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.share-icon {
  border-radius: 100%;
  height: 2rem;

  .fa-lg {
    @include font-size(1rem);
  }
}
</style>
