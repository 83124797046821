<script>
import { initMap, loadMap, createInfoWindow } from 'src/modules/google_maps_module.js';
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get, debounce } from "lodash";
import inputAddressGoogleMaps from '../support_components/input-address-google-maps.vue';

const LAT = 41.9;
const LNG = 12.6;
const ZOOM = 6;
const HIGHER_ZOOM = 12;

export default {
  components: { inputAddressGoogleMaps },
  mixins: [fandomBasicMixin],
  data() {
    return {
      map: null,
      place: {},
      markers: [],
      infoWindow: null,
      userQuery: { lat: undefined, lng: undefined },
      higherZoom: HIGHER_ZOOM,
      loadContents: false,
      gridContent: null
    }
  },
  mounted() {
    this.initInputSearch();
    this.setUpMap();
    Fandom.onEvent("map-pin-selection", (id) => {
      this.markerClickHandler(this.markers.find(marker => marker.id === id));
      Fandom.scrollToTop("taste-map", 0);
    })
  },
  watch: {
    pins(newValue, oldValue) {
      const toBeRemoved = oldValue.filter(pin => !newValue.find(pin2 => pin2.id === pin.id));
      const toBeAdded = newValue.filter(pin => !oldValue.find(pin2 => pin2.id === pin.id));
      toBeRemoved.forEach(pin => {
        this.removeMarker(pin);
      });
      toBeAdded.forEach(pin => {
        this.addMarker(pin);
      });
    } 
  },
  computed: {
    pins() {
      return get(this.pageModel, ["name_to_content", this.content.grid_content, "children"], []).map(p => this.getContent(p));
    },
  },
  methods: {
    getContentURL: Fandom.getContentURL,
    setUpMap() {
      const getParams = new URLSearchParams(window.location.search);
      let lat = getParams.get("lat");
      let lng = getParams.get("lng");
      if (!!lat && !!lng) {
        this.loadContents = true;
        this.initMapFrame(parseFloat(lat), parseFloat(lng), HIGHER_ZOOM);
      } else if ('geolocation' in navigator) {
        const component = this;
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.loadContents = true;
            lat = position.coords.latitude;
            lng = position.coords.longitude;
            component.initMapFrame(lat, lng, HIGHER_ZOOM);
          },
          (_) => {
            this.initMapFrame(LAT, LNG, ZOOM);
          }
        );
      } else {
        this.initMapFrame(LAT, LNG, ZOOM);
      }
    },
    initMapFrame(lat, lng, zoom) {
      initMap(() => {
        const map = loadMap(this.$refs["google-map"], {
          center: {
            lat: lat,
            lng: lng
          },
          zoom: zoom,
          disableDefaultUI: true
        });
        this.infoWindow = createInfoWindow(this.$refs["info-content"]);
        Vue.set(this, 'map', map);
        this.pins.forEach(pin => {
          this.addMarker(pin);
        });
        this.map.addListener("bounds_changed", debounce((_) => {
          if (this.loadContents) {
            this.loadGridContents(false);
          }
        }, 500));
      })
    },
    initInputSearch() {
      initMap(() => {
        Vue.nextTick(() => {
          const params = { fields: ["formatted_address"],
            types: ["address"]
          };
          const autocomplete = new google.maps.places.SearchBox(this.$refs["place-input"], params);
          autocomplete.addListener("places_changed", (d) => {
            const place = autocomplete.getPlaces()[0];
            if (place) {
              Vue.set(this, 'userQuery', {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
              })
            }
          }, 500);
        });
      }, true)
    },
    removeMarker(pin) {
      const marker = this.markers.find(marker => marker.id === pin.id);
      if (marker) {
        marker.setMap(null);
        this.markers = this.markers.filter(marker => marker.id !== pin.id);
      }
    },
    addMarker(pin) {
      var latLng = new google.maps.LatLng(pin.lat,pin.lng);
      const marker = new google.maps.Marker({
        position: latLng,
        icon: {
          path: "M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z",
          fillColor: "#c1252f",
          fillOpacity: 1,
          scale: .075,
          anchor: new google.maps.Point(
            384 / 2, // width
            512 // height
          ),
        },
        id: pin.id,
      });
      google.maps.event.addListener(marker, "click", () => { 
        this.markerClickHandler(marker, pin);
      });
      marker.setMap(this.map);
      this.markers.push(marker);
    },
    markerClickHandler(marker, pin) {
      pin = pin || this.pins.find(pin => pin.id === marker.id);
      this.map.setCenter(marker.getPosition());
      Vue.set(this, "place", pin);
      this.infoWindow.open(this.map, marker);
    },
    loadGridContents(loadMore) {
      const bounds = this.map.getBounds();
      const data = bounds.toJSON()
      const params = {
        geographic_bounds: {
          max_lat: data.north,
          min_lat: data.south,
          max_lng: data.east,
          min_lng: data.west
        },
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
        order_by: "distance",
        sorting_order: "asc",
        limit: parseInt(this.content.limit) || 6,
      };
      if (loadMore) {
        params.exclude_content_ids = this.gridContent.children.map(c => Fandom.getContent(c).id);
      }
      Fandom.getContents(
        this.content.grid_content,
        params,
        (data) => {
          Vue.set(this, 'gridContent', this.pageModel.name_to_content[this.content.grid_content]);
        },
        loadMore
      );
    }
  }
};
</script>

<template>
  <div class="container-fluid" v-easyadmin="easyadminId" :class="contentType" stripe-taste-map>
    <div class="row" id="taste-map">
      <div class="col-12 bg-light-red d-flex flex-column flex-md-row justify-content-center align-items-md-center py-4">
        <span class="input-label text-white">{{ft("taste_map.search_place")}}</span>
        <input ref="place-input" type="search" class="mx-md-4 mt-3 mt-md-0 form-control" :placeholder="ft('taste_map.search_input_placeholder')"/>
        <button class="btn btn-black mt-3 mt-md-0 mb-2 mb-md-0" :disabled="!userQuery.lat && !userQuery.lng" @click="map.setCenter(userQuery); map.setZoom(higherZoom); loadContents = true;">{{ft("taste_map.go")}}</button>
      </div>
      <div class="col-12 p-0">
        <div class="ar-map">
          <div class="position-absolute w-100 h-100" ref="google-map"></div>
          <div class="position-absolute-center text-white bg-layer" v-if="!loadContents">
            <div class="row mx-0 d-flex justify-content-center align-items-center h-100">
              <div class="col-12 col-md-8 col-lg-6 col-xl-4 d-flex flex-column justify-content-center align-items-center text-center">
                <i class="fal fa-map-marker-alt mb-4 fa-2x"></i>
                <h2 class="mb-4">{{ft('taste_map.layer_title')}}</h2>
                <div>{{ft('taste_map.layer_description')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none">
      <div class="info-content" ref="info-content">
        <div class="title">{{place.title}}</div>
        <div class="description mt-2 mb-3">
          {{place.address}} <br>
          Tel. <a :href="`tel:${place.phone}`">{{place.phone}}</a>
        </div>
        <div class="d-flex align-items-center">
          <a :href="getContentURL(place)" target="_blank">{{ft("taste_map.go_to_description")}}<i class="far ml-2 fa-lg fa-long-arrow-alt-right"></i></a>
        </div>
      </div>
    </div>
    <grid-fixed :alternative-append-contents="() => loadGridContents(true)" class="py-4 py-md-5" :content="gridContent" v-if="gridContent && loadContents" :opt-container-content="gridContent"></grid-fixed>
  </div>
</template>

<style lang="scss">
[stripe-taste-map] {

  .ar-map {
    padding-bottom: 41.666667%;
    background-color: #ddd;
    @include media-breakpoint-down(sm) {
      padding-bottom: 125%;
    }
  }
  .input-label {
    @include font-size(1.25rem);
    font-family: $italia-lt-font;
  }

  input {
    max-width: 25rem;
  }

  .info-content {
    .title {
      @include font-size(1.5rem);
      color: $red;
      font-family: $italia-lt-font;
    }

    .description, a {
      font-family: $neutra-text-font;
      font-weight: bold;
    }

    .description {
      @include font-size(.875rem);
      color: $black;
      line-height: 1.5;
    }

  }

  button.gm-ui-hover-effect {
    right: 0 !important;
    top: 0 !important;

    &:hover {
      outline: none;
    }
  }

  .bg-layer {
    backdrop-filter: blur(6px);
    background-color: rgba(0,0,0,.35);

    h2 {
      font-family: $italia-lt-font;
    }
  }
}
</style>
