<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  computed: {
    owner() {
      return Fandom.getUserById(this.content.owned_by);
    },
    image() {
      return get(this, ["owner", "avatar", "url"], false);
    },
    name() {
      return [get(this, ["owner", "first_name"], ""), get(this, ["owner", "last_name"], "")].join(" ");
    },
    role() {
      const decorator = Fandom.getDecorator(this.content, "role");
      const role = get(decorator, ["title"], false);
      return get(this, ["content", "role"], role);
    },
    share() {
      return get(this, ["content", "interactions", "share"], false);
    },
    description() {
      return get(this, ["content", "description"], false);
    },
    pageUrl() {
      const url = get(this, ["content", "author_page", "url"], false);
      return url ? Fandom.applyContextToUrl(url) : false; 
    },
    authorTag() {
      return this.pageUrl ? "a" : "div";
    }
  }
};
</script>

<template>
  <div class="container" author-signature-component>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 row justify-content-between">
        <component :is="authorTag" :href="pageUrl" class="row col-auto align-items-center mb-2 text-decoration-none">
          <div class="col-auto" v-if="image">
            <img class="avatar rounded-circle mw-100 mh-100" v-if="image" :src="image" />
          </div>
          <div class="col-auto d-flex flex-column text-medium-grey">
            <span class="d-inline-block font-weight-bold" v-if="name !== ''">{{name}}</span>
            <span class="font-md mb-2" v-if="role">{{role}}</span>
          </div>
        </component>
        <div class="col-auto" v-if="share">
          <div class="d-flex align-items-center font-sm">{{ft("recipe.share_recipe")}}:</div>
          <share-icons :content="content" :containerContent="mainContent"></share-icons>
        </div>
        <div class="col-12" v-html="description" v-if="description"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatar {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
}
</style>
