<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { multiLangMixin } from "../../modules/cinque_stagioni_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, multiLangMixin],
  mounted() {
    Vue.nextTick(() => {
      disableBodyScroll(this.$el);
    });
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
};
</script>

<template>
  <div class="position-fixed mh-100vh" sidebar-component>
    <div class="position-absolute h-100 w-100 bg-body-bg">
      <div class="px-3 py-2 border-bottom w-100 font-weight-bold position-relative">
        <div class="mx-3 my-1">
          {{ ft("globals.langs.menu") }}
        </div>
        <div class="close-icon position-absolute" @click="$emit('sidebarClose', true)">
          <i class="fal fa-times"></i>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between px-3 py-2 font-sm font-weight-bold border-bottom opacity-35">
        <div class="mx-3 my-1 d-flex align-items-center w-100 text-uppercase font-black">
          <svg class="lang-icon border border-dark rounded-circle">
            <use :xlink:href="`#${currentLang}`"></use>
          </svg>
          <span class="ml-3">{{ft(`globals.langs.${currentLang}`)}}</span>
        </div>
        <i class="far fa-check mirrored"></i>
      </div>
      <a v-for="(item, index) in langs" class="d-block px-3 py-2 font-weight-bold font-sm w-100" :key="index" :href="redirectTolang(item)">
        <div class="mx-3 my-1 d-flex align-items-center w-100 text-uppercase link-black">
          <svg class="lang-icon border border-dark rounded-circle">
            <use :xlink:href="`#${item}`"></use>
          </svg>
          <span class="ml-3">{{ft(`globals.langs.${item}`)}}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[sidebar-component] {
  right: 0;
}
</style>
