<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { sortBy } from 'lodash';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      contentFiltering: [],
      filterModalOpened: false
    }
  },
  mounted() {
    this.initFilters();
    if (this.content.$id) {
      console.log(`%cError\n\tmessage: the content cannot be inserted as a record`, "color: red");
    }
  },
  methods: {
    initFilters() {
      this.contentFiltering = [];
      console.log(this.content);
      this.content.main_filters.forEach(_ => {
        Vue.set(this.contentFiltering, this.contentFiltering.length, []);
      });
    },
    isFilterActive(filter, topIndex) {
      return (this.contentFiltering[topIndex] || []).includes(filter);
    },
    toggleFilter(filter, topIndex) {
      const filterInnerIndex = this.contentFiltering[topIndex].indexOf(filter);
      if (filterInnerIndex > -1) {
        this.contentFiltering[topIndex].splice(filterInnerIndex,1);
      } else {
        this.contentFiltering[topIndex].push(filter);
      }

      Fandom.emit('grid-filter-apply', {
        contentFiltering: this.contentFiltering,
        handlerContents: (this.content.handler_contents || "")
      });
    },
    toggleModal() {
      if (this.filterModalOpened) {
        clearAllBodyScrollLocks();
      } else {
        disableBodyScroll(this.$refs["filter-menu"]);
      }
      this.filterModalOpened = !this.filterModalOpened;
    },
    reset() {
      this.initFilters();
      Fandom.emit('grid-filter-apply', {
        contentFiltering: this.contentFiltering,
        handlerContents: (this.content.handler_contents || "")
      });
      this.toggleModal();
    },
    getOrderedChildren(filterName) {
      const filter = this.getContent(filterName);
      switch (filter.order) {
        case "alphabetical":
          return sortBy(filter.children, [(f) => this.getContent(f).title]);
        default:
          return filter.children;
      }
    }
  },
  computed: {
    anyFilterActive() {
      return this.contentFiltering.flat().length > 0;
    },
    paddingY() {
      return `py-3 py-md-${this.content.padding_y || '4'}`;
    }
  }
};

</script>

<template>
  <div 
    v-if="isContentVisibleToUser()" 
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY]"
    :style="{'background-color': content.background_color}"
    stripe-filter-component
  >
    <span v-if="content.stripe_id" class="anchor" :id="content.stripe_id"></span>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <component :is="content.title_tag || 'h2'" :class="content.title_class || 'h2'" v-if="content.title">{{content.title}}</component>
          <div class="d-flex justify-content-center flex-wrap py-3 py-md-4">
            <button class="btn" :class="content.main_filters_class || 'btn-primary'" type="button" @click="toggleModal">
              {{content.button_text}}
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-3" v-if="anyFilterActive">
        <div class="col-12">
          <div class="d-flex mx-n1">
            <div v-for="(item, topIndex) in contentFiltering" :key="`f-preview-${item.join('_')}-${topIndex}`">
              <button
                class="mx-1 my-1 btn btn-secondary font-sm px-3 py-1"
                v-for="filter in item.filter(i => i.length > 0)"
                :key="`filter-${filter}`"
                @click="toggleFilter(filter, topIndex)"
              >
                {{getContent(filter).title}}<i class="ml-3 mt-1 fal fa-times"></i>
              </button>
            </div>
          </div>
        </div>    
      </div>
    </div>
    <!-- FILTER MODAL -->
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="layer position-fixed" v-if="filterModalOpened" @click="toggleModal">
        <div class="close-icon position-absolute cursor-pointer">
          <i class="fal fa-times"></i>
        </div>
      </div>
    </transition>
    <transition enter-active-class="animated faster slideInLeft" leave-active-class="animated faster slideOutLeft">
      <div class="filter-menu position-fixed bg-white p-4 p-md-5" ref="filter-menu" v-if="filterModalOpened">
        <div v-for="(mainFilter, topIndex) in content.main_filters" :key="`filter-collapse-${topIndex}`" class="position-relative filter w-100 pb-4 pb-md-5">
          <h5 class="font-weight-bold">{{getContent(mainFilter).title}}</h5>
          <div class="w-100 divider"></div>
          <div class="d-flex flex-wrap pt-4 justify-content-start">
            <div
              v-for="filter in getOrderedChildren(mainFilter)"
              :key="`subfilter-${filter}`"
              class="cursor-pointer py-2 px-3 btn btn-outline-primary m-2"
              @click="toggleFilter(filter, topIndex)"
              :class="{'active': isFilterActive(filter, topIndex)}"
            >
              <div class="text-center small">{{getContent(filter).title}}</div>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center pb-4 pb-md-5 flex-column flex-sm-row">
          <button class="btn btn-primary mr-sm-3" @click="toggleModal()">{{ft('globals.go')}}</button>
          <button class="btn btn-dark mt-3 mt-sm-0" @click="reset()">{{ft('globals.reset')}}</button>
        </div>
      </div>
    </transition>
    <!-- END MODAL -->
  </div>
</template>

<style lang="scss" scoped>
[stripe-filter-component] {
  .layer, .filter-menu {
    overflow: auto;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    bottom: 0;
    left: 0;
  }

  .layer {
    background-color: rgba(0,0,0,0.5);
  }

  .filter-menu {
    width: 50rem;
    max-width: calc(100vw - 3.5rem);
  }

  .layer {
    .close-icon {
      width: 3.5rem;
      height: 3.5rem;
      top: 0;
      right: 0;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color .25s;

      & > i {
        line-height: 1.5rem;
        @include font-size(1.5rem);
      }

      &:hover {
        color: $gray-300;
      }
    }
  }
}
</style>
