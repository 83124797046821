<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { Youtube } from 'src/modules/youtube_module.js'
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
    };
  },
  mounted() {
    this.registerAnimation();
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
    toggleYoutubePopup: Youtube.toggleYoutubePopup,
    mayToggleYoutubePopup() {
      if (this.isYoutubePresent) {
        this.toggleYoutubePopup();
      }
    }
  },
  computed: {
    isYoutubePresent() {
      const mediaType = get(this.content, ["play", "media_type"], false);
      return mediaType == "youtube";
    },
    columnClasses() {
      if (this.content.full_width) {
        return "col-12";
      }
      const classes = [];
      const columnWidth = Fandom.exists(this.content.width) ? this.content.width : Fandom.retrieveFromGlobalState("one_col_media_width", 10);
      classes.push(`col-12 col-md-${columnWidth}`);
      classes.push(`text-${this.content.text_align}`);

      return classes;
    },
    containerClass() {
      return this.content.full_width ? "container-fluid" : "container";
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" video-content-component>
    <div @click="mayToggleYoutubePopup()" class="video-content-cover" :class="{'cursor-pointer': isYoutubePresent}">
      <div :class="containerClass">
        <div class="row justify-content-center">
          <div :class="columnClasses" class="px-sm-0">
            <figure  v-if="getContentResponsiveImage('image')" class="d-flex justify-content-center">
              <img :src="getContentResponsiveImage('image')" class="img-fluid"/>
              <figcaption v-if="content.caption" class="pt-2 pt-sm-3 px-2 px-sm-0 font-sm text-black">{{content.caption}}</figcaption>
            </figure>
            <div class="position-absolute-center d-flex flex-column align-items-center justify-content-center">
              <svg class="icon" v-if="isYoutubePresent">
                <use xlink:href="#icon-play"></use>
              </svg>
              <component v-if="content.title" class="h1" :is="content.title_tag || 'h2'">{{content.title}}</component>
              <component v-if="content.description" :is="content.description_tag || 'p'" v-html="content.description"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[video-content-component] {
  color: $white;

  .icon {
    width: 107px;
    height: 100px;
  }
}
</style>
