<script>
import { get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { cinqueStagioniBasicMixin, cinqueStagioniRelatedMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";

export default {
  props: {
    tagExtraField: {
      type: String,
      default: ""
    },
    optionalRelatedContentNames: {
      type: Array,
      default: () => []
    },
    optionalViewMoreLink: {
      type: String,
      default: ""
    }
  },
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin, cinqueStagioniRelatedMixin],
  data() {
    return {
      title: [
        { title: this.ft("recipe.related_title.first") },
        { title: this.ft("recipe.related_title.second"), class: "uppercase", color: true },
        { title: this.ft("recipe.related_title.third") }
      ],
    };
  },
  mounted() {
    if (this.title) {
      Vue.set(this.content, "title_composed", this.title);
    }
    if (this.optionalRelatedContentNames.length > 0) {
      this.getRelatedContentsByNames(this.optionalRelatedContentNames, "tile");
    } else {
      const tag = get(this, ["content", this.tagExtraField], this.content.decorators.default);
      this.getRelatedContents(tag, "tile");
    }
  },
  methods: {
    showAll() {
      sendFandomAnalyticsEvent("Interaction", "Related Click", "View More");
      location.href = this.optionalViewMoreLink || this.globalState.pageModel.name_to_content[this.tag].slug;
      // window.open(this.globalState.pageModel.name_to_content[this.tag].slug);
    }
  },
};

</script>

<template>
  <div v-if="resultContent" stripe-related-recipes>
    <div class="container" stripe-header>
      <div class="row justify-content-md-center pt-4">
        <div class="col-sm-8 text-center mt-4 py-2 mx-auto">
          <h4 class="title-composed">
            <span v-for="(t, index) in title" :class="getTitleStyle(t)" :key="index">
              {{t.title}}
            </span>
          </h4>
        </div>
      </div>
    </div>
    <grid-fixed :content="resultContent" forced-layout="tile-recipe" column-count="4"></grid-fixed>
    <div v-if="optionalViewMoreLink !== ''" class="container pb-4">
      <div class="row justify-content-md-center">
        <div class="col-sm-8 text-center my-2 py-1 mx-auto">
          <button :class="`btn btn-${this.color}`" class="px-5 text-white" @click="showAll">
            {{ft('globals.view_more')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-related-recipes] {

}
</style>
