<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      form: {
        data: {}
      }
    };
  },
  methods: {
    getFormField(fieldName) {
      return this.formFields.filter(field => field.name == fieldName)[0] || {};
    },
    getMLInfo(info) {
      return (typeof info == "object") ? info[globalState.lang] : info;
    },
    getFieldPlaceholder(field) {
      let placeholder = (typeof field.placeholder == "object") ? field.placeholder[globalState.lang] : field.placeholder;
      placeholder = placeholder ? placeholder : "";
      return field.required && placeholder !== "" ? `${placeholder} *` : placeholder;
    }
  },
  computed: {
    formFields() {
      return JSON.parse(this.content.newsletter.form_fields).fields;
    },
    emailField() {
      return this.getFormField("email");
    },
    countryField() {
      return this.getFormField("country");
    },
    newsletterField() {
      return this.getFormField("newsletter");
    }
  }
};

</script>

<template>
  <div v-if="content.newsletter.action" class="bg-red py-3 px-md-4 bg-cover-center" :style="{'background-image': getContentResponsiveImageURL('background_image', content.newsletter)}" stripe-newsletter>
    <form class="w-100" :action="content.newsletter.action">
      <div class="container px-0">
        <div class="row m-0 py-3 py-md-4 justify-content-center">
          <div class="col-12 col-md-10 col-lg-7 text-white d-flex flex-column">
            <img :src="content.newsletter.icon.url" v-if="content.newsletter.icon" class="mx-auto mb-4 newsletter-icon" />
            <h4 class="text-center mb-4 text-uppercase">{{content.newsletter.title}}</h4>
            <div class="w-100 mb-4 newsletter-description" v-html="content.newsletter.description"></div>
            <div style="position: absolute; left: -9999px; top: -9999px;">
              <label for="pardot_extra_field">Comments</label>
              <input type="text" id="pardot_extra_field" name="pardot_extra_field">
            </div>
            <div class="form-group">
              <label class="form-label">{{getFieldPlaceholder(emailField)}}</label>
              <input type="text" class="custom-input w-100 mb-md-3" v-model="form.data.email" :placeholder="getFieldPlaceholder(emailField)" name="email" :required="true">
            </div>
            <div class="d-flex flex-column flex-md-row">
              <div
                class="form-group form-check px-0 mt-3 mr-md-5 my-0 d-flex align-items-start"
                v-if="newsletterField && newsletterField.type !== 'hidden'"
                >
                <label
                  for="newsletter-checkbox"
                  class="custom-checkbox red border d-inline-flex align-items-center justify-content-center p-1 cursor-pointer mr-2 rounded-circle"
                  :class="{'active': form.data.newsletter}"
                  >
                  <i class="far fa-check"></i>
                </label>
                <input type="checkbox" id="newsletter-checkbox" class="input-checkbox" value="true" v-model="form.data.newsletter" name="newsletter" :required="true">
                <label class="form-check-label font-sm" v-html="content.newsletter.checkbox_description"></label>
              </div>
              <button type="submit" class="btn btn-black mb-auto mr-auto">{{content.newsletter.button_title}}</button>
            </div>
            <small class="small-text mt-3 mt-md-0">{{ft("globals.form.required_fields")}}</small>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
[stripe-newsletter] {
  min-height: 22.875rem;

  .input-checkbox {
    opacity: 0;
    width: 1px;
    position: relative;
    left: -18px;
    top: 11px;
  }

  .newsletter-icon {
    max-width: 4.15rem;
    max-height: 4.15rem;
  }

  .newsletter-description {
    @include font-size(1rem);
  }

  .custom-input {
    height: 4rem;
    border: 0;
    padding-left: 1.5rem;
    border-radius: 1rem;

    @include placeholder {
      color: $medium-grey;
    }

    &:hover, &:focus, &:active {
      outline: none;
    }
  }

  .show-newsletter-privacy-modal {
    color: inherit;
    font-weight: 700;
  }

  .small-text {
    opacity: .6;
    @include font-size(.75rem);
  }
}
</style>
