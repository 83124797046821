<script>
import { fandomTranslate } from "src/modules/common_module";
import { get } from 'lodash';

export default {
  data() {
    return {
      content: {},
      pageModel: globalState.pageModel,
      redirectWhenUnlocked: false
    };
  },
  mounted() {
    Vue.nextTick(() => {
      Fandom.onEvent('modal-user-group-required', ({ data, redirectWhenUnlocked }) => {
        Vue.set(this, 'content', data);
        this.redirectWhenUnlocked = redirectWhenUnlocked;
        $("#user-modal-group-required").modal("show");
      });
    });
  },
  methods: {
    ft: fandomTranslate,
    setAuthorization(data) {
      if (data.success) {
        const authMap = globalState.pageModel.name_to_content_authorization[this.content.name].data.authorization_type_to_authorized
        Vue.set(authMap, "user_group", true);

        let authorized = true;
        let unauthorizedGroup = null;

        Object.keys(authMap).forEach((groupName, index) => {
          const groupAuthorized = authMap[groupName];
          authorized = authorized && groupAuthorized;
          if (!groupAuthorized) {
            unauthorizedGroup = groupName;
          }
        });

        if (authorized) {
          Vue.set(globalState.pageModel.name_to_content_authorization[this.content.name], "authorized", true);
          Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
          if (this.redirectWhenUnlocked) {
            const slug = globalState.pageModel.name_to_content[this.content.name].slug;
            window.location.href = Fandom.applyContextToUrl(slug);
          }
        } else {
          Vue.set(globalState.pageModel.name_to_content_authorization[this.content.name], "type", unauthorizedGroup);
        }

        $("#user-modal-group-required").modal("hide");
      }
    }
  },
  computed: {
    authRecord() {
      return get(globalState.pageModel, ["name_to_content_authorization", this.content.name], null)
    },
    userGroup() {
      return get(this.authRecord, ["data", "authorization_record", "user_group"], null);
    },
    formContent() {
      return get(globalState.pageModel, ["layout","content","user_group_forms", this.userGroup], null);
    }
  }
};
</script>

<template>
  <div modal-user-group-required-component>
    <div id="user-modal-group-required" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0 pb-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span class="close-icon italia-lt-font" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center" registration-pages>
            <div class="row justify-content-center">
              <div class="col-lg-10" v-if="formContent">
                <h5 class="modal-title text-uppercase text-red">{{formContent.title}}</h5>
                <div v-if="formContent.description" class="user-edit-description font-lg" v-html="formContent.description"></div>
                <generic-form class="my-5 text-left" :content="formContent" @done="setAuthorization" :opt-container-content="pageModel.layout.content"></generic-form>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[modal-user-group-required-component] {

  .close-icon {
    font-family: $italia-lt-font;
  }

  [generic-form-component]::v-deep {
    color: $dark;

    .form-group {
      text-align: left;
    }
  }
}
</style>
