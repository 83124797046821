/* global google */
import { get } from "lodash";

function registerCallback(callback) {
  if (!globalState.pageModel.google_maps_callbacks) {
    Vue.set(globalState.pageModel, "google_maps_callbacks", []);
  }
  const index = globalState.pageModel.google_maps_callbacks.length;
  Vue.set(globalState.pageModel.google_maps_callbacks, index, callback);
}

function initGoogleObject() {
  if (!window.google) {
    window.google = {
      status: null
    };
  }
}

function initGoogleMapsCallback() {
  if (!window.google.execCallbacks) {
    window.google.execCallbacks = () => {
      if (!globalState.pageModel.google_maps_callbacks) {
        console.warn("No google maps callbacks provided - globalState.pageModel.google_maps_callbacks not found");
      } else {
        globalState.pageModel.google_maps_callbacks.forEach(cb => { cb(); });
      }
      window.google.status = "loaded";
    };
  }
}

function init(callback, includePlaces = false) {
  /* initialize global variable "google" */
  initGoogleObject();
  /* register callback */
  registerCallback(callback);
  /* initialize script callback into global object "google" */
  initGoogleMapsCallback();
  /* if google apis are not already loaded nor pending, load apis */
  if (window.google.status !== "loading" && window.google.status !== "loaded") {
    window.google.status = "loading";
    const gmapsScriptTag = document.createElement("script");
    const firstScriptTag = document.getElementsByTagName("script")[0];
    // TODO: Hard coded api key fallback; should be loaded from the deploy settings
    // Please ensure that every tenant that needs google maps uses an api key from the deploy settings before removing it
    const key = get(globalState, ["pageModel", "aux", "g_maps", "api_key"], "AIzaSyBftFRuRXJhsG5-qGVvw7GZ38i5OjQcCh8") || "AIzaSyBftFRuRXJhsG5-qGVvw7GZ38i5OjQcCh8";
    gmapsScriptTag.src = `//maps.googleapis.com/maps/api/js?key=${key}&callback=google.execCallbacks`;
    if (includePlaces) {
      gmapsScriptTag.src = `${gmapsScriptTag.src}&libraries=places`;
    }
    gmapsScriptTag.setAttribute("defer", "defer");
    gmapsScriptTag.setAttribute("async", "async");
    firstScriptTag.parentNode.insertBefore(gmapsScriptTag, firstScriptTag);
  } else if (window.google.status === "loaded") {
    /* else if apis are already loaded, invoke callback directly */
    callback();
  }
}

function loadMap(htmlNode, settings = { center: { lat: 45.563970, lng: 12.428058 }, zoom: 12 }) {
  return new google.maps.Map(htmlNode, settings);
}

const createInfoWindow = content => new google.maps.InfoWindow({ content });

// Returns a callback
const addPlacesToMap = (map, service, markers) => {
  // Setup some local variables and functions

  const clearMarkers = () => {
    while (markers.length > 0) {
      const marker = markers.pop();
      if (marker) {
        marker.setMap(null);
      }
    }
  };

  const dropMarker = i => {
    return () => markers[i].setMap(map);
  };

  const showInfoWindow = marker => {
    service.getDetails(
      { placeId: marker.placeResult.place_id },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          Fandom.emit("new-place-data-retrieved", { place, marker });
        }
      }
    );
  };

  return (results, status, pagination) => {
    if (status === google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
      clearMarkers();
      Fandom.emit("new-places-found", { places: results.map(r => r.name) });
      results.forEach((searchResult, i) => {
        const marker = new google.maps.Marker({
          position: searchResult.geometry.location,
          animation: google.maps.Animation.DROP
        });
        marker.placeResult = searchResult;

        markers.push(marker);

        // If the user clicks a marker, show the details of that place in an info window.
        google.maps.event.addListener(marker, "click", () => { showInfoWindow(marker); });
        setTimeout(dropMarker(i), i * 100);
      });
    }
  };
};

// Will search for places in the viewport of the map
const searchVisiblePlaces = (map, type, keyword, markers) => {
  const params = {
    keyword,
    type,
    location: map.getCenter(),
    radius: "500",
    bounds: map.getBounds(),
  };
  const service = new google.maps.places.PlacesService(map);
  const callback = addPlacesToMap(map, service, markers);
  service.nearbySearch(params, callback);
};

const queryLocation = (map, type, query, markers) => {
  const params = {
    query,
    type,
    bounds: map.getBounds(),
  };
  const service = new google.maps.places.PlacesService(map);
  const callback = addPlacesToMap(map, service, markers);
  service.textSearch(params, callback);
};

const searchCity = (map, field, country = ["it"]) => {
  const params = {
    componentRestrictions: { country },
    fields: ["geometry"],
    types: ["(cities)"]
  };
  const autocomplete = new google.maps.places.Autocomplete(field, params);
  autocomplete.addListener("place_changed", () => {
    const city = autocomplete.getPlace();
    map.setCenter(city.geometry.location);
    map.setZoom(12);
  });
};

const GoogleMapsModule = {
  init,
  loadMap
};

export {
  GoogleMapsModule,
  init as initMap,
  loadMap,
  searchVisiblePlaces,
  createInfoWindow,
  queryLocation,
  searchCity
};
