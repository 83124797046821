<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getContentURL: Fandom.getContentURL
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="position-absolute-center bg-transition" tile-simple-component>
    <a :href="getContentURL(content)" :target="getTarget(content.url)" class="deco-none position-absolute-center">
      <div class="bg-cover-center position-absolute-center" :class="{'bg': !content.prevent_hover_animation}" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
      <div v-if="!content.hide_gradient" class="position-absolute-center linear-gradient-y"></div>
      <div class="position-absolute-center d-flex align-items-center justify-content-center" v-if="content.title">
        <span class="d-inline-block m-0 h2">{{content.title}}</span>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-simple-component] {
  color: $white;
}
</style>


