<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    successMessage: {
      type: String,
      default: ""
    },
    success: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: ""
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasMessage() {
      return (Fandom.exists(this.errors) && this.errors.length > 0) || this.successMessage !== ""  || this.errorMessage !== "";
    }
  }
};
</script>

<template>
  <div class="text-center bg-white my-2" v-if="hasMessage" form-feedback-component>
    <div class="p-3 border border-success text-success" v-if="success"><small>{{successMessage}}</small></div>
    <div class="p-3 border border-danger text-danger" v-else-if="(errors && errors.length > 0) || errorMessage">
      <template v-if="errors && errors.length > 0">
        <small>{{ft("globals.form.errors_compiling_module")}}</small>
        <ul class="text-left">
          <li v-for="(message, index) in errors" :key="index"><small v-html="message"></small></li>
        </ul>
      </template>
      <small v-else>{{errorMessage}}</small>
    </div>
  </div>
</template>
