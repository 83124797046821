<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    borderRightIsNeeded(i) {
      if(globalState.viewport.xl || globalState.viewport.lg) {
        return (i + 1) % 4 !== 0;
      }
      if(globalState.viewport.md || globalState.viewport.sm) {
        return (i + 1) % 2 === 1;
      }
      return false;
    },
  },
  computed: {
    contentStyle() {
      return {
        "background-color": this.backgroundColor,
        "background-image": this.backgroundImage
      };
    },
    showSeparators() {
      return !get(this, ["content", "hide_separators"], false);
    },
    backgroundColor() {
      return get(this, ["content", "background_color"], null);
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :style="contentStyle" class="w-100 position-relative background-cover-center" stripe-numbers-component>
    <div class="container py-5">
      <h2 :style="{'color': content.text_color}" class="text-center mt-sm-5" v-if="content.title">{{content.title}}</h2>
      <div class="row justify-content-center py-4 py-sm-5">
        <template v-if="content.keypoints">
          <div v-for="(keypoint, index) in content.keypoints" class="col-12 col-sm-6 col-lg-3 d-flex flex-column justify-content-start px-0 pb-5 pb-lg-0" :key="index">
            <div class="border-top-xs" v-if="!content.hide_bottom_borders"></div>
            <div class="keypoint-container pb-4 pt-4 pt-sm-0">
              <div class="keypoint-icon mx-auto">
                <img class="img-fluid" :src="keypoint.icon.url" />
              </div>
            </div>
            <div :class="{'custom-border-right': showSeparators && borderRightIsNeeded(index)}" class="keypoint-info h-100 px-0 px-sm-3 pt-0 pb-3 pt-sm-4 pb-sm-5 d-flex flex-column justify-content-start">
              <div :style="{'color': content.text_color}" class="keypoint-number font-weight-bold text-center" v-if="keypoint.number">{{keypoint.number}}</div>
              <div :style="{'color': content.text_color}" class="keypoint-title font-weight-bold text-center py-3 py-sm-0" v-if="keypoint.title">{{keypoint.title}}</div>
              <div :style="{'color': content.text_color}" class="keypoint-description text-center d-flex justify-content-center px-4 px-sm-0 flex-grow-1 align-items-start" v-if="keypoint.description" v-html="keypoint.description"></div>
            </div>
          </div>
        </template>
      </div>
      <div class="w-100 d-flex justify-content-center" v-if="content.button">
        <a class="btn" :href="content.button.url" :target="getTarget(content.button)" :class="content.button_class || 'btn-primary'">{{content.button.title}}</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.keypoint-icon {
  width: 6rem;

  @include media-breakpoint-up(xl) {
    width: 8rem;
  }
}

.keypoint-number {
  font-size: 3.1875rem;

  @include media-breakpoint-up(xl) {
    font-size: 3.5rem;
  }
}

.keypoint-title {
  font-size: 1.625rem;
  line-height: 1.27;

  @include media-breakpoint-up(xl) {
    min-height: 6rem;
    font-size: 1.875rem;
  }
}

.keypoint-description {
  font-size: 1.25rem;
  line-height: 1.27;
  color: $medium-grey;

  @include media-breakpoint-up(xl) {
    font-size: 1.625rem;
  }
}

.custom-border-right {
  border-right: solid 2px $border-grey;
}

@include media-breakpoint-down(xs){
  .border-top-xs {
    border-top: solid 2px #979797;
    margin: 0 2rem;
  }
}
</style>
