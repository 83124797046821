<script>

import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { favouriteInteractionMixin} from 'src/modules/favourite_interaction_module.js';

export default {
  mixins: [fandomBasicMixin, interactionMixin, favouriteInteractionMixin],
  data: function() {
    return {
      modalListInfo: { listId: null, listName: null, errors: [] },
      focusedListInfo: { listId: null, listName: null, hasMoreContents: false },
      favouriteContents: {}
    };
  },
  mounted: function() {
    let successCallback = (data) => {
      this.setDefaultList();
    };

    Fandom.executeOnce("load-favourite-lists", () => {
      this.getFavouriteLists(successCallback); 
    });

    $('#favourite-stripe-add-list, #favourite-stripe-edit-list, #favourite-stripe-remove-list').on(
      'hidden.bs.modal', (e) => { this.resetInfo("modalListInfo");
    });
  },
  methods: {
    setDefaultList() {
      for (let listId in globalState.pageModel.favouriteLists) {
        if (globalState.pageModel.favouriteLists[listId] == null) {
          this.focusedListInfo.listId = listId;
          this.getFavouriteContents(listId);
          break;
        }
      }
    },
    openAddFavouriteModal() {
      $("#favourite-stripe-add-list").modal('show');
    },
    openEditFavouriteModal() {
      console.log("edit!!");
      this.modalListInfo.listId = this.focusedListInfo.listId;
      this.modalListInfo.listName = this.focusedListInfo.listName;
      $("#favourite-stripe-edit-list").modal('show');
    },
    openRemoveFavouriteModal() {
      this.modalListInfo.listId = this.focusedListInfo.listId;
      this.modalListInfo.listName = this.focusedListInfo.listName;
      $("#favourite-stripe-remove-list").modal('show');
    },
    addFavouriteList(listName) {
      let successCallback = (data) => {
        if (data.success) {
          Vue.set(globalState.pageModel.favouriteLists, data.favourite_list.id, data.favourite_list.name);
          $("#favourite-stripe-add-list").modal('hide');
        } else {
          this.modalListInfo.errors = data.errors;
        }
      }
      this.createFavouriteList(listName, successCallback);
    },
    editFavouriteListName() {
      let successCallback = (data) => {
        if (data.success) {
          $("#favourite-stripe-edit-list").modal('hide');
          Vue.set(globalState.pageModel.favouriteLists, data.favourite_list.id, data.favourite_list.name);
          this.focusedListInfo.listName = data.favourite_list.name;
        } else {
          this.modalListInfo.errors = data.errors;
        }
      };
      if (this.modalListInfo.listName != this.focusedListInfo.listName) {
        this.editFavouritList(this.modalListInfo.listId, this.modalListInfo.listName, successCallback);
      }
    },
    removeFavouriteList() {
      let successCallback = (data) => {
        this.resetInfo("focusedListInfo");
        this.setDefaultList();
        $("#favourite-stripe-remove-list").modal('hide');

      };
      this.deleteFavouriteList(this.modalListInfo.listId, successCallback);
    },
    getFavouriteContents(listId) {

      let successCallback = (data) => {
        data.contents = data;
        const mainContentName = data.contents.main_content_name;
        const mainContent = globalState.pageModel.name_to_content[mainContentName];
        if (!mainContent) {
          Vue.set(globalState.pageModel.name_to_content, mainContentName, data.contents.name_to_content[mainContentName]);
        }

        Fandom.getContentsCallback(mainContentName, data.contents, () => {
          for(let contentName in data.contents.name_to_content ) {
            if(contentName != mainContentName){
              Vue.set(this.favouriteContents, contentName, data.contents.name_to_content[contentName].id);
            }
          }

          this.focusedListInfo.hasMoreContents = data.contents.name_to_content[mainContentName].has_more_children;
          Vue.delete(globalState.pageModel.name_to_content, mainContentName);

          Vue.nextTick(() => Fandom.emit("dom-items-loaded"));

        }, false);        
      };

      const favouriteContentsLayout = this.content.favourite_contents_layout ? this.content.favourite_contents_layout : 'tile-recipe';
      this.getContentsFromFavouriteList(listId, Object.values(this.favouriteContents), successCallback, favouriteContentsLayout);
    },
    focusList(listId, listName) {
      if (listId != this.focusedListInfo.listId) {
        this.focusedListInfo.listId = listId;
        this.focusedListInfo.listName = listName;
        Vue.set(this, "favouriteContents", {});
        this.getFavouriteContents(listId);
      }
    },
    resetInfo(field) {
      if (this[field]) {
        this[field].listId = null;
        this[field].listName = null;
        this[field].errors = [];
        if (this[field].hasMoreContents) {
          this[field].hasMoreContents = false;
        }
      }
    },
  },
  computed: {
    focusedListName() {
      if (this.focusedListInfo.listName) {
        return this.focusedListInfo.listName;
      } else {
        return this.ft("favourite.default_list");
      }
    },
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType"  stripe-favourite-component >
    <div class="container pt-4 pb-2">
      <div class="row justify-content-end d-none d-md-flex">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 mt-4 mt-md-0">
          <div class="row">
            <div class="col-auto d-flex align-items-center">
              <span class="text-uppercase text-red ml-3 mb-0 mr-2">{{focusedListName}}</span>
              <template v-if="focusedListInfo.listName">
                <i class="fal fa-edit px-1" @click="openEditFavouriteModal()"></i>
                <i class="fal fa-trash px-1" @click="openRemoveFavouriteModal()"></i>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 order-2 mt-4 mt-md-0">
          <div class="row d-md-none">
            <div class="col-12 d-flex align-items-center justify-content-center">
              <span class="text-uppercase text-red ml-3 mb-0 mr-2">{{focusedListName}}</span>
              <template v-if="focusedListInfo.listName">
                <i class="fal fa-edit px-1" @click="openEditFavouriteModal()"></i>
                <i class="fal fa-trash px-1" @click="openRemoveFavouriteModal()"></i>
              </template>
            </div>
          </div>
          <div v-if="Object.keys(favouriteContents).length > 0 " class="row px-3">
            <div v-for="(id, contentName) in favouriteContents" class="col-12 col-sm-6 col-md-6 col-xl-4 h-100 py-3" :key="id">
              <component :is="getContent(contentName).layout" :content="getContent(contentName)" class="d-block w-100" tile></component>
            </div>
            <div v-if="focusedListInfo.hasMoreContents" class="col-12 text-center py-3">    
              <button class="btn" :class="getPrimaryButtonClass()" @click="getFavouriteContents(focusedListInfo.listId)">{{ft("globals.load_more")}}</button>
            </div>
          </div>
          <div v-else class="row">
            <div class="col ml-3">
              {{ft("favourite.empty_list")}}
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 order-1">
          <div class="row pt-3">
            <div class="col-12 font-sm favourite-stripe__description" v-html="content.description"></div>
            <div class="col-12 favourite-stripe__create-list py-3">
              <button class="btn w-100 px-3 py-3" :class="getPrimaryButtonClass()" @click="openAddFavouriteModal('favourite-stripe-add-list')" >{{ft("favourite.create_list")}}</button>
            </div>
            <div class="col-12 favourite-stripe__show-lists">
              <span class="text-uppercase font-md font-weight-bold">{{ft("favourite.list_lists")}}</span>
              <ul v-if="globalState.pageModel.favouriteLists" class="my-3 py-3 border-top border-bottom">
                <li v-for="(listName, listId) in globalState.pageModel.favouriteLists" :key="listId">
                  <p class="font-xs text-uppercase py-2 pl-2" v-if="listName" @click="focusList(listId, listName)" :class="{'text-primary': focusedListName == listName}">- {{listName}}</p>
                  <p class="font-xs text-uppercase py-2 pl-2" v-else @click="focusList(listId, null)" :class="{'text-primary': focusedListName == ft('favourite.default_list')}">- {{ft("favourite.default_list")}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <!-- -------- modals -------- -->
    <!-- add modal -->
    <div id="favourite-stripe-add-list" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title">{{ft("favourite.create_list_title")}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span class="italia-lt-font" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ft("favourite.create_list_description")}}</p>
            <input type="text" v-model="modalListInfo.listName" name="new_list_name">
            <div v-if="modalListInfo.errors">
              <ul>
                <li v-for="error in modalListInfo.errors">{{error}}</li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn px-3" :class="getPrimaryButtonClass()" :disabled="!modalListInfo.listName" @click="addFavouriteList(modalListInfo.listName)">{{ft("favourite.create_list")}}</button>
            <button type="button" class="btn px-3" :class="getSecondaryButtonClass()" data-dismiss="modal">{{ft("globals.close")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- edit modal -->
    <div id="favourite-stripe-edit-list" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ft("favourite.edit_list_title")}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span class="italia-lt-font" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ft("favourite.edit_list_description")}}</p>
            <input type="text" v-model="modalListInfo.listName" name="new_list_name">
            <div v-if="modalListInfo.errors">
              <ul>
                <li v-for="error in modalListInfo.errors">{{error}}</li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn px-3" :class="getPrimaryButtonClass()" @click="editFavouriteListName()" >{{ft("favourite.edit_list")}}</button>
            <button type="button" class="btn px-3" :class="getSecondaryButtonClass()" data-dismiss="modal">{{ft("globals.close")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- delete modal -->
    <div id="favourite-stripe-remove-list" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{modalListInfo.listName}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span class="italia-lt-font" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ft("favourite.remove_list_description")}}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn px-3" :class="getPrimaryButtonClass()" @click="removeFavouriteList()">{{ft("favourite.remove_list")}}</button>
            <button type="button" class="btn px-3" :class="getSecondaryButtonClass()" data-dismiss="modal">{{ft("globals.close")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- -------- end modals -------- -->

  </div>
</template>

<style lang="scss">
[stripe-favourite-component] {

  [tile] {
    position: relative;
  }

  h1 {
    color: $primary;
  }

  h3, p {
    margin: 0;
  }

  i {
    @include font-size(1.25rem);
    display: block;
  }

  ul {
    padding-inline-start: $list-inline-padding;
  }

  li > p {
    line-height: 22px;
  }

  li, i {
    cursor: pointer;
    list-style-type: none;
    transition: color .25s ease-in-out;

    @include hover {
      color: darken($primary, 5%);
    }
  }

}
</style>
