<script>
import { formAutocompleteInputMixin } from "../../modules/cinque_stagioni_mixins_module";
import { fandomTranslate } from "src/modules/common_module";
import { debounce } from 'lodash';

export default {
  mixins: [formAutocompleteInputMixin],
  props: {
    formConfig: {
      type: String,
      default: null
    },
    showEmailField: {
      type: Boolean,
      default: false
    },
    initData: {
      type: Object,
      default: () => { return {} }
    },
  },
  data() {
    return {
      formFields: []
    }
  },
  mounted() {
    const fields = !!this.formConfig ? JSON.parse(this.formConfig).fields : [];
    if (this.showEmailField)
      Vue.set(this, 'formFields', fields);
    else
      Vue.set(this, 'formFields', fields.filter(el => el.name !== 'email'));

    Vue.nextTick(() => {
      this.formFields.forEach(field => {
        if (this.initData[field.name]) {
          Vue.set(field, 'value', this.initData[field.name]);
        }
        if (!field.value) {
          Vue.set(field, 'value', field.default_value);
        }
      });

      Vue.nextTick(() => {
        this.formFields.filter(f => f.hide_field && f.hide_value).forEach(f => {
          const fieldIndex = this.formFields.findIndex(f2 => f2.name === f.hide_field);
          Vue.set(this.formFields[fieldIndex], 'hide', true);
          $(`#user_${f.name}`).change(debounce(() => {
            Vue.set(this.formFields[fieldIndex], 'hide', !(f.hide_value === $(`#user_${f.name}`).val()));
          }, 200));
        });
        $(function () {
          $('[data-toggle="tooltip"]').tooltip()
        })
      });
    })
  },
  methods: {
    autocompleteItemClicked(name, title) {
      $(`[name='user[${name}]']`).val(title);
    },
    isCheckboxActive(field) {
      return $(`#user_${field.name}`)[0] && $(`#user_${field.name}`)[0].checked;
    },
    ft: fandomTranslate,
  },
  computed: {
    activeFields() {
      return this.formFields.filter(f => !f["hide"]);
    },
    hiddenFields() {
      return this.formFields.filter(f => f["hide"] && f.default_value);
    },
  }
};
</script>

<template>
  <div v-if="formFields && formFields.length > 0">
    <div class="form-group text-left" v-for="field in activeFields" :key="field.name">
      <label v-if="field.type !== 'checkbox'" class="form-label d-flex align-items-center" :for="`user_${field.name}`">
        <img v-if="field.icon" :src="field.icon" class="form-icon mr-2" />
        {{getMLInfo(field.label)}}
        <span v-if="field.required">*</span>
        <small class="ml-2" v-if="!field.required">({{ft('globals.optional')}})</small>
        <span class="cursor-pointer text-light-grey ml-2" v-if="field.tooltip">
          <i class="fas fa-question-circle" data-toggle="tooltip" data-placement="top" data-html="true" :title="`<small>${getMLInfo(field.tooltip)}</small>`"></i>
        </span>
      </label>

      <template v-if="field.type == 'autocomplete'">
        <div class="text-danger small mb-2" v-if="autocompleteErrors.length > 0">
          <div v-for="(error, index) in autocompleteErrors" :key="`error-${index}`">{{error}}</div>
        </div>
        <div class="dropdown" :id="`field-${field.name}`" data-toggle="dropdown" aria-expanded="false">
          <input
            @blur="autocompleteValidateSelection(field)"
            :placeholder="getMLInfo(field.placeholder)"
            @focus.once="debounceAutocomplete(field)"
            @keyup="debounceAutocomplete(field)"
            @keypress.enter.stop.prevent=""
            :name="`user[${field.name}]`"
            :id="`user_${field.name}`"
            :required="field.required"
            data-toggle="dropdown"
            v-model="field.value"
            aria-expanded="false"
            class="form-control"
            autocomplete="off"
            type="text"
          />
          <div class="dropdown-menu" v-show="autocompleteContents.length > 0 || field.mandatory_option_name" :aria-labelledby="`user_${field.name}`">
            <a
              class="dropdown-item small"
              href="#"
              v-for="(item, index) in autocompleteContents"
              :key="`autocomplete-${field.name}-${index}`"
              @keyup.prevent="autocompleteItemClicked(field.name, item.title)"
              @click.prevent="autocompleteItemClicked(field.name, item.title)"
            >{{item.title}}</a>
            <a
              v-if="field.mandatory_option_name"
              class="dropdown-item small"
              href="#"
              @keyup.prevent="autocompleteItemClicked(field.mandatory_option_name, field.mandatory_option_title)"
              @click.prevent="autocompleteItemClicked(field.mandatory_option_name, field.mandatory_option_title)"
            >
              {{getMLInfo(field.mandatory_option_title) || getMLInfo(field.mandatory_option_name)}}
            </a>
          </div>
        </div>
      </template>
      <div v-else-if="field.type == 'dropdown'" class="custom-select-outer">
        <select :id="`user_${field.name}`" :name="`user[${field.name}]`" class="custom-select"  :required="field.required">
          <option :value="null" disabled selected>{{getMLInfo(field.placeholder)}}</option>
          <option
            v-for="(option, optionIndex) in (typeof field.options === 'string' ? field.options.split(',') : field.options)"
            :key="`option-${optionIndex}`"
            :value="getMLInfo(option.value) || getMLInfo(option)"
            :selected="field.value == getMLInfo(option.value)"
          >{{getMLInfo(option.label) || getMLInfo(option)}}</option>
        </select>
      </div>
      <template v-else-if="field.type == 'address'">
        <input-address-google-maps
          :input-name="`user[${field.name}]`"
          :input-required="field.required"
          :input-placeholder="getMLInfo(field.placeholder)"
          :input-id="`user_${field.name}`"
          :initial-value="field.value"
        />
      </template>
      <div class="form-group position-relative checkbox mr-3 pr-3 pl-0" v-else-if="field.type === 'checkbox'">
        <input
          class="custom-checkbox-checkbox"
          type="checkbox"
          :id="`user_${field.name}`"
          :name="`user[${field.name}]`"
          :required="field.required"
          :checked="initData[field.name]"/>
        <label
          class="custom-checkbox red border d-inline-flex align-items-center justify-content-center p-1 cursor-pointer mr-2 rounded-circle"
          :for="`user_${field.name}`"
        ><i class="far fa-check text-white"></i></label>
        <label class="form-check-label" v-html="`${getMLInfo(field.label)} ${field.required ? '*' : ''}`"></label>
      </div>
      <input
        :type="field.type"
        :required="field.required"
        :placeholder="getMLInfo(field.placeholder)"
        :name="`user[${field.name}]`"
        :id="`user_${field.name}`"
        v-model="field.value"
        :pattern="field.pattern"
        class="form-control"
        v-else
      >
      <small class="d-block mt-3" v-if="field.footer">
        <span class="small" v-html="getMLInfo(field.footer)"></span>
      </small>
    </div>
    <input v-for="field in hiddenFields" :key="field.name" type="hidden" v-model="field.default_value" :name="`user[${field.name}]`" :id="`user_${field.name}`"/>
  </div>
</template>

<style lang="scss" scoped>
.active {
  background-color: $red;
}
input[type=checkbox]:checked + label {
  @extend .active;
}
.form-icon {
  width: 1.5rem;
  height: auto;
}
.dropdown-menu {
  margin-top: 10px;
  width: 100%;
}
.custom-checkbox-checkbox {
  position: absolute;
  opacity: 0;
  z-index: -1;
  bottom: 0;
}
</style>