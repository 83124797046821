<script>
import { initMap, loadMap, searchVisiblePlaces, queryLocation, createInfoWindow, searchCity } from 'src/modules/google_maps_module.js';
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { trim } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      map: null,
      markers: [],
      availablePlaces: [],
      infoWindow: null,
      place: {},
      types: ["restaurant"],
      keywords: "Pizzeria",
      userQuery: "",
      zoom: 6
    }
  },
  mounted() {
    initMap(() => {
      const map = loadMap(this.$refs["google-map"], { center: { lat: 41.9, lng: 12.6 }, zoom: this.zoom });
      Vue.set(this, "map", map);
      this.infoWindow = createInfoWindow(this.$refs["info-content"]);
      Vue.nextTick(() => {
        searchCity(this.map, this.$refs["city"]);
        this.searchVisiblePlaces();
        this.map.addListener("dragend", () => this.searchVisiblePlaces());
        this.map.addListener("zoom_changed", () => {
          this.zoom = this.map.getZoom(); 
          this.searchVisiblePlaces();
        });
      });
    }, true);
    Fandom.onEvent("new-places-found", ({ places }) => {
      this.availablePlaces = places;
    });
    Fandom.onEvent("new-place-data-retrieved", ({ place, marker }) => {
      Vue.set(this, "place", place)
      this.infoWindow.open(this.map, marker);
    });
  },
  computed: {
    query() {
      return trim(this.userQuery);
    },
    zoomedEnoughForSearch() {
      return this.zoom >= 10;
    },
    suggestions() {
      return this.availablePlaces.filter(place => place.includes(this.query));
    },
    rating() {
      let ratingHtml = "";

      for (let i = 0; i < 5; i++) {
        ratingHtml += (this.place.rating < i + 0.5) ? "&#10025;" : "&#10029;";
      }
      return ratingHtml;
    },
    website() {
      const hostnameRegexp = new RegExp("^https?://.+?/");
      return String(hostnameRegexp.exec(this.place.website));
    }
  },
  methods: {
    setQuery(value) {
      this.userQuery = value;
    },
    setLocation(value) {
      this.setQuery(value);
      this.queryLocation();
    },
    searchVisiblePlaces() {
      if (this.zoomedEnoughForSearch && this.query === "") {
        searchVisiblePlaces(this.map, this.types, this.keywords, this.markers);
      }
    },
    queryLocation() {
      if (this.zoomedEnoughForSearch && this.query !== "") {
        queryLocation(this.map, this.types, [this.keywords, this.query].join(" "), this.markers);
      }
    }
  }
};
</script>

<template>
  <div class="container" v-easyadmin="easyadminId" :class="contentType">
    <div class="row">
    <div class="col-12 py-3 d-flex align-items-end">
      <div class="form-group mb-0 mr-3">
        <label>{{ft("maps.search_city")}}</label>
        <input type="text" class="form-control" :placeholder="ft('maps.search_city')" ref="city" />
      </div>
      <div class="form-group mb-0 mr-3">
        <label>{{ft("maps.search_place")}}</label>
        <v-autocomplete
          type="text"
          class="form-control"
          @keypress.enter="queryLocation"
          @item-clicked="setLocation"
          @change="setQuery"
          :items="suggestions"
          :placeholder="ft('maps.search_place')"
          :auto-select-one-item="false"
          >
        </v-autocomplete>
      </div>
      <button class="btn btn-primary" @click="queryLocation" :disabled="query === ''" :class="{'disabled': query === ''}">
        {{ft("globals.search")}}
      </button>
    </div>
      <div class="col-12 position-relative">
        <div class="ar-16by9">
          <div class="position-absolute w-100 h-100" ref="google-map"></div>
        </div>
      </div>
    </div>
    <div class="d-none">
      <div ref="info-content">
        <table>
          <tr class="table-row">
            <td class="text-right">
            <img class="place-icon" :src="place.icon"/>
            </td>
            <td>
              <b>
                <a :href="place.url">{{place.name}}</a>
              </b>
            </td>
          </tr>
          <tr class="table-row">
            <td class="font-weight-bold text-right">{{ft("maps.address")}}:</td>
            <td>{{place.vicinity}}</td>
          </tr>
          <tr class="table-row" v-if="place.formatted_phone_number">
            <td class="font-weight-bold text-right">{{ft("maps.telephone")}}:</td>
            <td>{{place.formatted_phone_number}}</td>
          </tr>
          <tr class="table-row" v-if="place.rating">
            <td class="font-weight-bold text-right">{{ft("maps.rating")}}:</td>
            <td v-html="rating"></td>
          </tr>
          <tr class="table-row" v-if="place.website">
            <td class="font-weight-bold text-right">{{ft("maps.website")}}:</td>
            <td>
              <a :href="website">{{website}}</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-row {
  height: 1.125rem;
}

.place-icon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
}
</style>
