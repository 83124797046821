<script>
import { map } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      resultContent: null,
      excludeContentIds: [],
      hasMoreChildren: false,
      searchText: "",
      activeSearch: "",
    };
  },
  mounted() {
    Vue.nextTick(() => {    
      const filtersInGet = Fandom.getJSONUrlParameter("filters");
      if (filtersInGet != null) {
        this.searchText = filtersInGet.searchText;
        this.applyFilters();
      }
    });
  },
  computed: {
    filters() {
      return {
        "searchText": this.searchText
      };      
    }
  },
  methods: { 
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    applyContextToUrl: Fandom.applyContextToUrl,
    applyFilters() {
      this.activeSearch = this.searchText;
      this.mayDeleteResultContentChildren();
      this.getSearchableContents(false);
    },
    mayDeleteResultContentChildren() {
      if (Fandom.exists(this.resultContent)) {
        if (Fandom.exists(this.resultContent.children)) {
          for (const chidlName of this.resultContent.children) {
            delete globalState.pageModel.name_to_content[chidlName];
          }  
        }   
        this.resultContent = null;
      }
    },
    getSearchableContents(appendContents) {
      const successBlock = (data) => {
        Vue.set(this, "resultContent", globalState.pageModel.name_to_content[data.main_content_name]);
      };
      const params = this.filtersToGetContentsParams(this.filters);
      Fandom.getSearchableContents(successBlock, params, appendContents);
    },
    appendContents() {
      const successBlock = (data) => {
        this.resultContent = globalState.pageModel.name_to_content[data.main_content_name];
      };
      const params = this.filtersToGetContentsParams(this.filters);
      params["exclude_content_ids"] = map(this.resultContent.children, c => this.getContent(c).id);
      Fandom.getSearchableContents(successBlock, params, true);
    },
    filtersToGetContentsParams(filters) {
      const params = {
        layout: "search-result",
        include_parent_names: true,
        include_children_count: true
      };
      if (Fandom.exists(this.resultContent)) {
        params.main_content_name = this.resultContent.name;
      }
      if (Fandom.exists(filters.searchText)) { 
        params.search_text = Fandom.searchTextToBackendFormat(filters.searchText);
      }
      if (!!this.content.filter_contents && this.content.filter_contents.length > 0) {
        params.content_filtering = [this.content.filter_contents];
      }
      return params;
    },
    redirect(slug) {
      window.location.href = slug;
    },
    itemTitle(item) {
      const content = this.getContent(item);
      return content.seo && content.seo.title ? content.seo.title : content.title;
    },
    thumbnailUrl(item) {
      const cc = this.getContent(item);
      return cc.thumbnail ? cc.thumbnail.url : this.content.placeholder ? this.content.placeholder.url : "";
    }
  },
};

</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" stripe-search-component>
    <div class="w-100 m-0 px-0 py-4 bg-red">
      <span class="text-white text-center text-md d-block mb-2">{{ft("globals.you_searched_for")}}</span>
      <h2 class="text-white text-uppercase text-center">{{activeSearch}}</h2>
    </div>
    <div v-if="resultContent" class="px-2 mx-3 mx-sm-5">
      <div v-if="resultContent.children_count > 0" class="text-red font-weight-bold text-uppercase mb-3 mt-4">
        {{resultContent.children_count}} {{ft("globals.results_for")}} {{activeSearch}}
      </div>
      <div v-else class="d-flex align-items-center justify-content-center no-results" >
        <p class="h4 text-center">{{ft("globals.no_results")}}</p>
      </div>
      <div v-for="(child, index) in resultContent.children" :key="index">
        <div class="w-100 border-bottom" v-if="index > 0"></div>
        <div class="py-3 cursor-pointer d-flex flex-column flex-sm-row" @click="redirect(applyContextToUrl(getContent(child).slug))">
          <div :style="{'background-image': `url(${thumbnailUrl(child)})`}" class="bg-cover-center rounded thumbnail mr-sm-4"></div>
          <div class="d-flex flex-column justify-content-center pl-sm-2 mt-4 mt-sm-0">
            <div class="font-xs text-red border border-red py-2 px-3 rounded mr-auto" v-if="getDecorator(getContent(child), 'default')">
              {{getDecorator(getContent(child), "default").title}}
            </div>
            <div class="font-weight-bold mt-3 mt-sm-4" v-if="itemTitle(child)">
              {{itemTitle(child)}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center container py-4" v-if="resultContent && resultContent.has_more_children"> 
      <button 
        class="btn shadow-none" 
        :class="getPrimaryButtonClass()"
        @click="appendContents()"
      >{{ft("globals.load_more")}}</button>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-search-component] {

  .no-results {
    min-height: 35vh;
  }

  .thumbnail {
    height: 6.25rem;
    width: 9.375rem;
  }
}
</style>
