<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    formSubmitDone(skip) {
      if (!skip) {
        Fandom.setCookie('signup-thankyou-modal', true);
      }
      const redirectUrl = globalState.beforeSignUpLastVisitedPage || Fandom.applyContextToUrl("/");
      window.location.href = get(this.content, ["redirect_url", "url"], redirectUrl);
    },
  },
  computed: { },
};

</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    v-if="isContentVisibleToUser()" 
    :class="[contentType]" 
    class="w-100"
    page-after-signup-component
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6 my-4">
          <div class="my-4">
            <h3 class="mb-3 h5 text-uppercase text-center text-red">{{content.title || ft("globals.user_profile.user_edit_title")}}</h3>
            <generic-form :content="getContent(content.form_content)" @done="formSubmitDone"></generic-form>
            <div class="w-100 d-flex justify-content-center">
              <button class="btn btn-primary mx-auto" @click="formSubmitDone(true)">{{ft("globals.user_profile.skip")}}</button>
            </div>  
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[page-after-signup-component] {
  [generic-form-component] {
    form > div.col-12 {
      justify-content: center !important;
      margin-right: auto !important;
    }
  }
}
</style>