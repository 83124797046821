<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

const toColValue = number => Math.round(number > 12 ? 12 : (number < 1 ? 12 : number));

export default {
  mixins: [fandomBasicMixin],
  props: {
    alternativeAppendContents: Function,
    trackTileClick: Array,
    hideLoadMore: {
      type: Boolean,
      default: false
    },
    forcedLayout: {
      type: String,
      default: ""
    },
    enableInfiniteScrolling: {
      type: Boolean,
      default: false
    },
    columnCount: {
      type: String,
      default: "4"
    }
  },
  data() {
    return {
      contentFiltering: []
    }
  },
  mounted() {
    if (this.enableInfiniteScroll) {
      $(this.$refs["load-more"]).on("inview", (event, isInView) => {
        if (isInView) {
          this.appendContents();
        }
      });
    }
    Fandom.onEvent("grid-filter-apply", (data) => {
      if (data.handlerContents.indexOf(this.content.name) > -1) {
        Vue.set(this, 'contentFiltering', data.contentFiltering);
        const params = {
          content_filtering: data.contentFiltering
        };
        ["order_by", "sorting_order", "limit"].forEach(key => {
          if (data[key]) {
            params[key] = data[key];
          }
        });
        Fandom.getContents(this.content.name, params, () => {}, false);
        Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
      }
    });
  },
  methods: {
    appendContents() {
      if (Fandom.exists(this.alternativeAppendContents) && typeof this.alternativeAppendContents === "function") {
        this.alternativeAppendContents();
      } else {
        const params = {}
        const count = get(this.content, "contents_count", null);
        if (this.contentFiltering.length > 0) {
          params["content_filtering"] = this.contentFiltering;
        }
        Fandom.appendContents(this.content.name, null, count, params);
      }
      Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
    },
    getTileClass(colNumber, index) {
      const positionCheck = (index + 1) % colNumber;
      const positionClass = positionCheck === 0 ? "end-tile" : ( positionCheck === 1 ? "start-tile" : "center-tile");
      return `${positionClass} ${index % 2 === 0 ? "even-tile" : "odd-tile"}`;
    },
    getColumnClass(index) {
      const tileClass = this.getTileClass(this.colNumber, index);
      return `${this.colClasses} ${tileClass}`;
    },
    getLayout(item) {
      return this.forcedLayout !== "" ? this.forcedLayout : this.getContent(item).layout;
    }
  },
  computed: {
    colNumber() {
      const columns = get(this.content, "column_count", this.columnCount);
      return toColValue(parseInt(columns));
    },
    colClasses() {
      const columnStep = Math.round(4 / this.colNumber);
      const lgSize = toColValue(12 / this.colNumber);
      return [
        "col-12",
        lgSize < 12 ? "col-xs-lnd-6" : "",
        `col-sm-${toColValue(12 / (this.colNumber - columnStep * 2))}`,
        `col-md-${toColValue(12 / (this.colNumber - columnStep))}`,
        `col-lg-${lgSize}`
      ].join(" ");
    },
    children() {
      const items = get(this, ["content", "children"], []);
      return items.filter(Fandom.exists);
    },
    hasChildren() {
      return this.children.length > 0;
    },
    enableInfiniteScroll() {
      return get(this, ["content", "enable_infinite_scrolling"], this.enableInfiniteScrolling);
    }
  },
};

</script>


<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :id="`ref-${easyadminId}`" :style="{'background-color': content.background_color}" fixed-grid>
    <stripe-header :content="content" v-if="content.title || content.title_composed"></stripe-header>
    <div class="container">
      <div class="row" v-if="hasChildren">
        <div v-for="(child, index) in children" class="tile" :class="[getColumnClass(index), {'px-0': content.hide_column_padding}]" :key="index">
          <div class="p-2">
            <component :class="[content.aspect_ratio_class]" :is="getLayout(child)" :key="`fixed-grid-item-${child}`" :trackTileClick="trackTileClick" :content="getContent(child)" class="w-100" tile></component>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12">
          <p>{{content.no_contents_message || ft("globals.no_content")}}</p>
        </div>
      </div>
    </div>
    
    <div class="container mt-4" v-if="content.has_more_children && !hideLoadMore">
      <div class="row">
        <div class="col-sm-12 text-center">
          <div class="agugiaro-figna-loader" ref="load-more" v-if="enableInfiniteScroll"></div>
          <button class="btn shadow-none" :class="getPrimaryButtonClass(content.button_class, content.theme)" @click="appendContents()" v-else>{{ft("globals.load_more")}}</button>
        </div>
      </div>
    </div>
    <div class="container mt-4" v-else-if="content.url">
      <div class="row">
        <div class="col-sm-12 text-center">
          <a :href="content.url.url" :target="getTarget(content.url)" class="btn shadow-none" :class="getPrimaryButtonClass(content.button_class, content.theme)">{{content.url.title}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[fixed-grid] {

  [tile] {
    // padding-bottom: 150%;
    position: relative;
  }
}
</style>

