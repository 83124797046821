<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

import jQueryBridget from "jquery-bridget";
import Isotope from "isotope-layout";
import "isotope-packery";

jQueryBridget("isotope", Isotope, $);

export default {
  mixins: [fandomBasicMixin],
  props: {
    alternativeAppendContents: Function,
    trackTileClick: Array
  },
  data: function() {
    return {
    };
  },
  mounted: function() {
    Vue.nextTick(this.init);
  },
  computed: {
    componentClasses() {
      let classes = [ this.contentType ];
      if (this.content.theme) {
        classes.push(`${this.content.theme}-theme`);
      }
      return classes;
    },
    isotopeRef() {
      return $(this.$refs["isotope-grid"]);
    },
    mainStyles: function() {
      let style = {
        "background-color": this.content.background_color,
        "color": this.content.text_color
      };
      return this.content.theme ? {} : style;
    }
  },
  methods: {
    init() {      
      this.isotopeRef.isotope({
        layoutMode: "packery",
        itemSelector: ".isotope-box",
        percentPosition: true,
        transitionDuration: 0,
      });
    },
    isotopeReload() {
      console.log("Isotope reload");
      Vue.nextTick(() => {
        this.isotopeRef.isotope("reloadItems").isotope();
      });
    },
    getIsotopeClass: function(element) {
      return "grid-item--" + (Fandom.exists(element.isotope_size) ? element.isotope_size : "default");
    },
    appendContents: function() {
      if (Fandom.exists(this.alternativeAppendContents)) {
        this.alternativeAppendContents(this.content.name, this.isotopeReload)
      } else {
        Fandom.appendContents(this.content.name, this.isotopeReload);
      }
    }
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="componentClasses" class="py-4" :style="mainStyles" isotope-grid>
    <stripe-header :content="content" v-if="content.title_composed"></stripe-header>
    <div v-if="content.children">
      <div v-if="content.children.length == 0">
        <p>{{ft("globals.no_content")}}</p>
      </div>
      <div class="isotope-grid p-0 container" ref="isotope-grid" v-else>
        <div class="isotope-box overflow-hidden rounded" :class="getIsotopeClass(getContent(child))" v-for="(child, key) in content.children" :key="'isotope-child-' + key">
          <component :is="getContent(child).layout" :content="getContent(child)" :trackTileClick="trackTileClick"></component>
        </div>
      </div>
    </div>
    <div class="container p-0 mt-4" v-if="content.has_more_children">
      <div class="row">
        <div class="col-sm-12 text-center">
          <button class="btn shadow-none" :class="getSecondaryButtonClass(content.button_class, content.theme)" @click="appendContents()">{{ft("globals.load_more")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@mixin isotope-square($size, $spacer) {
  width: calc(#{$size} - #{$spacer} * 2);
  height: 0;
  padding-bottom: calc(#{$size} - #{$spacer} * 2);
}

@mixin isotope-vertical-rectangle($size, $spacer) {
  width: calc(#{$size} - #{$spacer} * 2);
  height: 0;
  padding-bottom: calc(#{$size * 2} - #{$spacer} * 2);
}

@mixin isotope-horizontal-rectangle($size, $spacer) {
  width: calc(#{$size * 2} - #{$spacer} * 2);
  height: 0;
  padding-bottom: calc(#{$size} - #{$spacer} * 2);
}

@mixin isotope-horizontal-rectangle-mobile($size, $spacer) {
  width: calc(100% - #{$spacer} * 2);
  height: 0;
  padding-bottom: calc(#{$size} - #{$spacer} * 2);
}

[isotope-grid] {
  $spacer: .5rem;

  &.dark-theme {
    background-color: $dark;
    color: $white;

    .flickity-page-dots .dot {
      background-color: $white;
    }
  }

  .isotope-box {
    margin: $spacer;
    @include isotope-square(25%, $spacer);

    &.grid-item--widecontent {
      @include isotope-horizontal-rectangle(25%, $spacer);
    }

    &.grid-item--largesquarecontent {
      @include isotope-square(50%, $spacer);
    }

    &.grid-item--highcontent {
      @include isotope-vertical-rectangle(25%, $spacer);
    }
  }

  @include media-breakpoint-down(xs) {
    .isotope-box {
      @include isotope-square(100%, $spacer);

      &.grid-item--widecontent {
        @include isotope-horizontal-rectangle-mobile(60%, $spacer);
      }

      &.grid-item--largesquarecontent {
        @include isotope-square(100%, $spacer);
      }

      &.grid-item--highcontent {
        @include isotope-square(100%, $spacer);
      }
    }
  }
}

</style>
