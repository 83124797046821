<script>
import { get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { multiLangMixin } from "../../modules/cinque_stagioni_mixins_module.js";

export default {
  data() {
    return {
      sidebarOpen: false,
      langSelectorOpen: false,
      showSidebarLayer: false,
      showSearchLayer: false,
      searching: false,
      searchText: ""
    };
  },
  mixins: [fandomBasicMixin, multiLangMixin],
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    afterEnterCallback() {
      $(".search-input").focus();
    },
    toggleLangSelector() {
      this.langSelectorOpen = !this.langSelectorOpen;
    },
    search() {
      window.location.href = `${this.resultUrl}?filters=${encodeURI(JSON.stringify({"searchText":this.searchText}))}`;
    }
  },
  computed: {
    isViewportSmall() {
      return globalState.viewport.sm || globalState.viewport.xs;
    },
    absolute() {
      return this.pageModel.name_to_content[this.pageModel.main_content_name].absolute;
    },
    resultUrl() {
      return get(this.content, ["search_result_url","url"], "search");
    }
  }
};
</script>

<template>
  <div class="w-100 py-md-3 d-flex flex-column justify-content-center" :class="[absolute && !isViewportSmall ? 'bg-transparent position-absolute' : 'bg-body-bg position-relative', {'border-bottom': !absolute}]" header>
    <div class="w-100 d-flex justify-content-between align-items-center position-relative p-3 py-md-0">
      <div class="hamburger d-none mr-3 cursor-pointer" @click="sidebarOpen = true">
        <i class="far fa-bars"></i>
      </div>
      <a :href="applyContextToUrl('')" class="d-block d-md-none" v-if="isViewportSmall">
        <div class="m-0 d-flex justify-content-center align-items-center logo-container" style="line-height: 0;" :title="content.logo.title">
          <img class="logo" v-if="content.logo" :src="content.logo.url" :alt="content.logo.title">
        </div>
      </a>
      <div class="search px-md-4 mr-md-0 ml-md-auto d-flex align-items-center font-weight-bold" :class="{'invisible': searching}">
        <div class="h-100 font-sm pr-3" v-if="content.support_url">
          <a :href="applyContextToUrl(content.support_url.url)" class="text-uppercase link-black">{{content.support_url.title}}</a>
        </div>
        <div class="h-100 font-sm pr-3" v-if="!isUserRegistered()">
          <a class="text-uppercase link-black" rel="nofollow" :href="applyContextToUrl('/users/sign_in')">{{ft("globals.login.login")}}</a>
        </div>
        <div class="pr-3 font-sm position-relative" v-else>
          <a class="text-uppercase link-black collapsed d-flex align-items-center" href="#profile-dropdown" data-toggle="collapse">
            {{ft("globals.profile")}}
            <i class="far fa-chevron-down ml-1 mb-1"></i>
          </a>
          <div class="position-absolute bg-white py-3 profile-menu collapse" id="profile-dropdown" v-if="content.profile_menu.length > 0">
            <div
              v-for="(item, index) in content.profile_menu"
              class="user-link px-3 w-100 text-right"
              :class="{'mt-3': index > 0}"
              :key="index"
            >
              <a :href=item.url.url class="link-black text-uppercase font-sm" :class="item.custom_class" :target="getTarget(item.url)">
                {{item.url.title}}
              </a>
            </div>
          </div>
        </div>
        <div class="pr-3" v-if="langs.length > 0">
          <div class="d-flex align-items-center link-black font-md cursor-pointer" @click="toggleLangSelector">
            <svg class="lang-icon border border-dark rounded-circle">
              <use :xlink:href="`#${currentLang}`"></use>
            </svg>
            <i class="far fa-chevron-down ml-3"></i>
          </div>
        </div>
        <i class="far fa-search font-lg ml-3 link-black cursor-pointer" @click="searching = true"></i>
      </div>
      <transition enter-active-class="animated faster slideInRight" leave-active-class="animated faster slideOutRight" v-on:after-enter="afterEnterCallback()" v-if="isViewportSmall">
        <div class="h-100 d-flex align-items-center position-absolute search-stripe bg-cream mx-3" v-if="searching">
          <div class="pl-md-4 mx-3 d-flex align-items-center w-100">
            <div class="input-group search-input flex-grow-1">
              <input type="text" class="form-control border-cream" :placeholder="ft('globals.search')" v-model="searchText" v-on:keyup.enter="search()" v-on:click="showSearchLayer = true">
              <div class="input-group-append btn-primary d-flex align-items-center px-3" @click="search()">
                <i class="far fa-search cursor-pointer"></i>
              </div>
            </div>
            <div class="pr-md-4 mx-3 d-flex align-items-center" @click="searching = false; showSearchLayer = false;">
              <i class="fal fa-times cursor-pointer link-black"></i>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="w-100 h-100 position-fixed" v-if="showSidebarLayer" layer-dark></div>
    </transition>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="w-100 h-100 position-fixed" v-if="showSearchLayer" layer-light></div>
    </transition>
    <transition
      enter-active-class="animated faster slideInLeft"
      leave-active-class="animated faster slideOutLeft"
      v-on:after-enter="showSidebarLayer = true"
      v-on:before-leave="showSidebarLayer = false"
    >
      <sidebar
        v-if="isViewportSmall && sidebarOpen"
        :content="content"
        :opt-container-content="content"
        @sidebarClose="sidebarOpen = false"
      ></sidebar>
    </transition>
    <transition
      enter-active-class="animated faster slideInRight"
      leave-active-class="animated faster slideOutRight"
      v-on:after-enter="showSidebarLayer = true"
      v-on:before-leave="showSidebarLayer = false"
    >
      <lang-sidebar v-if="!isViewportSmall && langSelectorOpen" @sidebarClose="langSelectorOpen = false"></lang-sidebar>
    </transition>
    <div class="container pt-3" v-if="!isViewportSmall">
      <div class="row align-items-center">
        <a :href="applyContextToUrl('')" class="d-none d-md-block col-auto p-0 mr-3">
          <div class="m-0 logo-container d-flex justify-content-center align-items-center" style="line-height: 0;" :title="content.logo.title">
            <img class="logo" v-if="content.logo" :src="content.logo.url" :alt="content.logo.title">
          </div>
        </a>
        <div class="col-auto position-relative">
          <transition enter-active-class="animated faster slideInRight" leave-active-class="animated faster slideOutRight" v-on:after-enter="afterEnterCallback()" v-if="!isViewportSmall">
          <div class="h-100 d-flex align-items-center position-absolute search-stripe bg-cream mx-3" v-if="searching">
            <div class="pl-md-4 mx-3 d-flex align-items-center w-100">
              <div class="input-group search-input flex-grow-1">
                <input type="text" class="form-control border-cream" :placeholder="ft('globals.search')" v-model="searchText" v-on:keyup.enter="search()" v-on:click="showSearchLayer = true">
                <div class="input-group-append btn-primary d-flex align-items-center px-3" @click="search()">
                  <i class="far fa-search cursor-pointer"></i>
                </div>
              </div>
              <div class="pr-md-4 mx-3 d-flex align-items-center" @click="searching = false; showSearchLayer = false;">
                <i class="fal fa-times cursor-pointer link-black"></i>
              </div>
            </div>
          </div>
          </transition>
          <navbar :content="content" :opt-container-content="content"></navbar>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[header] {

  $header-logo-width: 7.575rem;
  $header-logo-height: 6.875rem;

  z-index: $zindex-menu-item + 2;

  @include media-breakpoint-up(md) {
    min-height: $header-logo-height * 1.5;
  }

  .logo-container {
    width: $header-logo-width;
    height: $header-logo-height * 0.6;

    @include media-breakpoint-up(md) {
      height: $header-logo-height;
    }
  }

  .logo {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .profile-menu {
    top: calc(100% + .25rem);
    right: 0;
    min-width: 8.5rem;
  }

  [aria-expanded="true"] {
    .fa-chevron-down {
      transform: rotate(180deg);
    }
  }

  .hamburger {
    @include font-size(1.25rem);
    line-height: 1rem;
  }

  .search {
    z-index: 1;
  }

  .fal.fa-times,
  .far.fa-search {
    @include font-size(1.25rem);
  }

  .search-stripe {
    z-index: $zindex-menu-item + 2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      left: -1.5rem;
      top: -5rem;
      width: 100vw;
      max-width: map-get($container-max-widths, "md") - $grid-gutter-width;
    }

    @include media-breakpoint-up(lg) {
      max-width: map-get($container-max-widths, "lg") - $grid-gutter-width * 1.5;
    }

    @include media-breakpoint-up(xl) {
      max-width: map-get($container-max-widths, "xl") - $grid-gutter-width * 2;
    }
  }

  .search-input {
    box-shadow: 0 0 .625rem 0 rgba($black, 0.1);
    border-radius: .625rem;
    overflow: hidden;

    .form-control {
      height: 2.2rem;
      @include font-size(1rem);

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .far.fa-search {
      @include font-size(1.125rem);
    }
  }

  @include media-breakpoint-down(sm) {
    .hamburger {
      display: block !important;
    }

    .search > div {
      display: none !important;
    }
  }

}

[layer-dark] {
  background-color: rgba($black, .35);
  z-index: 1;
  top: 0;
}

[layer-light] {
  background-color: rgba($white, .5);
  z-index: $zindex-menu-item + 1;
  top: 0;
}
</style>
