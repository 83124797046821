<script>
import { initMap } from 'src/modules/google_maps_module.js';
import { fandomTranslate } from "src/modules/common_module.js";

export default {
  props: {
    inputName: {
      type: String,
      required: true
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    inputRequired: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String,
      required: true
    },
    initialValue: {
      type: String,
      required: false,
      default: ""
    }
  },
  mounted() {
    initMap(() => {
      Vue.nextTick(() => {
        const params = {
          fields: ["formatted_address"],
          types: ["address"]
        };
        const autocomplete = new google.maps.places.Autocomplete(this.$refs["address"], params);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          this.$refs["address"].value = place.formatted_address;
          this.$emit('set-address', place.formatted_address);
        });
      });
    }, true);
  },
  computed: { },
  methods: {
    ft: fandomTranslate
  }
};
</script>

<template>
  <input
    :name="inputName"
    type="text"
    class="form-control"
    :placeholder="inputPlaceholder"
    ref="address"
    :required="inputRequired"
    :id="inputId"
    :value="initialValue"
    autocomplete="off"
  />
</template>