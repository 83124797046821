<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { sendFandomAnalyticsEvent }  from "src/modules/fandom_analytics_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      additionalFields: {
        fiscalCode: {
          "type": "fiscalcode",
          "label": {
            "en": "Fiscal code",
            "it": "Codice fiscale"
          },
          "name": "fiscal_code",
          "placeholder": {
            "en": "Fiscal code",
            "it": "Codice fiscale"
          },
          "required": true,
          "update_user_model": true,
        },
        email: {
          "type": "email",
          "label": "Email",
          "name": "email",
          "placeholder": "Email",
          "required": true,
          "update_user_model": true,
          "sales_manago_path": "contact.email"
        },
        password: {
          "type": "password",
          "label": "Password",
          "name": "password",
          "required": true,
          "pattern": "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$",
          "update_user_model": true
        }
      }
    }
  },
  created() {
    const formFields = JSON.parse(this.content.form_fields);
    if (formFields && formFields.fields && formFields.fields instanceof Array) {
      if (!Fandom.isUserRegistered()) {
        formFields.fields.splice(formFields.fields.length - 1, 0, this.additionalFields.email);
        formFields.fields.splice(formFields.fields.length - 1, 0, this.additionalFields.fiscalCode);
        formFields.fields.splice(formFields.fields.length - 1, 0, this.additionalFields.password);   
      } else if (!this.pageModel.user.fiscal_code) {
        formFields.fields.splice(formFields.fields.length - 1, 0, this.additionalFields.fiscalCode);
      }
    }
    Vue.set(this.content, 'form_fields', JSON.stringify(formFields));
  },
  computed: {
    showItemsBorders() {
      return Fandom.exists(this.content.show_items_borders) ? this.content.show_items_borders : false;
    }
  },
  methods: {
    formSubmitDone (data) {
      if (data.success) {
        sendFandomAnalyticsEvent("Simple Form", "Submit", `content_${this.contentId}_interaction_${this.content.interaction.id}`);
      }
    },
    setUser(data) {
      if (data.user && !data.user.anonymous) {
        Vue.set(this.pageModel, 'user', data.user);
        const formFields = JSON.parse(this.content.form_fields);
        const newFormFields = { fields: formFields.fields.filter(el => el.name != 'email' && el.name != 'password') };
        Vue.set(this.content, 'form_fields', JSON.stringify(newFormFields));
      }
    },
    getContentStyle () {
      var styleObject = {};
      if (Fandom.exists(this.content.background_color)) {
        styleObject["background-color"] = this.content.background_color;
      }
      if (Fandom.exists(this.content.background_image)) {
        styleObject["background-image"] = this.getContentResponsiveImageURL("background_image");
      }
      if (Fandom.exists(this.content.text_color)) {
        styleObject["color"] = this.content.text_color;
      }
      return styleObject;
    }
  }
};
</script>

<template>
  <div :id="content.name" v-easyadmin="easyadminId" class="bg-cover-center" :style="getContentStyle()" contest-form-component>
    <div class="container" :class="{'borderless-items': !showItemsBorders}">
      <div class="row justify-content-center align-items-center py-4">
        <div class="col-sm-12 col-md-8 col-lg-6">
          <p class="text-center font-sm pb-4 mb-1 text-medium-grey">{{content.title}}</p>
          <generic-form action="/contest_upload_simple_form" :content="content" :opt-container-content="containerContent" ref="form" @error="setUser" @done="formSubmitDone"></generic-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[contest-form-component] {
  .borderless-items {
    .form-control,
    .custom-select,
    .cuustom-file-input {
      border-width: 0px;
    }
  }
}
</style>
  
