<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  computed: {
    breadcrumb() {
      return get(globalState, ["pageModel", "breadcrumb"], []);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" class="position-relative" page-blank-component>
    <div v-if="!content.hide_breadcrumb">
      <breadcrumb/>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss">
  [breadcrumb-component] {
    ol {
      background-color: transparent;
    }
  }
</style>
