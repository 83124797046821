<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { isEmpty } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      userNotices: null
    };
  },
  mounted() {
    this.getUserNotices();
  },
  methods: {
    isEmpty: isEmpty,
    getIconByCategory(category) {
      if (category == "comment") {
        return "fal fa-comment";
      }
    },
    getIconClass(notice) {
      return notice.icon || this.getIconByCategory(notice.category);
    },
    getUserNotices() {
      Fandom.ajax({
        method: "GET",
        data: {},
        url: "/api/v5/get_user_notices",
        success: (data) => {
          this.userNotices = data;
        }
      });
    },
    setUserNoticeAsRead(notice) {
      const status = !notice.read;
      Fandom.ajax({
        method: "POST",
        data: { notice_id: notice.id, status: status },
        url: "/api/v5/set_user_notice_read_status",
        success: () => {
          notice.read = status;
        }
      });
    },
    getNoticeReferenceUrl(notice) {
      return this.applyContextToUrl(`/show_content_by_name/${notice.content_cache_name_reference}`);
    }
  }
};
</script>

<template>
  <div v-if="userNotices" user-notices-component>
    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <div class="row" v-if="!isEmpty(userNotices.date_to_notices)" v-for="(notices, date, index) in userNotices.date_to_notices" :class="{'mt-3': index > 0}" :key="index">
            <div class="col-12">
              <h3 class="h4 mb-3">{{date}}</h3>
            </div>
            <div class="col-12">
              <div class="user-notice d-flex align-items-center border-bottom justify-content-between px-3 py-2" :class="{'read': notice.read, 'unviewed': !notice.viewed}" v-for="notice in notices">
                <div class="d-flex align-items-center mr-2">
                  <div v-if="getIconClass(notice)">
                    <i class="user-notice-icon mr-2 d-flex align-items-center justify-content-center" :class="getIconClass(notice)"></i>
                  </div>
                  {{notice.text}}
                </div>
                <div class="d-flex align-items-center">
                  <a :href="getNoticeReferenceUrl(notice)" v-if="notice.content_cache_name_reference"><i class="fal fa-arrow-to-right mr-2 user-notice-reference"></i></a>
                  <div class="user-notice-status" @click="setUserNoticeAsRead(notice)"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isEmpty(userNotices.date_to_notices)"><h3 class="h4 mb-0 py-3" >{{ft("user_notices.empty")}}</h3></div>
        </div>  
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[user-notices-component] {
  
  .user-notice {

    .user-notice-reference {
      cursor: pointer;
    }

    .user-notice-icon {
      width: 2.5rem;
      height: 2.5rem;
      color: $white;
      border-radius: 100%;
      background-color: $primary;
    }

    .user-notice-status {
      border-radius: 100%;
      background-color: $primary;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    &.unviewed {
      background-color: $gray-100;
    }

    &.read {
      opacity: 0.5;

      .user-notice-status {
        border: 1px solid $primary;
        background-color: transparent;
      }
    }

  }
}
</style>
