<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import registrationForm from '../support_components/registration-form.vue';

export default {
  props: {
    initData: {
      type: Object,
      default: () => { return {} }
    }
  },
  components: { registrationForm },
  mixins: [fandomBasicMixin],
  data() {
    return {
      userData: {}
    }
  },
  mounted() {
    const formFields = JSON.parse(this.content.form_fields).fields;
    let userData = localStorage.getItem('userData');
    if (userData) {
      userData = JSON.parse(userData);
    } else {
      userData = this.initData;
    }
    formFields.forEach(field => {
      Vue.set(this.userData, field.name, userData[field.name]);
    });
  },
  methods: {
    submit() {
      (this.$refs["form"].formFields || []).forEach(field => {
        const el = document.getElementById(`user_${field.name}`);
        const value = field.type === "checkbox" ? el.checked : el.value;
        Vue.set(this.userData, field.name, value);
      });
      this.$emit('update', {data: this.userData});
    }
  },
  computed: { }
};
</script>

<template>
  <div class="container py-4 py-md-5" v-easyadmin="easyadminId" :class="contentType" stripe-selection-component>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 col-xl-6">
        <text-section class="text-center" v-if="content.title || content.description" :content="content" :opt-container-content="containerContent"></text-section>
        <form @submit.stop.prevent="submit" class="registration-form">
          <registration-form :init-data="userData" ref="form" :show-email-field="true" :form-config="content.form_fields"></registration-form>
          <div class="my-4 d-flex justify-content-center">
            <button type="submit" class="btn btn-primary">{{content.button_text || ft('globals.next')}}</button>
          </div>
        </form>
        <small v-html="content.footer" v-if="content.footer"></small>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>