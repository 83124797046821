<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { Youtube } from "src/modules/youtube_module.js";
import { pick, get } from "lodash";
import Flickity from "flickity";
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";

const durationConverter = {
  "number": val => val,
  "string": val => parseInt(val.replace(/\D/g, ""))
};
const zeroFun = () => 0;
const getDurationTime = val => get(durationConverter, typeof val, zeroFun)(val);

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  mounted() {
    Vue.nextTick(() => {
      const procedure = $(this.$refs["procedure"]);
      if (procedure.length != 0) {
        procedure.magnificPopup({type: "image", delegate: ".magnific-popup"});
      }
      if (Fandom.exists(this.content.accessories)) {
        const carousel = new Flickity(this.$refs["accessory-carousel"], {
          draggable: ">1",
          prevNextButtons: false,
          pageDots: false,
          cellAlign: "left",
          wrapAround: false,
          freeSCroll: false,
          contain: true
        });
      }
    });
  },
  methods: {
    getDurationTime,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
    toggleYoutubePopup: Youtube.toggleYoutubePopup,
    getInfoContentTitle (field) {
      const name = get(this, ["info", field], false);
      const content = name ? this.getContent(name) : {}; 
      return get(content, "title", false);
    },
    getTagsInfoFromContents(name, info = "title") {
      const field = get(this.content, name, []);
      const arrayConverter = content => Fandom.exists(content) ? pick(content, info) : false;
      const baseConverter = content => Fandom.exists(content) ? content[info] : false;
      const converter = Array.isArray(info) ? arrayConverter : baseConverter;
      return field.map(this.getContent).map(converter);
    },
    ingredientsConverter(ingredients) {
      return ingredients.map(ingredient => {
        const content = ingredient["ingredient"] ? this.getContent(ingredient["ingredient"]) : {};
        const title = get(ingredient, "title", "");
        return {
          quantity: get(ingredient, "quantity", ""),
          title: get(content, "title", title)
        };
      });
    }
  },
  computed: {
    headerBackground() {
      const url = Fandom.retrieveFromGlobalState("recipe_header_background", false);
      return url ? `url("${url.url}")` : null;
    },
    chefAdvice() {
      return get(this, ["content", "chef_advice"], this.ft("recipe.chef_advice"));
    },
    recipeLevel() {
      return get(this, ["content", "decorators", "access-level"], false);
    },
    favourite() {
      return get(this, ["content", "interactions", "favourite"], false);
    },
    share() {
      return get(this, ["content", "interactions", "share"], false);
    },
    like() {
      return get(this, ["content", "interactions", "like"], false);
    },
    info() {
      return get(this, ["content", "info"], false);
    },
    difficulty() {
      return this.getInfoContentTitle("difficulty");
    },
    accessories() {
      return this.getTagsInfoFromContents("accessories", ["slug","thumbnail","title"]);
    },
    specialOccasions() {
      return this.getTagsInfoFromContents("special_occasions",["slug","title"]);
    },
    allergens() {
      return this.getTagsInfoFromContents("allergens");
    },
    expence() {
      return this.getInfoContentTitle("expence");
    },
    imageUrl() {
      return this.getContentResponsiveImage("image").replace("/original/", "/medium/");
    },
    ingredients() {
      const ingredients = get(this.content, "ingredients", []);
      return this.ingredientsConverter(ingredients);
    },
    secondaryIngredients() {
      const ingredients = get(this.content, "secondary_ingredients", []);
      return this.ingredientsConverter(ingredients);
    },
    owner() {
      const owner = get(this, ["content", "owned_by"], false);
      return get(this, ["pageModel", "id_to_user", owner], false);
    },
    totalTime() {
      const timeRecord = pick(this.content.time, ["cooking_time", "resting_time", "preparation_time"]);
      const reducer = (acc, val) => (+acc) + getDurationTime(val);
      return Object.values(timeRecord).reduce(reducer, 0);
    },
    titleInfo() {
      const title = this.ft("recipe.ingredients");
      const servings = get(this, ["info", "quantity"], false);
      return servings ? title + this.ft("recipe.ingredients_for", { servings }) : title;
    },
    isYoutubePresent() {
      return get(this.content, ["play", "media_type"], false) === "youtube";
    },
    contentClasses() {
      return [this.contentType, this.recipeLevel];
    },
    ownerAvatar() {
      return get(this, ["owner", "avatar"], false);
    },
    ownerPage() {
      const url = get(this, ["content", "author_page", "url"], false);
      return url ? Fandom.applyContextToUrl(url) : false;
    },
    ownerTag() {
      return this.ownerPage ? "a" : "div";
    },
    quoteImage() {
      const image = get(this, ["content", "quote_icon"], this.ownerAvatar);
      return get(image, ["url"], false);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentClasses" recipe>
    <div class="container pt-4">
      <div class="row">
        <div class="col-12">
          <breadcrumb class="px-0"></breadcrumb>
        </div>
      </div>
    </div>
    <div class="w-100 bg-light-red py-md-3 bg-cover-top" :style="{'background-image': headerBackground}">
      <div class="container py-3">
        <div class="row justify-content-between">
          <div class="recipe-info col-12 col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1">
            <div class="row align-items-center justify-content-between px-3 pr-md-0">
              <div class="category-wrapper d-inline-flex align-items-center col-auto px-0 mb-3" v-if="decorator">
                <div v-if="decorator.title" class="font-xs text-white border border-white py-2 px-3 rounded">{{decorator.title}}</div>
              </div>
              <div v-if="like || favourite" class="font-xl d-flex align-items-center justify-content-end col-auto mb-3 px-0">
                <div v-if="like" class="interactions-like d-flex align-items-center">
                  <span class="font-sm text-white">{{getLikeCounter(containerContent, like.interaction, "")}}</span>
                  <i
                    class="px-2 text-white-hover cursor-pointer fa-heart"
                    :class="[isLikeDone(containerContent, like.interaction) ? 'fas text-white' : 'fal']"
                    @click="updateLikeInteraction(containerContent, like, like.interaction)"
                    >
                  </i>
                </div>
                <div v-if="favourite" class="interactions-favourite d-flex">
                  <i
                    class="fa-bookmark text-white-hover cursor-pointer px-2"
                    :class="[isFavourite(containerContent, favourite.interaction) ? 'fas text-white' : 'fal']"
                    @click="openFavouriteModal(containerContent, favourite, favourite.interaction)"
                    >
                  </i>
                </div>
              </div>
              <div class="recipe-info-title col-12 py-3 pl-0">
                <h1 class="h4 text-white">{{content.title}}</h1>
              </div>
              <component
                :is="ownerTag"
                :href="ownerPage"
                class="recipe-info-owner d-flex align-items-center pl-0 col-12"
                :class="ownerPage ? 'link-white' : 'text-white'"
                v-if="owner"
                >
                <div v-if="owner.avatar" class="owner-avatar rounded-circle bg-cover-center" :style="{'background-image': getBackgroundUrl(owner.avatar.url)}"></div>
                <span class="pl-2 font-sm">By <b>{{owner.first_name}} {{owner.last_name ? owner.last_name : ''}}</b></span>
              </component>
              <div v-if="content.info" class="recipe-info-details col-12 py-4 pl-0 font-xs">
                <div class="row">
                  <div v-if="difficulty" class="col-4 py-2 py-sm-3">
                    {{ft("recipe.difficulty")}}: <strong>{{difficulty}}</strong>
                  </div>
                  <div v-if="expence" class="col-4 py-2 py-sm-3">
                    {{ft("recipe.cost")}}: <strong>{{expence}}</strong>
                  </div>
                  <div v-if="content.info.quantity" class="col-4 py-2 py-sm-3">
                    {{ft("recipe.servings")}}: <strong>{{content.info.quantity}}</strong>
                  </div>
                </div>
                <div v-if="content.time" class="recipe-times row font-xs">
                  <div class="col-4 py-2 py-sm-3" v-html="ft('recipe.preparation_time', { time: getDurationTime(content.time.preparation_time) })"></div>
                  <div class="col-4 py-2 py-sm-3" v-html="ft('recipe.resting_time', { time: getDurationTime(content.time.resting_time) })"></div>
                  <div class="col-4 py-2 py-sm-3" v-html="ft('recipe.cooking_time', { time: getDurationTime(content.time.cooking_time) })"></div>
                  <div class="col-12 py-2 py-sm-3 font-weight-bold text-white font-lg" v-html="ft('recipe.total_time', { time: totalTime })"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-xl-7 pl-lg-0 order-1 order-lg-2">
            <!-- <div v-if="content.image" class="main-img bg-cover-center" :style="{'background-image': getBackgroundUrl(content.image.url, 'medium')}"></div> -->
            <div v-if="content.image" class="main-img position-relative rounded overflow-hidden w-100">
              <img :src="imageUrl" class="recipe-main-image rounded w-100 h-100 position-absolute" :title="content.title" :alt="content.title" />
              <embed-youtube class="position-absolute-center rounded overflow-hidden" :content="content" :opt-container-content="containerContent" v-if="isYoutubePresent"></embed-youtube>
            </div>
            <div v-else class="recipe-main-image mw-100 position-absolute ar-16by9 bg-light-grey rounded">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-3 mt-4">
      <div class="row justify-content-between"> 
        <div class="col-12 col-lg-4 mb-auto">
          <ingredients-box
            v-if="allergens.length > 0 || ingredients.length > 0"
            :allergens="allergens"
            :ingredients="ingredients"
            :title="titleInfo"
            >
          </ingredients-box>
          <ingredients-box
            class="mt-4"
            v-if="secondaryIngredients.length > 0"
            :allergens="allergens"
            :ingredients="secondaryIngredients"
            :title="titleInfo"
            >
          </ingredients-box>
          <div class="share-interactions d-flex align-items-center px-4 pt-3" v-if="share">
            <div class="font-sm pr-3">{{ft("recipe.share_recipe")}}:</div>
            <share-icons :content="content" :containerContent="containerContent"></share-icons>
          </div>
        </div>
        <div class="recipe-main-content align-self-start col-12 col-lg-8 col-xl-7 pl-lg-0">
          <div class="row"> 
            <div class="introduction col-12" v-if="content.description">
              <h3 class="pb-2">{{ft("recipe.introduction")}}</h3>
              <p class="pt-2 pb-0 mb-0" v-html="content.description"></p>
              <div v-if="specialOccasions" class="special-occasions d-flex pt-2 font-xs flex-wrap">
                <div v-for="(specialOccasion, index) in specialOccasions" class="pr-2 pb-2" :key="`occasion-${index}`">
                  <a :href="specialOccasion.slug" class="btn rounded-pill btn-premium-variable font-xs text-capitalize px-4 py-1">{{specialOccasion.title}}</a>
                </div>
              </div>
            </div>
            <div class="equipment pt-3 col-12" v-if="accessories.length > 0">
              <h4 class="pb-3 text-uppercase premium-variable-text">{{ft("recipe.equipment")}}</h4>
              <div class="accessory-carousel" ref="accessory-carousel">
                <div class="accessory-carousel-item border premium-variable-border rounded overflow-hidden" v-for="(accessory, index) in accessories" :key="`carousel-slide-${index}`">
                  <a class="position-absolute w-100 h-100" :href="accessory.slug">
                    <div v-if="accessory.thumbnail" class="w-100 h-100 position-absolute bg-cover-center" :style="{'background-image': getBackgroundUrl(accessory.thumbnail.url)}">
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div v-if="content.steps" class="procedure pt-4 mt-md-5 col-12" ref="procedure">
              <h4 class="pb-3 text-uppercase premium-variable-text">{{ft("recipe.procedure")}}</h4>
              <div v-for="(step, i) in content.steps" class="entire-step pb-4" :key="`step-${i}`">
                <div class="row">
                  <div class="col-auto d-flex pr-2">
                    <div class="step-counter premium-variable-bg icon-sizer italia-lt-font text-white d-flex rounded-circle align-items-center justify-content-center">
                      {{i+1}}
                    </div>
                  </div>
                  <template v-if="step.title">
                    <div class="col col-lg d-flex align-items-center pl-2">
                      <h5 class="step-title premium-variable-text font-xl italia-lt-font">{{step.title}}</h5>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-auto d-lg-block pr-2 d-none"><div class="step-counter-mock-spacer icon-sizer"></div></div>
                  </template>
                  <div class="col d-flex flex-column align-items-begin pl-lg-2 pt-2">
                    <div v-html="step.description"></div>
                    <a v-if="step.image" class="recipe-img w-100 bg-cover-center magnific-popup" :href="step.image.url" :style="{'background-image': getBackgroundUrl(step.image.url)}" ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="owner && content.preparation_advice" class="container-fluid recipe_quote premium-variable-bg text-white">
      <div class="row mx-0 py-4 justify-content-center">
        <div class="col-12 col-md-8 d-flex flex-column justify-content-center align-items-center py-2">
          <div 
            v-if="quoteImage" 
            class="quote-avatar rounded-circle mt-1 mb-3 bg-cover-center"
            :style="{'background-image': getBackgroundUrl(quoteImage)}"
          ></div>
          <div class="text-uppercase text-center pb-3 mb-0 h6">{{chefAdvice}}</div>
          <div class="d-block text-center quote-description mb-3" v-if="content.preparation_advice" v-html="content.preparation_advice"></div>
        </div>
      </div>    
    </div>
    <div v-if="content.extra_details" class="container-fluid py-4">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 py-3">
            <p class="h4 text-uppercase premium-variable-text">{{ft("recipe.extra_details")}}</p>
          </div>
          <div class="col-12 py-4">
            <div v-html="content.extra_details"></div>
          </div>
          <div v-if="share" class="col-12 py-3">
            <div class="share-interactions d-flex">
              <div class="d-flex align-items-center font-sm pr-3">{{ft("recipe.share_recipe")}}:</div>
              <share-icons :content="content" :containerContent="containerContent"></share-icons>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
    <stripe-related-articles
      v-if="!!content.related_content_article_tag || !!content.related_content_articles"
      :content="content"
      tagExtraField="related_content_article_tag"
      :optionalRelatedContentNames="content.related_content_articles"
    ></stripe-related-articles>
    <stripe-related-recipes
      :content="content"
      :tagExtraField="!!content.related_content_reciepe_tag ? 'related_content_reciepe_tag' : 'course'"
      :optionalRelatedContentNames="content.related_content_reciepes"
      :optionalViewMoreLink="(content.related_view_more_link || {}).url"
    ></stripe-related-recipes>
  </div>
</template>

<style lang="scss" scoped>
$icon-size: 2.625rem;

.main-img {
  padding-bottom: 57.6%;
}

.recipe-main-image {
  object-fit: cover;
  top: 0;
  left: 0;
}

.category-wrapper {
  line-height: 1;
}

.icon-sizer {
  height: $icon-size;
  width: $icon-size;
}

.owner-avatar {
  height: 4rem;
  width: 4rem;
}

.step-counter {
  @include font-size(1.75rem);
}

.recipe-img {
  padding-bottom: 52.5%;
  transition: opacity 0.35s;

  @include hover {
    opacity: 0.92;
  }
}

.accessory-carousel-item {
  width: 38.5%;
  padding-bottom: 38.5%;

  @include media-breakpoint-up(sm) {
    width: 23.5%;
    padding-bottom: 23.5%;
  }
}

.quote-avatar {
  width: 6rem;
  height: 6rem;
}

.quote-description {
  @include font-size(1.375rem);
  line-height: 1.45;
}

.btn-filter {
  color: $black;
  border-color: $black;

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $white;
    border-color: transparent;
    background-color: $red;
  }
}

.recipe-info-detail {
  &:last-child {
    padding-top: 0;
  }

  @include media-breakpoint-down(xs) {
    width: 50%;
    &:last-child {
      padding-top: 1rem;
    }
  }
}


.share-interactions {
  ::v-deep {
    .share-icon .fa-lg {
      @include font-size(1.25rem);

      @include media-breakpoint-up(lg) {
        @include font-size(1rem);
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .recipe-times {
    .border-right {
      border-right: none !important;
    }
  }
}
</style>
