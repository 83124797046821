<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" class="position-relative" page-stats>
    <div class="container px-3 px-sm-0 py-4">
      <div class="row justify-content-left">
        <h1 class="h2 text-red text-left">{{ft("stats.registered_users")}}</h1>
        <div class="col-12">
          <div class="row py-4">
            <div class="col-md-2">
              <div class="form-group">
                <label class="text-input">{{ft("stats.from_date")}}</label><br />
                <input class="form-control" type="date" v-model="params.from_date" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="text-input">{{ft("stats.to_date")}}</label><br />
                <input class="form-control" type="date" v-model="params.to_date" />
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-12 flex-column">
              <p>Totale utenti nel periodo:     {{userStats.pizzaiolo + userStats.professionista + userStats.appassionato + userStats['pizzaiolo-l5s']}}</p>
              <p>Pizzaiolo L5S:     {{userStats['pizzaiolo-l5s']}}</p>
              <p>Pizzaiolo Generico:     {{userStats.pizzaiolo}}</p>
              <p>Professionista:     {{userStats.professionista}}</p>
              <p>Appassionato:     {{userStats.appassionato}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      totalUser : 0,
      userStats: [],
      params: {
        from_date: undefined,
        to_date: undefined,
      },
    };
  },

  mounted() {
    this.doFilter();
  },

  methods: {
    doFilter() { 
      if ((this.params.from_date != undefined) && (this.params.to_date != undefined)) {
        Fandom.ajax({
          url: `/api/v5/users/statistics?start_date=${this.params.from_date}&end_date=${this.params.to_date}`,
          method: 'GET',
          success: data => {
              this.userStats = data;
          }
        });
      } else {
        Fandom.ajax({
          url: `/api/v5/users/statistics`,
          data: this.params,
          method: 'GET',
          success: data => {
              this.userStats = data;
              this.totalUser = this.userStats.pizzaiolo + this.userStats.professionista + this.userStats.appassionato + this.userStats['pizzaiolo-l5s'];
          }
        });
      }
    }
  },

  watch: {
    params: {
      handler(newValue, previousValue) {
        this.doFilter();
      },
      deep: true
    },
  },
};
</script>

<style lang="scss">

</style>