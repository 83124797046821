<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { cinqueStagioniBasicMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  methods: {
    isItemActive(item) {
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    getItemUrl(item) {
      const url = get(item, ["url", "url"], "");
      const location = get(window, ["location", "search"], "");
      const anchor = url.split("#")[1];
      const anchorUrl = anchor ? `#${anchor}` : url;
      return this.content.preserve_url_params ? `${url}?${location.substr(1)}` : anchorUrl;
    },
    itemClasses(item) {
      return this.isItemActive(item) ? "text-red" : "link-black";
    }
  },
  computed: {
    contentClasses() {
      const classes = [this.contentType];
      if (this.content.sticky || this.sticky) {
        classes.push("sticky-top");
      }
      return classes;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentClasses]" navbar-secondary-component>
    <nav class="navbar navbar-expand-md d-flex justify-content-between" id="secondary-nav">
      <div class="navbar-nav mx-auto">
        <li class="nav-item text-uppercase mr-3" v-for="(item, index) in content.items" :class="{'active': isItemActive(item)}" :key="index">
          <a 
            class="nav-link font-sm font-weight-bold px-3 py-2 pl-0" 
            :class="itemClasses(item)" 
            :href="getItemUrl(item)"
            >
            {{item.url.title}}
          </a>
        </li>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@include media-breakpoint-up(md) {
  #secondary-nav {
    height: 5rem;
  }
}
</style>
