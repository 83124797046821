<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import { favouriteInteractionMixin} from 'src/modules/favourite_interaction_module.js';

export default {
  mixins: [fandomBasicMixin, interactionMixin, favouriteInteractionMixin],
  data: function() {
    return {
      errorMessages: [],
      newListName: "",
      selectedFavouriteLists: {}
    };
  },
  mounted: function() {
    if (Fandom.isUserRegistered()) {
      $('#favourite-interaction-modal').on('show.bs.modal', (e) => {
        let successCallback = (data) => {
          for(let listId of data.content_list_ids) {
            Vue.set(this.selectedFavouriteLists, listId, true);
          }
        };
        this.getFavouriteLists(() => {this.getContentLists(this.containerContent.id, successCallback);});
      });

      $('#favourite-interaction-modal').on('hidden.bs.modal', (e) => {
        this.errorMessages = [];
        Vue.set(this, "selectedFavouriteLists", {});
        this.listName = "";
      })
    }
  },
  computed: {
    favouriteLists: () => {
      return globalState.pageModel.favouriteLists || [];
    },
    interactionRecord: () => {
      return globalState.requiredModals.favourite.interactionRecord || {};
    },
    interaction: () => {
      return globalState.requiredModals.favourite.interaction || {}; 
    },
    textBlocks: function() {
      let favouriteModalLayout =  this.retrieveFromGlobalState("favourite_modal", false)
      if (favouriteModalLayout) {
        const modalText1 = favouriteModalLayout["modal_text_1"] ? favouriteModalLayout["modal_text_1"] : "";
        const modalText2 = favouriteModalLayout["modal_text_2"] ? favouriteModalLayout["modal_text_2"] : "";
        const modalTextAnonymous = favouriteModalLayout["modal_text_anonymous"] ? favouriteModalLayout["modal_text_anonymous"] : "";
        return { modalText1: modalText1, modalText2: modalText2, modalTextAnonymous: modalTextAnonymous };
      } 
    },
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    retrieveFromGlobalState: Fandom.retrieveFromGlobalState,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    addFavouriteList(newListName) {
      let successCallback = (data) => {
        if (data.success) {
          Vue.set(globalState.pageModel.favouriteLists, data.favourite_list.id, data.favourite_list.name);
        } else {
          this.errorMessages = data.errors;
        }
      }
      this.createFavouriteList(newListName, successCallback);
    },
  }
};
</script>

<template>
  <div id="favourite-interaction-modal" class="modal fade" tabindex="-1" role="dialog" favourite-modal>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" v-if="isUserRegistered()">
      <div class="modal-content">
        <div class="modal-header p-0">
          <div class="container-fluid px-4 px-sm-3 py-3">
            <div class="row justify-content-center">
              <div class="col-12 col-sm-10 text-sm-center pt-3 pb-2">
                <div class="text-uppercase font-sm">{{ft("favourite.add_to_cookbook")}}</div>
              </div>
              <div class="col-12 col-sm-10 text-sm-center pt-0 pt-sm-2">
                <p>"{{containerContent.title}}"</p>
              </div>
              <button type="button" class="close m-0 p-0" data-dismiss="modal" aria-label="Close">
                <i aria-hidden="true" class="fal fa-times text-black"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body p-0">
          <div class="container-fluid px-4 px-sm-3 pt-3 pb-5">
            <div class="row justify-content-center">
              <div class="col-12 col-sm-10 py-4 font-sm text-uppercase">{{ft("favourite.list_lists")}}</div>
              <div v-if="globalState.pageModel.favouriteLists" class="col-12 col-sm-10 favourite-lists">
                <ul class="p-0">
                  <li v-for="(listName, listId) in globalState.pageModel.favouriteLists" :key="listId" class="favourite-lists__element d-flex align-items-center py-2">
                    <div class="favourite-lists__element__icon pr-4">
                      <i class="fa-bookmark" :class="[selectedFavouriteLists[listId] ? 'fas text-red' : 'fal']" @click="updateFavouriteInteraction(containerContent, interactionRecord, interaction, listId)"></i>
                    </div>
                    <div @click="updateFavouriteInteraction(containerContent, interactionRecord, interaction, listId)" :class="{'text-red':selectedFavouriteLists[listId]}">
                      <div v-if="listName" class="favourite-lists__element__name font-sm text-uppercase pl-2">{{listName}}</div>
                      <div v-else="listName" class="favourite-lists__element__name font-sm text-uppercase pl-2">{{ft("favourite.my_cookbook")}}</div>
                    </div>
                  </li>
                  <li class="favourite-lists__element d-flex align-items-center">
                    <div class="favourite-lists__element__icon pr-4">
                      <i class="fal fa-bookmark"></i>
                    </div>
                    <div class="favourite-lists__element__new-list pl-1 align-self-end">
                      <input type="text" class="pl-1 text-uppercase font-sm" name="listName" v-model="newListName" :placeholder="ft('favourite.create_list_placeholder')" >
                    </div>
                    <div>
                      <button class="btn btn-red ml-3 px-3 px-sm-5" @click="addFavouriteList(newListName)">{{ft("favourite.create_list")}}</button>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-if="errorMessages.length > 0" class="col-12 col-sm-9 col-lg-7 mt-4 mb-0 text-center alert alert-danger">
                <div v-for="errorMessage in errorMessages" class="">{{errorMessage}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0" v-if="textBlocks">
          <div class="container-fluid p-3">
            <div class="row justify-content-center">
              <div class="col-12 py-2 text-center" v-if="textBlocks.modalText1" v-html="textBlocks.modalText1"></div>
              <div class="col-12 py-2 text-center" v-if="textBlocks.modalText2" v-html="textBlocks.modalText2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- popup for anonymous users -->
    <div v-else class="modal-dialog modal-dialog-centered modal-favourite" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <button type="button" class="close m-0 p-0" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="fal fa-times text-black"></i>
          </button>
          <div class="container-fluid pt-5 pb-4">
            <div class="row pt-3 justify-content-center">
              <div class="col-10 py-2"><p class="text-center">{{ft("favourite.anonymous_user_alert_title")}}</p></div>
              <div class="col-10 py-2"><p class="text-center m-0">{{ft("favourite.anonymous_user_alert_description")}}</p></div>
              <div class="col-12 col-sm-10 text-center py-3">
                <a href="/users/sign_up" class="btn btn-red btn-lg ml-3 text-white">{{ft("favourite.anonymous_user_alert_button")}}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0">
          <div class="container-fluid p-4">
            <div class="row justify-content-center">
              <div class="col-sm-10 text-center" v-if="textBlocks" v-html="textBlocks.modalTextAnonymous"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" >
  [favourite-modal] {
    a {
      color: $red;
    }

    .favourite-lists__element__icon {
      @include font-size(1.25rem);
      i {
        cursor: pointer;
      }
    }

    .favourite-lists__element__name {
      cursor: pointer;
      &:hover {
        color: $red;
      }
    }

    .favourite-lists__element__new-list {
      flex: auto;
      input {
        border-bottom: 1px solid $gray-300 !important;
        border: none;
        width: 95%;
        padding: 0.4rem 0;
      }
    }

    .modal-footer {
      p {
        margin: 0 !important;
      }
    }

    @include media-breakpoint-up(sm) {
      .modal-lg {
        max-width: 700px;
      }

      .modal-favourite {
        max-width: 610px;
      }
    }
  }

</style>
