<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    alternativeAppendContents: Function,
    trackTileClick: Array
  },
  computed: {
    componentClasses() {
      let classes = [ this.contentType ];
      if (this.content.theme) {
        classes.push(`${this.content.theme}-theme`);
      }
      return classes;
    },
    mainStyles() {
      return {
        "background-color": this.content.background_color,
        "color": this.content.text_color,
        "background-image": this.getContentResponsiveImageURL("background_image"),
        "background-size:": "cover",
        "--default-tile-padding-bottom": this.content.children.reduce(
          (res, val) => res && ["highcontent", "largesquarecontent"].includes(this.getSizeClass(Fandom.getContent(val))),
          true
        ) ? '100%' : '50%'
      };
    }
  },
  methods: {
    getSizeClass(element) {
      return element.isotope_size ?? this.content.isotope_size ?? "default";
    },
    appendContents() {
      if (Fandom.exists(this.alternativeAppendContents) && typeof this.alternativeAppendContents === "function") {
        this.alternativeAppendContents(this.content.name, () => {});
      } else {
        Fandom.appendContents(this.content.name, () => {});
      }
      Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
    }
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="[componentClasses, outsidePadding]" :style="mainStyles" css-grid>
    <stripe-header :content="content" v-if="content.title_composed"></stripe-header>
    <div v-if="content.children">
      <div v-if="content.children.length == 0">
        <p>{{ft("globals.no_content")}}</p>
      </div>
      <div class="container" v-else>
        <div class="row">
          <div class="col-12">
            <div class="css-grid">
              <div
                class="grid-item rounded position-relative overflow-hidden"
                :class="getSizeClass(getContent(child))"
                v-for="(child, key) in content.children"
                :key="`grid-child-${key}`"
                >
                <component :is="getContent(child).layout" :content="getContent(child)" :trackTileClick="trackTileClick"></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container p-0 mt-4" v-if="content.has_more_children">
      <div class="row">
        <div class="col-sm-12 text-center">
          <button class="btn shadow-none" :class="getPrimaryButtonClass(content.button_class, content.theme)" @click="appendContents()">{{ft("globals.load_more")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

[css-grid] {
  --columns: 1;
  --span-col: 1;
  --span-row: 1;
  --row-size: 50vmax;

  @media (orientation: landscape) {
    --columns: 2;
  }
  
  @include media-breakpoint-up(sm) {
    --columns: 2;
    --span-col: 2;
    --row-size: 1fr;
  }
  
  @include media-breakpoint-up(md) {
    --columns: 4;
    --span-row: 2;
  }

  &.dark-theme {
    background-color: $dark;
    color: $white;

    .flickity-page-dots .dot {
      background-color: $white;
    }
  }

  .css-grid {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-auto-rows: var(--row-size);
    grid-auto-flow: dense;
    grid-gap: 1rem;

    @include media-breakpoint-up(sm) {
      margin: 0 -1rem;
    }
    
    @include ie {
      display: flex;
      flex-flow: row wrap;
    }
  }

  .grid-item {
    padding-bottom: var(--default-tile-padding-bottom, 50%);

    &.inlinecontent {
      padding-bottom: 0;
    }
    
    &.largesquarecontent {
      grid-column: span var(--span-col);
      grid-row: span var(--span-row);
    }

    &.widecontent {
      grid-column: span var(--span-col);
    }

    &.highcontent {
      grid-row: span var(--span-row);
    }
    
    @include ie {
      margin: 1rem;
      width: calc(25% - 2rem);

      &.largesquarecontent,
      &.widecontent {
        width: calc(50% - 2rem);
      }

      &.highcontent {
        padding-bottom: 100%;
      }
    }
  }
}

</style>
