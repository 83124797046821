<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { CinqueStagioni } from '../../modules/cinque_stagioni_module.js'

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      layout: globalState.pageModel.layout,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div >
    <template v.if="layout.content.global_contents"> 
      <component
        v-for="child in layout.content.global_contents"
        :is="getContent(child).layout"
        :content="getContent(child)"
        :key="`global-component-${getContent(child).name}`"
        >
        </component>
    </template> 
  </div>
</template>

<style lang="scss">

</style>