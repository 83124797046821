<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      toplevelItems: [ ],
    };
  },
  mounted() {
    this.initItems();
  },
  methods: {
    initItems() {
      const header = this.globalState.pageModel.layout.header;
      header.menu.forEach((item) => {
        if (Fandom.exists(item.right_launch_box)) {
          const title = item.right_launch_box.title;
          const url1 = item.right_launch_box.url_1;
          const url2 = item.right_launch_box.url_2;
          let urls = [];
          if (url1) urls.push({url: url1});
          if (url2) urls.push({url: url2});
          if (title && urls.length > 0) {
            this.toplevelItems.push({
              title: title,
              subitems: urls
            });
          } else {
            console.warn("Missing subitem title or urls in right_launch_box, skipping...");
          }
        }
        if (Fandom.exists(item.menu)) {
          item.menu.forEach((subitem) => {
            const title = subitem.title;
            if (title && subitem.menu) {
              this.toplevelItems.push({
                title: title,
                subitems: subitem.menu
              });
            } else {
              console.warn("Missing subitem title or menu, skipping...");
            }
          });
        } else {
          if (Fandom.exists(item.url)) {
            this.toplevelItems.push(item);
          }
        }
      });

    }
  },
  computed: { },
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="contentType" 
    v-if="isContentVisibleToUser()" 
    visual-sitemap-component
  >
    <div class="w-100 py-4 text-center" v-if="content.title">
      <h2 class="h4">{{content.title}}</h2>
    </div>
    <div class="row mx-0 px-0 px-md-5 py-5 bg-light-grey">
      <div v-for="item in toplevelItems" class="col-6 col-sm-4 col-lg-2 d-flex flex-column pb-5 mb-4">
        <component
          :is="item.url ? 'a' : 'span'"
          :href="item.url ? item.url.url : null"
          :target="getTarget(item.url)"
          class="link-black"
        > 
          <h2 class="font-weight-bold text-uppercase font-xs mb-3">
            {{item.url ? item.url.title : item.title}}
          </h2>
        </component>
        <div v-for="subitem in item.subitems">
          <a 
            class="font-sm link-black"
            :href="subitem.url.url"
            :target="getTarget(subitem.url)"
          > {{subitem.url.title}} </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[visual-sitemap-component] {
  h2 {
    font-family: $neutra-text-font;
  }
}
</style>
