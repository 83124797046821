<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  computed: {
    bgColor() {
      return get(this, ["content", "background_color"], "primary");
    },
    textColor() {
      return get(this, ["content", "text_color"], "white");
    },
    componentClasses() {
      return [
        this.contentType,
        `bg-${this.bgColor}`,
        `text-${this.textColor}`,
        this.content.background_contain ? "bg-contain-center" : "bg-cover-center"
      ];
    },
    mainStyles() {
      const style = {
        "background-color": this.content.background_color,
        "background-image": this.getContentResponsiveImageURL("background_image"),
        "color": this.content.text_color
      };
      return this.content.theme ? {} : style;
    },
    linkClass() {
      return `text-${this.textColor}`;
    }
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="componentClasses" class="container-fluid py-4" :style="mainStyles" stripe-quote-component>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-10 d-flex flex-column justify-content-center align-items-center py-4 py-sm-5">
            <img 
              v-if="content.image" 
              :src="content.image.url" 
              class="avatar rounded-circle mt-1 mb-3"
              :alt="content.image.title"
            >
            <div class="h5 d-block text-center description mb-0" v-if="content.description" v-html="content.description"></div>
            <div class="d-flex flex-column align-items-center text-center mb-3" v-if="content.author">
              <span v-if="content.author.name" class="text-uppercase author font-weight-bold">
                {{content.author.name}}
              </span>
              <span v-if="content.author.role" class="role">
                {{content.author.role}}
              </span>
            </div>
            <div class="d-flex justify-content-center pt-2" v-if="content.social_icons">
              <div v-for="(social, index) in content.social_icons" class="mx-3 mx-sm-2 social-icons" :key="index">
                <a 
                  v-if="social.url" 
                  :href="social.url.url" 
                  :target="getTarget(social.url)" 
                  :class="linkClass"
                  class="d-flex align-items-center justify-content-center"
                >
                  <i class="fab social-icon" v-if="social.icon" :class="social.icon"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<style lang="scss">
[stripe-quote-component] { 

  a {
    text-decoration: none;
    
    @include hover {
      text-decoration: none
    }
  }

  .social-icons {
    @include font-size(1.25rem);
  }

  .author {
    font-family: $neutra-text-font;
    @include font-size(1.125rem);
  }

  .description {
    @include font-size(1.875rem);
  }

  .avatar {
    width: 7.5rem;
    height: 7.5rem;
  }

  .role {
    font-family: $neutra-text-font;
    @include font-size(.875rem);
  }

  @include media-breakpoint-down(xs) {
    .social-icon {
      @include font-size(1.75rem);
    }
  }
}
</style>
