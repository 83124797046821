<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  mounted() {
    Vue.nextTick(() => {
      if (Fandom.exists(this.content.keypoint)) {
        const carousel = new Flickity(this.$refs["keypoints-carousel"], {
          draggable: ">1",
          prevNextButtons: false,
          pageDots: false,
          cellAlign: "left",
          wrapAround: false,
          freeSCroll: true,
          contain: true,
          imagesLoaded: true
        });
      }
    });
  },
  computed: { },
  methods: { }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="contentType" 
    v-if="isContentVisibleToUser()"
    list-keypoint-component
  >
    <div class="container-fluid row mx-0 px-0 px-sm-2 pb-3 d-flex justify-content-center" :style="{ 'background-color': content.background_color ? content.background_color : 'initial' }">
      <stripe-header :content="content"></stripe-header>
      <div class="col-12 justify-content-center flex-wrap px-0 pb-3 d-none d-sm-flex">
        <div v-for="(point, index) in content.keypoint" :key="`${contentId}-point-${index}`" class="keypoint mx-sm-4">
          <keypoint-item :keypoint="point"></keypoint-item>
        </div>
      </div>
      <div class="col-12 px-5 pb-3 d-sm-none overflow-hidden">
        <div class="keypoints-carousel" ref="keypoints-carousel">
          <div class="keypoints-carousel-item keypoint mx-3" v-for="(point, index) in content.keypoint" :key="`carousel-slide-${index}-mobile`">
            <keypoint-item :keypoint="point"></keypoint-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[list-keypoint-component] {

  .keypoint {
    width: 13.625rem;
    color: $dark;
    transition: color .5s ease-in-out;

    @include media-breakpoint-down(xs) {
      width: 35%;
    }
  }

  .flickity-viewport {
    overflow: visible;
  }


   /* force on extra large screens to have max 5 points in a row */
  @media only screen and (min-width: 1800px) {
    .keypoint {
      width: calc(20% - 4rem);
    }
  }
}

</style>
