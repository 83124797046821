<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { cinqueStagioniAuthorizationMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, cinqueStagioniAuthorizationMixin],
  methods: {
    isUserRegistered: Fandom.isUserRegistered
  },
  mounted() {
    globalState.showNewsletter = false;
  },
  computed: {
    isYoutubePresent() {
      return get(this.content, ["play", "media_type"], false) === "youtube";
    },
    titleClasses() {
      const color = this.titleColor ? `text-${this.titleColor}` : "text-red";
      return [color, this.content.title_class];
    },
    subtitleStyle() {
      return {
        color: this.content.text_color
      };
    },
    headerBackground() {
      const url = Fandom.retrieveFromGlobalState("recipe_header_background", false);
      return url ? `url("${url.url}")` : null;
    },
    buttonUrl() {
      return this.isUserRegistered() ? "/profile" : "/users/sign_in";
    },
    buttonTitle() {
      return this.isUserRegistered() ? this.ft("globals.complete_profile") : this.ft("globals.login.login");
    }
  }
};
</script>

<template>
  <div class="w-100" v-easyadmin="easyadminId" :class="contentType" page-preview-component>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <breadcrumb class="px-0"></breadcrumb>
        </div>
        <div class="col-12 col-md-8 text-center mt-3 mb-4">
          <h1 v-if="content.title" :class="titleClasses">{{content.title}}</h1>
          <h2 class="font-xl h3" v-if="content.subtitle" :style="subtitleStyle">{{content.subtitle}}</h2>
        </div>
        <div class="col-12 col-md-10 mb-4 pb-4">
          <div class="position-relative rounded overflow-hidden w-100 ar-16by9">
            <img :src="content.image" class="recipe-main-image rounded w-100 h-100 position-absolute" :title="content.title" :alt="content.title" v-if="content.image" />
            <embed-youtube class="position-absolute-center rounded overflow-hidden" :content="content" :opt-container-content="containerContent" v-if="isYoutubePresent"></embed-youtube>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="preview overflow-hidden position-relative">
            <div v-html="content.description"></div>
            <div class="w-100 h-100 position-absolute-center preview-gradient"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 bg-cover-top" :style="{'background-image': headerBackground}">
          <div class="row justify-content-center">
            <div class="col-10 col-md-8 col-xl-6 col-md-8 text-white d-flex flex-column align-items-center text-center py-5 my-5">
              <svg class="icon">
                <use xlink:href="#content-locked-icon"></use>
              </svg>
              <h4>{{ft("course_preview.locked_content_title")}}</h4>
              <div v-if="!isUserRegistered()" class="my-4 locked-content-description" v-html="ft('course_preview.locked_content_description_anonymous')"></div>
              <div v-else class="my-4 locked-content-description" v-html="ft('course_preview.locked_content_description')"></div>
              <a :href="buttonUrl" class="btn btn-light mx-auto mb-4" v-if="!isUserRegistered()">{{buttonTitle}}</a>
              <a v-else href="/supporto/contattaci" class="btn btn-light mx-auto">{{ft('course_preview.info_button_title')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[page-preview-component] {
  .preview {
    height: 12rem;
  }

  .preview-gradient {
    background-image: linear-gradient(to bottom, rgba($white, 0), $white);
  }

  .locked-content-description {
    a {
      color: #ffffff !important;
    }
  }

  .icon {
    width: 4rem;
    height: 4rem;
  }
}
</style>
