<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get, union } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      resultContent: {}
    };
  },
  created() {
    this.getSearchableContents();
  },
  methods: {
    getSearchableContents(appendContents = false) {
      const successBlock = data => {
        const mainContent = get(data, ["name_to_content", data.main_content_name], false);
        if (mainContent) {
          if (appendContents) {
            this.resultContent.children = union(this.resultContent.children, mainContent.children);
            this.resultContent.has_more_children = mainContent.has_more_children;
          } else {
            this.resultContent = mainContent;
          }
          
          // Workaround to prevent layout conflict with filter contents
          Object.keys(data.name_to_content).forEach(key => {
            const content = globalState.pageModel.name_to_content[key];
            if (content.name != mainContent.name) {
              Vue.set(globalState.pageModel.name_to_content, key, data.name_to_content[key]);
            }
          });

          Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
        }
      };
      Fandom.getSearchableContents(successBlock, this.params, appendContents);
    },
    alternativeAppendContents() {
      this.getSearchableContents(true);
    },
  },
  computed: {
    menuContent() {
      const name = get(this, ["content", "menu"], "");
      return Fandom.getContent(name);
    },
    tileLayout() {
      return get(this, ["content", "tile_layout"], "tile-recipe");
    },
    excludedContents() {
      const childrens = get(this, ["resultContent", "children"], []);
      const childrenIds = [];
      childrens.forEach(name => {
        const id = get(globalState, ["pageModel", "name_to_content", name, "id"], -1);
        if (id > -1) {
          childrenIds.push(id);
        }
      });
      return childrenIds;
    },
    params() {
      const limit = get(this, ["content", "limit"], 16);
      return {
        layout: this.tileLayout,
        layout_extra_field_name: "$empty",
        limit: parseInt(limit),
        include_rich_info: true,
        exclude_content_ids: this.excludedContents,
        content_filtering: [[this.content.name]]
      };
    }
  }
};

</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="w-100 pb-5" v-if="isContentVisibleToUser()" menu-component>
    <icon-menu :content="menuContent"></icon-menu>
    <grid-fixed
      :ref="`grid-${resultContent.name}`"
      :content="resultContent"
      :key="resultContent.name"
      :alternative-append-contents="alternativeAppendContents"
      :enable-infinite-scrolling="content.enable_infinite_scrolling"
      :column-count="content.column_count"
      >
    </grid-fixed>
  </div>
</template>
