<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity-bg-lazyload";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      carousel: null,
      currentSlideIndex: null,
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (!!this.content.items && this.content.items.length > 0) {
        var cmp = this;
        this.carousel = new Flickity(this.$refs["flickity-carousel-products"], {
          draggable: ">1",
          pageDots: this.content.flickity_dots,
          cellAlign: "center",
          prevNextButtons: true,
          wrapAround: true,
          imagesLoaded: true,
          //adaptiveHeight: true,
          arrowShape: { 
            x0: 10,
            x1: 60, y1: 50,
            x2: 65, y2: 45,
            x3: 20
          },
          on: {
            ready: function() {
              cmp.updateCurrentSlideIndex(0);
            },
            change: function( index ) {
              cmp.updateCurrentSlideIndex(index);
            },
          }
        });
      }
    });
  },
  methods: {
    titleStyle(item) {
      return {
        color: item.color
      }
    },
    anchorPointStyle(item) {
      return {
        'background-color': item.color
      }
    },
    updateCurrentSlideIndex(index) {
      this.currentSlideIndex = index;
    },
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
  },
  computed: {
    componentStyles() {
      if(this.content.items[this.currentSlideIndex]) {
        return {
          'background-color': this.content.items[this.currentSlideIndex].bg_color
        }
      } else {
        return {
          'background-color': this.content.background_color
        }
      }
    }
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" class="container-fluid py-5" :style="componentStyles" :class="contentType" :id="easyadminId" stripe-carousel-products-component>
    <div class="container">

      <h2 v-if="content.title" class="carousel-product-title text-center pt-sm-5 mb-0">{{content.title}}</h2>
      <div class="flickity-carousel-products w-100" ref="flickity-carousel-products">
        <div
          v-for="(item, index) in content.items"
          class="flickity-carousel-products-item"
          :key="`carousel-products-slide-${index}`"
          :id="`carousel-products-slide-${index}`"
          :class="{'is-selected': index === 0, 'pb-3': content.flickity_dots}"
          :ref="`carousel-products-slide-${index}`"
        >
          <div class="text-center d-flex flex-column justify-content-center align-items-center py-4">
            <div class="procuct-item__title h4 py-2 px-1 d-inline-block pb-4" :style="titleStyle(item)" v-if="item.title">{{item.title}}</div>
            <div class="slide-image-container">
              <div class="bg-contain-center slide-image" :style="{'background-image': getContentResponsiveImageURL('image', item)}"></div>
            </div>
            <div class="product-item__description d-inline-block my-4" v-if="item.description" v-html="item.description"></div>
            <a class="btn btn-primary px-4 py-2 mx-auto d-block" v-if="item.url" :href="item.url.url" :target="getTarget(item.url)">{{item.url.title}}</a>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center flex-wrap py-2" v-if="carousel && !content.flickity_dots">
        <div
          v-for="(item, index) in content.items"
          :key="`slide-anchor-${index}`"
          @click="carousel.select(index)"
          :class="{'active': carousel.selectedIndex === index}"
          class="d-flex flex-column justify-content-center align-items-center carousel-anchor cursor-pointer my-3"
        >
          <div class="rounded-circle anchor-point" :style="anchorPointStyle(item)"></div>
          <div class="small font-weight-bold mt-2 anchor-title" :style="titleStyle(item)">{{item.title}}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss">
[stripe-carousel-products-component] {

  .flickity-page-dots .dot {
    width: 15px !important;
    height: 15px !important;
    margin: 0 6px !important;
  }

  .carousel-product-title {
    font-size: 40px;
  }

  .flickity-carousel-products-item {
    width: 33.33334%;

    .slide-image-container {
      width: 65%;
      padding-bottom: 65%;
      position: relative;

      .slide-image {
        top: 0;
        width: 100%;
        position: absolute;
        padding-bottom: 100%;
        transition: all .5s;
        filter: opacity(100%);
      }
    }
    
    .procuct-item__title {
      padding-top: 0.8rem !important;
      font-size: 40px;
    }
    
    .procuct-item__title {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
    }
    .btn,
    .procuct-item__title,
    .product-item__description {
      transition: opacity .5s;
      opacity: 1;
    }
    .product-item__description {
      color: #575756;
      padding: 10px 0;
      border-top: 1px solid #575756;
      border-bottom: 1px solid #575756;

      & strong {
        display: block;
        margin-top: 1rem;
      }

      & > p:first-child {
        //margin-bottom: 0;
        line-height: 40px;
      }
    }
  }

  .flickity-carousel-products-item:not(.is-selected) {
    // height: 100%;
    .slide-image-container {
      .slide-image {
        width: 70%;
        padding-bottom: 70%;
        filter: opacity(50%);
      }
    }
    .btn,
    .procuct-item__title,
    .product-item__description {
      opacity: 0;
    }
  }

  .flickity-button {
    transform: scale(2);
  }
  .flickity-button,
  .flickity-button:hover {
    background-color: transparent !important;
  }

  .carousel-anchor {
    width: 7rem;
    .anchor-point {
      width: 2rem;
      height: 2rem;
      position: relative;
    }
    .anchor-title {
      font-size: .96rem;
    }
    &.active {
      .anchor-point::after {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        border-radius: 100%;
        border: 1px solid $black;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .carousel-product-title {
      font-size: 30px;
    }
    .flickity-carousel-products-item {
      width: 80%;
    }
    .flickity-button {
      transform: scale(1.25);
    }
    .procuct-item__title {
      font-size: 34px !important;
    }
    .product-item__description {
      font-size: 20px !important;
    }
    .carousel-anchor {
      width: 3.75rem;
      .anchor-point {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
      }
      .anchor-title {
        font-size: .8rem;
      }
    }
    .flickity-prev-next-button.previous {
      left: -1.25rem;
    }
    .flickity-prev-next-button.next {
      right: -1.25rem;
    } 
  }
}
</style>
