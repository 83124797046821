<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { videoBasicMixin, videoInteractiveMixin } from "src/modules/vimeo_module.js";
import { merge } from 'lodash';

export default {
  mixins: [fandomBasicMixin, videoBasicMixin, videoInteractiveMixin],
  data: function () {
    return {
      videoContent: this.getInitialVideoContent()
    };
  },
  watch: {
    videoPlayer: {
      handler(newValue, previousValue) {
        this.$emit("video-player-handler", newValue);
      },
      deep: true
    },
    overvideoInteraction(newValue, previousValue) {
      this.$emit("overvideo-interaction-handler", newValue);
    }
  },
  methods: {
    unsetOvervideoInteraction() {
      this.overvideoInteraction = null;
    },
    getInitialVideoContent() {
      return merge(this.content.play, {
        videoConfig: {
          autoplay: true,
          texttrack: globalState.lang,
          quality: "720p"
        }
      });
    }
  }
};
</script>

<template>
  <div embed-vimeo-component>
    <div :id="videoContentId"></div>
  </div>
</template>

<style lang="scss" scoped>
</style>