<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin]
};
</script>

<template>
  <icon-menu :content="content" v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType"></icon-menu>
</template>
