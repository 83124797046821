<script>

import { interactionMixin, getUrlFromPath } from "src/modules/interactions_module.js";
export default {
  props: {
    shareText: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.$on("copy", this.copyToClipboard);
  },
  methods: {
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.shareText).then(() => {
        $(this.$refs["modal"]).appendTo("body").modal("show");
      }, () => {
        console.log('Unable to copy values to clipboard');
      })
    }
  }
};
</script>

<template>
  <div class="modal fade" ref="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header position-relative border-0">
          <span class="mt-3 mt-md-4 h5 w-100 d-block text-center">{{title}}</span>
          <button type="button" class="close m-0 p-0" data-dismiss="modal" aria-label="Close">
            <i aria-hidden="true" class="fal fa-times text-black"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group">
            <textarea class="w-100 form-control" ref="textarea" rows="4" :value="shareText"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-control {
  border-color: $gray-400;
  box-shadow: none;
}

.close,
.form-control {
  outline-color: transparent !important;
  outline-style: none !important;
}
</style>
