<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      selection: 'undefined'
    }
  },
  mounted() {
    const userData = localStorage.getItem('userData');
    if (userData) {
      this.selection = JSON.parse(userData)[this.content.param_name];
    }
  },
  methods: {
    update() {
      const data = { };
      data[this.content.param_name] = this.selection;
      this.$emit('update', { data });
    }
  },
  computed: {
    url() {
      let url = this.content.url.url;
      if (this.content.param_name) {
        url += '?' + this.content.param_name + '=' + this.selection;
      }
      return url;
    },
    colClass() {
      return this.content.column_class || 'col-12';
    }
  }
};
</script>

<template>
  <div class="container text-center py-4 py-md-5" v-easyadmin="easyadminId" :class="contentType" stripe-selection-component>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 col-xl-6">
        <text-section v-if="content.title || content.description" :content="content" :opt-container-content="containerContent"></text-section>
        <div class="row">
          <div :class="colClass" v-for="(item, index) in content.items" :key="`item-${index}`">
            <div
              class="choice my-2 py-4 px-3 cursor-pointer text-uppercase text-center"
              @click="selection = item.label"
              :class="{'selected': item.label === selection}"
            >
              <small class="font-weight-bold">{{item.text}}</small>
            </div>
          </div>
        </div>
        <div class="my-4" v-html="content.footer" v-if="content.footer"></div>
        <div class="my-4" v-if="content.url">
          <a
            :href="url"
            class="btn btn-primary"
            :disabled="selection === 'undefined'"
            :class="{'disabled': selection === 'undefined'}"
          >{{content.url.title}}</a>
        </div>
        <div class="my-4 d-flex justify-content-center" v-else>
          <button
            class="btn btn-primary"
            :disabled="selection === 'undefined'"
            :class="{'disabled': selection === 'undefined'}"
            @click="update"
          >{{content.button_text || ft('globals.next')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-selection-component] {
  .choice {
    border: 1px solid $primary;
    border-radius: 8px;
    transition: all .25s;
    color: $primary;
    background-color: $white;

    &:hover {
      color: darken($primary, 10%);
      border-color: darken($primary, 10%);
      background-color: darken($white, 3%);
    }

    &.selected {
      background-color: $primary;
      color: $white;
    }
  }
}
</style>