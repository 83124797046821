<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { cinqueStagioniBasicMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { CinqueStagioni } from "../../modules/cinque_stagioni_module.js";
import { capitalize, get } from "lodash";

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin, interactionMixin],
  props: {
    trackTileClick: Array
  },
  mounted() {
    this.registerAnimation();
    // google analytics
    if (Fandom.exists(this.trackTileClick)){
      Vue.nextTick(() => {
        CinqueStagioni.trackLinkVisited(`#ref-${this.easyadminId}-link`, this.trackTileClick[0], this.trackTileClick[1], this.content.name);
      });
    }
  },
  methods: {
    capitalize,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getContentURL: Fandom.getContentURL
  },
  computed: {
    favourite() {
      return (this.content.interactions && this.content.interactions["favourite"]) ? this.content.interactions["favourite"] : false;
    },
    owner() {
      return get(this, ["pageModel", "id_to_user", this.content.owned_by], false);
    },
    tileUrl() {
      return this.content.url ? this.content.url.url : this.getContentURL(this.content);
    },
    accessLevel() {
      return get(this, ["content", "decorators", "access-level"], false);
    },
    accessLevelClass() {
      return this.accessLevel ? `bg-alpha-${this.accessLevel}` : "bg-alpha-red";
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="position-absolute-center bg-transition" tile-component>
    <a :href="tileUrl" :id="`ref-${easyadminId}-link`" :target="getTarget(content.url)" class="container-fluid h-100 p-0 d-flex flex-column justify-content-between">
      <div class="bg-cover-center position-absolute-center bg lazy-bg" :lazy-src="getContentResponsiveImageURL('thumbnail')"></div>
      <div v-if="!content.hide_gradient" class="position-absolute-center linear-gradient-y"></div>
      <div class="row tile__top mx-0 pt-3 pt-md-4">
        <div v-if="content.category" class="col-12 px-3 px-md-4">
          <span :class="accessLevelClass" class="category rounded d-inline-block px-1 py-1 px-sm-2 py-md-2 font-sm m-0">{{capitalize(content.category)}}</span>
        </div>
      </div>
      <div class="row tile__bottom mx-0 pb-3 pb-md-4">
        <div v-if="content.title_composed || content.title" class="col-12 px-3 px-md-4 pt-3">
          <div v-if="content.title_composed" class="title-composed">
            <span v-for="(titleLine, index) in content.title_composed" :class="[getTitleStyle(titleLine), index === 0 ? 'is-big-title mb-2' : 'is-small-title']" class="d-block m-0" :key="index">{{titleLine.title}}</span>
          </div>
          <div v-else class="title-simple">
            <span class="is-big-title m-0 title-italic">{{content.title}}</span>
          </div>
        </div>
        <div v-if="owner || favourite" class="col-12 px-3 px-md-4 pt-3 px-md-4 d-flex" :class="{'justify-content-between': !(favourite && !owner), 'justify-content-end': favourite && !owner}">
          <div v-if="owner" class="owner-info d-flex align-items-center pl-0" >
            <div v-if="owner.avatar" class="owner-avatar bg-cover-center" :style="{'background-image': getBackgroundUrl(owner.avatar.url)}"></div>
            <span class="pl-2 font-sm">By {{owner.first_name}} {{owner.last_name}}</span>
          </div>
          <div v-if="favourite" class="favourite text-left d-flex align-items-center justify-content-center" @click.stop.prevent="openFavouriteModal(containerContent, favourite, favourite.interaction)">
            <i class="fa-bookmark" :class="[isFavourite(containerContent, favourite.interaction) ? 'fas text-red' : 'fal']"></i>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-component] {
  color: $white;
  overflow: hidden;
  cursor: pointer;

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  .linear-gradient-y {
    background: linear-gradient(to bottom, rgba($black, 0) 50%, $black);
    opacity: .16;
  }

  .title-composed,
  .title-simple {
    .title-italic {
      font-style: italic;
    }
    .title-uppercase {
      text-transform: uppercase;
    }
    .title-underline {
      text-decoration: underline;
    }
  }

  .is-big-title {
    font-family: $neutra-display-font;
    @include font-size(1.875rem);
    line-height: 1;

    @include media-breakpoint-up(lg) {
      @include font-size(2.25rem);

      .largesquarecontent & {
        @include font-size(2.75rem);
      }
    }
  }

  .is-small-title {
    @include font-size(1.35rem);
    line-height: 1.1;

    @include media-breakpoint-up(lg) {
      @include font-size(1.875rem);
    }
  }

  .favourite {
    i {
      @include font-size(1.75rem);
    }
  }

  .category {
    font-family: $italia-lt-font;
    letter-spacing: 0.96px;
  }

  .owner-info {
    .owner-avatar {
      height: 42px;
      width: 42px;
      border-radius: 50%;
    }
  }

  @include media-breakpoint-down(md) {
    .favourite {
      i {
        @include font-size(1.25rem);
      }
    }
  }

}
</style>
