<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    getContentURL: Fandom.getContentURL
  },
  computed: {
    owner() {
      return get(this.pageModel, ["id_to_user", this.content.owned_by], false);
    },
    imageUrl() {
      return this.getContentResponsiveImageURL('foto').replace('/original/', '/thumb/');
    },
    pizzeria() {
      try {
        return JSON.parse(this.content.pizzeria);
      } catch(_) {
        return {name: "Pizzeria sconosciuta"}
      }
    }
  }
};
</script> 

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="w-100" tile-ugc-box-pizza-component>
    <a :href="getContentURL(content)" class="ugc-content d-flex flex-column deco-none">
      <div class="ugc-img-wrapper position-relative overflow-hidden w-100" :id="`ref-${easyadminId}-link`">
        <div
          class="ugc-img-content rounded bg-cover-center position-absolute-center bg w-100 lazy-bg"
          :lazy-src="imageUrl"
        >
        </div>
      </div>
      <div class="ugc-title-content w-100 py-3">
        <p class="h6 font-weight-bold px-0 mb-2 text-black" v-shave="{ height: 120 }">{{owner.first_name}} {{owner.last_name && owner.last_name[0]}}.</p>
        <p class="text-uppercase small text-red mb-0">
          <i class="fal fa-map-marker-alt mr-2"></i>{{pizzeria.name}}
        </p>
      </div>
    </a>
  </div>
</template>

<style lang="scss">
[tile-ugc-box-pizza-component] {
  $img-padding: 67.5%;
  $avatar-size: 25%;
  $avatar-img-cover-size: 90%;

  .ugc-img-wrapper {
    padding-bottom: $img-padding;
  }

  .ugc-img-content {
    background-color: $light-grey;
  }
}
</style>
