import { forEach } from "lodash";


const observerMap = {};

const lazyLoadAdapter = {
  image(target, targetAtribute) {
    target.src = target.getAttribute(targetAtribute);
  },
  backgroundImage(target, targetAtribute) {
    target.style.backgroundImage = target.getAttribute(targetAtribute);
  },
  video(target, targetAtribute) {
    forEach(target.children, child =>  {
      child.src = child.getAttribute(targetAtribute);
    });
    target.load();
    target.play();
  }
};

const isInView = (item, observer, callback) => {
  if (item.intersectionRatio > 0) {
    callback(item.target);
    observer.unobserve(item.target);
  }
};

const lazyLoad = (targetClass, targetAtribute, type, loadedClass, loadingAnimationTimeout = 800) => {
  let targets = $(`.${targetClass}`);

  const loadImage = (target) => {
    lazyLoadAdapter[type](target, targetAtribute);
    $(target).addClass(loadedClass);
    setTimeout(() => {
      $(target).removeClass(targetClass)
    }, loadingAnimationTimeout);
  };

  if ("IntersectionObserver" in window) {
    const callback = (entries, observer) => { 
      forEach(entries, entry => isInView(entry, observer, loadImage));
    };

    if (Fandom.exists(observerMap[type])) {
      forEach(targets, elem => observerMap[type].unobserve(elem));
    } else {
      const options = { rootMargin: "25px", threshold: 0.25 };
      observerMap[type] = new IntersectionObserver(callback, options);
    }

    forEach(targets, elem => observerMap[type].observe(elem));
  } else {
    // ie management
    forEach(targets.length, loadImage);
  }
};

const lazyLoadImages = (targetClass = "lazy-img", targetAtribute = "lazy-src", loadedClass = "lazy-img-loaded") => {
  lazyLoad(targetClass, targetAtribute, "image", loadedClass);
};

const resetLazyLoadImages = (targetClass = "lazy-img", targetAtribute = "lazy-src", loadedClass = "lazy-img-loaded") => {
  $(`.${loadedClass}`).removeClass(`.${loadedClass}`).addClass(targetClass);
  lazyLoadImages();
};

const lazyLoadBackgrounds = (targetDom = document, targetClass = "lazy-bg", targetAtribute = "lazy-src", loadedClass = "lazy-bg-loaded") => {
  lazyLoad(targetClass, targetAtribute, "backgroundImage", loadedClass);
};

const resetLazyLoadBackgrounds = (targetClass = "lazy-bg", targetAtribute = "lazy-src", loadedClass = "lazy-bg-loaded") => {
  $(`.${loadedClass}`).removeClass(`.${loadedClass}`).addClass(targetClass);
  lazyLoadBackgrounds();
};

const lazyLoadVideos = (targetDom = document, targetClass = "lazy-video", targetAtribute = "lazy-src", loadedClass = "lazy-video-loaded") => {
  lazyLoad(targetClass, targetAtribute, "video", loadedClass);
};

const resetLazyLoadVideos = (targetClass = "lazy-video", targetAtribute = "lazy-src", loadedClass = "lazy-video-loaded") => {
  $(`.${loadedClass}`).removeClass(`.${loadedClass}`).addClass(targetClass);
  lazyLoadVideos();
};

const lazyLoadingdMixin = {
  mounted() {
    Vue.nextTick(() => {
      this.lazyLoadItems();
    });
    Fandom.onEvent("dom-items-loaded", () => { this.lazyLoadItems() });
  },
  watch: {
    "globalState.viewport.xs"(newValue, oldValue) {
      if (Fandom.exists(oldValue)) {
        resetLazyLoadImages();
        resetLazyLoadBackgrounds();
        resetLazyLoadVideos();
      }
    }
  },
  methods: {
    lazyLoadItems() {
      lazyLoadImages();
      lazyLoadVideos();
      lazyLoadBackgrounds();
    }
  }
}

export {
  lazyLoad,
  lazyLoadImages,
  lazyLoadVideos,
  lazyLoadBackgrounds,
  lazyLoadingdMixin
};
