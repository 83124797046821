<script>
// added for deployment translation
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  computed: {
    componentStyle() {
      return {
        "background-color": this.content.background_color,
        "background-image": Fandom.exists(this.content.background_image) ? Fandom.getBackgroundUrl(this.content.background_image) : false
      };
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" class="bg-cover-center" :class="contentType" v-if="isContentVisibleToUser()" :style="componentStyle" article-list>
   <div class="container px-3 px-sm-0 py-4">
     <div v-if="content.title" class="row justify-content-center">
        <div class="col-12 col-md-10 pb-4">
          <h5>{{content.title}}</h5>
        </div>
      </div>
      <div class="row justify-content-center">
        <div v-for="(entry, i) in content.list" class="col-12 col-md-10 pb-4 pb-sm-4" :key="i">
          <div class="row">
            <div class="col-12 col-sm-auto">
              <div class="title h5 text-uppercase text-red d-flex">#{{i+1}} {{entry.title}}</div>
            </div>
          </div>
          <div v-if="entry.image" class="row">
            <div class="col-12 py-3 py-sm-4 rounded">
              <img :src="entry.image.url" class= "img-fluid">
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-11 col-md-10 pt-3 pb-5">
              <div class="description" v-html="entry.description"></div>
            </div>
          </div> 
        </div>
      </div>
   </div>
 </div>
</template>

<style lang="scss" scoped>
.description {
  @include font-size(1.125rem);

  @include media-breakpoint-up(md) {
    @include font-size(1.375rem);
  }
}
</style>

