<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin]
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" page-influencer-component>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <breadcrumb class="px-0" />
        </div>
        <div class="col-12 col-md-6">
          <tile-influencer :page-mode="true" :content="content"></tile-influencer>
        </div>
        <template v-if="content.description">
          <div class="w-100"></div>
          <div class="col-12 col-md-8">
            <div v-html="content.description"></div>
          </div>
        </template>
      </div>
    </div>
    <component
      v-for="stripe in content.stripes"
      :is="stripe.layout"
      :content="stripe"
      :key="stripe.$id"
      :owned-by="content.owned_by"
      :opt-container-content="containerContent"
      >
    </component>
  </div>
</template>
