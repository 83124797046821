<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { youtubeBasicMixin } from "src/modules/youtube_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";

export default {
  mixins: [fandomBasicMixin, youtubeBasicMixin, interactionMixin],
  data: function() {
    return {
      youtubeConfig: this.content.play.videoConfig || { 
        controls: 1,
        autoplay: 0 
      }
    };
  },
  watch: {
    videoPlayer: {
      handler(newValue, previousValue) {
        this.$emit("video-player-handler", newValue);
      },
      deep: true
    },
    overvideoInteraction(newValue, previousValue) {
      this.$emit("overvideo-interaction-handler", newValue);
    }
  },
  methods: {
    unsetOvervideoInteraction() {
      this.unsetOvervideoInteractionByUserInteraction();
    }
  }
};
</script>

<template>
	<div embed-youtube-component>
		<div :id="content.youtube_id"></div>
	</div>
</template>

<style lang="scss" scoped>
</style>