<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { find, get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  props: {
    ownedBy: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      resultContent: {}
    };
  },
  computed: {
    resultContentChildrenIds() {
      const children = get(this, ["resultContent", "children"], []);
      return children.map(this.getContent).map(content => content.id);
    },
    getContentsParams() {
      return {
        content_filtering: [this.content.name],
        include_children: true,
        include_content_children: true,
        layout_extra_field_name: "tile_layout",
        layout: get(this, ["content", "children_tile_layout"], "tile"),
        include_user_ids: this.ownedBy > -1 ? [this.ownedBy] : [],
        exclude_content_ids: this.resultContentChildrenIds
      };
    }
  },
  methods: {
    // force deploy
    setResultContent(data) {
      const newResultContent = find(data.name_to_content, content => content.id < 0);
      if (Fandom.exists(this.resultContent.children)) {
        newResultContent.children.forEach(name => this.resultContent.children.push(name));
        this.resultContent.has_more_children = newResultContent.has_more_children;
      } else {
        Vue.set(this, "resultContent", newResultContent);
      }
      Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
    },
    alternativeAppendContents() {
      Fandom.getSearchableContents(this.setResultContent, this.getContentsParams);
    }
  },
  mounted() {
    this.alternativeAppendContents();
  }
};
</script>

<template>
  <grid-fixed
    class="pb-3 pb-md-4"
    :content="resultContent"
    :key="resultContent.name"
    :alternative-append-contents="alternativeAppendContents"
    v-easyadmin="easyadminId"
    v-if="isContentVisibleToUser()"
    :id="`ref-${easyadminId}`"
    >
  </grid-fixed>
</template>
