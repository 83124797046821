<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      userInfo: {},
      loading: false,
      errors: [],
      success: false,
      formSubmitted: false
    };
  },
  beforeCreate() {
    if (Fandom.isUserRegistered()) {
      window.location.href = Fandom.applyContextToUrl("/");
    }
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    submit() {
      this.loading = true;
      this.success = false;
      this.errors = [];

      if (this.userInfo.confirmPassword !== this.userInfo.password) {
        this.formSubmitted = true;
        this.errors.push(this.ft("globals.form.password_mismatch"));
        this.loading = false;
      } else {
        Fandom.ajax({
          method: "POST",
          data: { 
            password: this.userInfo.password,
            password_confirmation: this.userInfo.confirmPassword,
            hash: this.hash
          },
          url: "/api/v5/token_password_update",
          success: (data) => {
            this.formSubmitted = true;
            this.success = true;
            this.loading = false;
            window.location.href = '/users/sign_in';
          },
          error: (err) => {
            this.formSubmitted = true;
            this.errors = err.responseJSON.errors;
            this.success = false;
            this.loading = false;
          }
        });
      }
    }
  },
  computed: {
    submitTextFormatted() {
      return this.loading ? this.ft("globals.form.loading") : this.ft("globals.form.send_password");
    },
    hash() {
      const params = new URLSearchParams(window.location.search);
      return params.get('hash');
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" page-influencer-component>
    <div class="container">
      <div class="row py-4 mh-100vh-header justify-content-center align-items-center">
        <div class="col-12 col-md-8 col-lg-6">
          <component :is="content.title_tag || 'h2'" :class="content.title_class">{{ content.title }}</component>
          <component :is="content.description_tag || 'h4'" :class="content.description_class" v-html="content.description"></component>
          <form class="d-flex flex-column" user-password-editing-form-component v-on:submit.prevent="submit($event)">
            <div class="form-group">
              <label>{{ft("globals.form.new_password")}} *</label>
              <input v-model="userInfo.password" class="form-control" type="password" pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" required>
            </div>
            <div class="form-group">
              <label>{{ft("globals.form.password_confirmation")}} *</label>
              <input v-model="userInfo.confirmPassword" class="form-control" type="password" pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" required>
            </div>
            <div class="col-12 col-md-8 px-0 pt-3">
              <small class="form-text mt-2">{{ft("globals.form.required_fields")}}</small>
              <small
                class="d-block form-group password-edit-instructions mt-3"
                v-if="content.password_edit_instructions"
                v-html="content.password_edit_instructions"
              ></small>
            </div>
            <button type="submit" class="col-12 col-sm-6 col-md-5 ml-auto px-0 py-4 mb-4 btn btn-primary" :class="{'disabled': loading}">{{submitTextFormatted}}</button>
            <form-feedback v-if="formSubmitted" :success-message="ft('globals.user_edit_password.success')" :success="success" :errors="errors"></form-feedback> 
          </form>
        </div>
      </div>
    </div>
  </div>
</template>