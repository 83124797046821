<script>
import { get, throttle } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (this.properties.length > 0) {
        this.mountCarousel();
      }

      this.toggleNavbarAffix(); 
      window.addEventListener("scroll", throttle(this.toggleNavbarAffix, 200));
    });
  },
  methods: {
    mountCarousel() {
      new Flickity(this.$refs["flickity-carousel"], {
        draggable: ">1",
        prevNextButtons: (this.properties.length > 1),
        pageDots: (this.properties.length > 1),
        wrapAround: true,
        initialIndex: this.getInitialIndex()
      });
    },
    toggleNavbarAffix() {
      globalState.navbarAffix = this.$el.offsetHeight < $(window).scrollTop();
    },
    isItemActive(item) {
      return globalState.pageModel.main_content_name == item.name;
    },
    getInitialIndex() {
      let index = -1;
      let property;
      do {
        property = this.properties[++index]
      }
      while (property.name != this.propertyInfo.name);
      return index;
    }
  },
  computed: { 
    logo() {
      return get(globalState, "pageModel.context.filtering_content_map.property.logo", this.content.logo) || {}; 
    },
    properties() {
      return this.content.properties || []; 
    }
  },
};
</script>

<template>
  <div v-if="properties.length > 0" class="border-bottom py-2 d-none d-lg-block" properties-component>
    <div class="container">
      <div class="row align-items-center justify-content-around">
        <div class="col-auto">
          <a :href="applyContextToUrl('/')">
            <div class="header-around">
              <img class="header-logo" :src="expandUrl(logo.url)">
            </div>
          </a>
        </div>
        <div class="col-12 col-sm-4 px-0">
          <div class="flickity-carousel" ref="flickity-carousel"">
            <div class="flickity-carousel-item mr-2" v-for="item in properties">
              <div class="w-100 ar-16by9">
                <property :content="item" :opt-container-content="content"></property>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto border-left">
          <div class="header-around">
            <navbar-user :content="content" :opt-container-content="content"></navbar-user>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[properties-component] {
  background-color: $user-dashboard-bg-color;
  
  .header-around {
    width: 160px;

    .header-logo {
      height: 80px;
    }
  }

  .flickity-page-dots {
    display: none;
  }

  .flickity-prev-next-button {
    background-color: transparent;

    &.previous {
      left: -50px;
    }

    &.next {
      right: -50px;
    }
  }

  .flickity-carousel-item {
    width: 50%;
  }
}
</style>

