<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mounted() {
    this.content.accordion.forEach((item) => {
      Vue.set(item, "collapsed", true);
    });
  },
  mixins: [fandomBasicMixin],
  methods: {
    toggleItem(index) {
      Vue.set(this.content.accordion[index], "collapsed", !this.content.accordion[index]["collapsed"]);
    }
  },
  computed: {
    parent() {
      return `#accordion-${this.content.name}`;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser" :id="`accordion-${content.name}`" :class="contentType" class="pb-4" stripe-accordion-component>
    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col-12">
          <h4 class="mb-5" v-if="content.title">{{content.title}}</h4>
        </div>
        <div class="col-12 col-md-10">
          <div v-for="(item, index) in content.accordion" :key="index">
            <a :href="`#item-${index}`" class="deco-none w-100 collapsed" data-toggle="collapse" @click="toggleItem(index)">
              <div class="d-flex pb-3 align-items-center justify-content-between border-bottom border-light-grey">
                <!-- <span class="h5 text-red text-uppercase">{{item.title}}</span> -->
                <span class="font-weight-bold">{{item.title}}</span>
                <i class="fal icon text-red fa-plus mr-3 font-lg"></i>
              </div>
            </a>
            <div class="w-100 pb-2"></div>
            <div class="collapse description" :id="`item-${index}`" :data-parent="parent" v-html="item.description"></div>
            <div class="w-100 pb-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon {
  transform: rotate(-45deg);
  transition: transform .125s ease-in-out;

  .collapsed & {
    transform: rotate(0);
  }
}

.description {
  @include font-size($font-size-base * .95);

 ::v-deep p {
    margin-bottom: 0;
  }
}
</style>
