<script>
import { get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { CinqueStagioni } from "../../modules/cinque_stagioni_module.js";

export default {
  mixins: [fandomBasicMixin],
  mounted() {
    Vue.nextTick(() => {
      for (let i = 0; i < this.content.menu.length; i++) {
        const selector = `#navbar-item-${i}`;
        CinqueStagioni.trackLinkVisited(selector, "Interaction", "Menu Click", $(selector).html());
      }
    });
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    getTarget: Fandom.getTarget,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getActiveColor(item) {
      return {
        "--active-color": item.color ? `var(--${item.color})` : "var(--red)",
        "--active-background-color": item.color ? `var(--${item.background_color})` : "transparent"
      };
    },
    rightBoxItems(menuItem) {
      let  items = [];
      for (let i = 0; i < menuItem.menu.length/2; i++) {
        items[i] = [];
      }
      menuItem.menu.forEach((section, index) => {
        const pos = Math.floor(index/2);
        items[pos].push(section);
      });
      return items;
    },
    linkUrl(item) {
      const url = get(item, ["url", "url"], false);
      return !url || url == "#" ? "javascript:void(0)" : url;
    },
    isItemActive(item) {
      const mainContent = this.getContent(globalState.pageModel.main_content_name);
      const re = new RegExp(`^${item.url.url}`);
      return re.test(mainContent.slug);
    },
  },
};
</script>

<template>
  <div class="d-flex justify-content-center align-items-center position-relative" navbar-component>
    <div
      v-for="(item, index) in content.menu"
      :style="getActiveColor(item)"
      :class="{'active': isItemActive(item)}"
      :key="index"
      >
      <div class="font-weight-bold menu-item d-flex justify-content-center align-items-center cursor-pointer position-relative px-4">
        <a :href="linkUrl(item)" :target="getTarget(item.url)" :title="item.url.title" class="link-black hover-active-link" :id="`navbar-item-${index}`">
          <span v-for="(word, wIndex) in (item.url.title || '').split(' ')" :class="{'text-uppercase': wIndex > 0, 'text-lowercase': wIndex === 0}" :key="`${index}-${wIndex}-word`">{{word}} </span>
        </a>
        <div class="sub-menu position-absolute d-none mx-0" v-if="item.menu">
          <div class="row mx-0">
            <div class="col-8 p-0" :class="item.right_launch_box ? 'col-8' : 'col-12'">
              <div class="m-2 p-3" :class="{'border-right': item.right_launch_box}">
                <div v-for="(subItem, subItemIndex) in item.menu" :key="`${index}-${subItemIndex}`">
                  <a :href="subItem.url.url" :target="getTarget(subItem.url)" class="sub-item link-black deco-none">{{subItem.url.title}}</a>
                </div>
              </div>
            </div>
            <!-- <div class="col-4 pr-3 pl-0 py-3 d-flex flex-column" v-if="item.right_launch_box">
              <div class="section font-sm my-3" v-if="item.right_launch_box.title" :class="`text-${item.color ? item.color : 'red'}`">
                {{item.right_launch_box.title}}
              </div>
              <div class="url-lg text-capitalize" v-if="item.right_launch_box.url_1">
                <a class="font-xl link-black" :href="item.right_launch_box.url_1.url" :target="getTarget(item.right_launch_box.url_1)">
                  {{item.right_launch_box.url_1.title}}
                </a>
              </div>
              <div class="url-lg text-capitalize" v-if="item.right_launch_box.url_2">
                <a class="font-xl link-black" :href="item.right_launch_box.url_2.url" :target="getTarget(item.right_launch_box.url_2)">
                  {{item.right_launch_box.url_2.title}}
                </a>
              </div>
              <div 
                class="bg-cover-center w-100 flex-grow-1 mt-3" 
                v-if="item.right_launch_box.image" 
                :style="{ 'background-image': getBackgroundUrl(item.right_launch_box.image.url) }"
              ></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

[navbar-component] { 
  height: $navbar-height;

  .menu-item {
    z-index: $zindex-menu-item;
    transition: all $animation-time-fast;
    height: $top-level-item-height;
    @include font-size(1.25rem);
    padding-top: 4px;

    .hover-active-link {
      text-decoration: none;
      color: var(--active-color) !important;
    }
    background-color: var(--active-background-color);

    &:hover .sub-menu {
      display: block !important;
    }
  }

  .sub-menu {
    top: $top-level-item-height;
    background-color: $white;
    cursor: default;
    color: $dark;
    z-index: 1;
    left: 0;

    a.sub-item {
      white-space: pre;
      font-size: 1.125rem;
    }

    .url-lg {
      font-family: $italia-lt-font; // TODO: move into font variable;
    }

    .img-box > img {
      width: 100%;
      height: auto;
    }
  }
}
</style>

