<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { initMap, loadMap, createInfoWindow } from 'src/modules/google_maps_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      map: null,
      infoWindow: {},
      place: {},
      relatedsContentName: null
    }
  },
  mounted() {
    this.loadRelated();
    initMap(() => {
      Vue.nextTick(() => {
        if (this.$refs["google-map"]) {
          const map = loadMap(this.$refs["google-map"], { zoom: 15 });
          const service = new google.maps.places.PlacesService(map);
          this.infoWindow = createInfoWindow(this.$refs["info-content"]);
          service.textSearch({ query: `${this.pizzeria.name} ${this.pizzeria.address}` }, (data) => {
            const pizzeria = data[0];
            console.log(data[0]);
            service.getDetails(
              { placeId: pizzeria.place_id },
              (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  Vue.set(this, 'place', place);
                }
              }
            );
            map.setCenter(pizzeria.geometry.location);
            const marker = new google.maps.Marker({
              position: pizzeria.geometry.location,
              animation: google.maps.Animation.DROP
            });
            marker.setMap(map);
            this.infoWindow.open(map, marker);
            google.maps.event.addListener(marker, "click", () => {
              this.infoWindow.open(map, marker);
            });
          });
          Vue.set(this, "map", map);
        }
      });
    }, true);
  },
  computed: {
    owner() {
      return get(this.pageModel, ["id_to_user", this.content.owned_by], false);
    },
    website() {
      const hostnameRegexp = new RegExp("^https?://.+?/");
      return String(hostnameRegexp.exec(this.place.website));
    },
    rating() {
      let ratingHtml = "";

      for (let i = 0; i < 5; i++) {
        ratingHtml += (this.place.rating < i + 0.5) ? "&#10025;" : "&#10029;";
      }
      return ratingHtml;
    },
    pizzeria() {
      try {
        return JSON.parse(this.content.pizzeria);
      } catch(_) {
        return {name: "Pizzeria sconosciuta"}
      }
    },
    relatedsContent() {
      return this.pageModel.name_to_content[this.relatedsContentName];
    }
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    loadRelated() {
      const gridName = this.content.parent_names.find(p => p.includes('griglia') || p.includes('grid'));
      Fandom.getContents(gridName, {limit: 4, exclude_content_ids: [this.content.id]}, (data) => {
        Vue.set(this, 'relatedsContentName', data.main_content_name);
        Vue.set(this.relatedsContent, 'children', this.relatedsContent.children.slice(0,4));
        Vue.set(this.relatedsContent, 'has_more_children', false);
      });
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" page-ugc-box-pizza-component>
    <div class="container py-4 py-md-5 px-4">
      <h3 class="h2 font-weight-bold px-0 mb-2 text-black text-center">
        <span class="text-uppercase text-primary">La pizza di</span>
        <span class="font-family-italia-lt-book pl-2">{{owner.first_name}} {{owner.last_name && owner.last_name[0]}}.</span>
      </h3>
      <p class="text-uppercase text-center small text-red mb-4 mb-md-5 mt-2">
        <i class="fal fa-map-marker-alt mr-2"></i>{{pizzeria.name}}
      </p>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="ar-16by9 w-100 bg-contain-center mb-2 bg-light-grey" :style="{'background-image': getContentResponsiveImageURL('foto')}"/>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-100 ar-16by9 position-relative">
            <div ref="google-map" class="position-absolute-center"></div>
            <div class="d-none">
              <div ref="info-content">
                <table>
                  <tr class="table-row">
                    <td class="text-right">
                      <img class="place-icon" :src="place.icon"/>
                    </td>
                    <td>
                      <b>
                        <a :href="place.url">{{pizzeria.name}}</a>
                      </b>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="font-weight-bold text-right">{{ft("maps.address")}}:</td>
                    <td>{{place.vicinity}}</td>
                  </tr>
                  <tr class="table-row" v-if="place.formatted_phone_number">
                    <td class="font-weight-bold text-right">{{ft("maps.telephone")}}:</td>
                    <td>{{place.formatted_phone_number}}</td>
                  </tr>
                  <tr class="table-row" v-if="place.rating">
                    <td class="font-weight-bold text-right">{{ft("maps.rating")}}:</td>
                    <td v-html="rating"></td>
                  </tr>
                  <tr class="table-row" v-if="place.website">
                    <td class="font-weight-bold text-right">{{ft("maps.website")}}:</td>
                    <td>
                      <a :href="website">{{website}}</a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 class="h4 text-center text-primary font-family-neutra-display my-4 my-md-5">
      Scopri le pizze degli altri utenti
    </h3>
    <component class="mb-4 mb-md-5" :hide-load-more="true" :content="relatedsContent" v-if="relatedsContent && relatedsContent.children.length > 0" :is="relatedsContent.layout"></component>
  </div>
</template>

<style lang="scss" scoped>
.ar-16by9 {
  @include media-breakpoint-down(sm) {
    padding-bottom: 100%;
  }
}

.table-row {
  height: 1.125rem;
}

.place-icon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
}

.font-family-italia-lt-book {
  font-family: "Italia LT Book", Georgia, Times, serif;
}

.font-family-neutra-display {
  font-family: "Neutra Display", Helvetica, Arial, sans-serif;
}
</style>