<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  props: ["propBookmark"],
  data() { 
    return {
      bookmark: this.propBookmark,
      unitNumber: 1
    } 
  },
  methods: {
    setBookmark(chapter, index) {
      if (chapter.$id != this.bookmark.$id) {
        this.bookmark = chapter;
        this.unitNumber = index + 1;
        this.$emit('set-bookmark', { chapter: chapter, unitNumber: this.unitNumber });
      }
    },
    isChapterActive(chapter) {
      return  this.bookmark.$id == chapter.$id;
    }
  }
};
</script>

<template>
  <div id="accordion" class="h-100 bg-super-dark-gray" course-sidebar-component>
    <div class="course-sidebar__header p-4 d-flex justify-content-end" v-if="!(globalState.viewport.md || globalState.viewport.lg || globalState.viewport.xl)">
      <a href="javascript: void(0)" @click="$emit('toggle-sidebar')" class="course-sidebar__close">
        <i class="far fa-times"></i>
      </a>
    </div>
    <div class="card bg-super-dark-gray px-3" v-for="(chapter, index) in content.chapters" :key="`chapter-${index}`">
      <div class="card-header bg-super-dark-gray">
        <h5 class="mb-0">
          <button
            :aria-expanded="(index + 1 == unitNumber)"
            data-toggle="collapse"
            :aria-controls="`collapse${chapter.$id}`"
            :data-target="`#course-chapter-${chapter.$id}`"
            :class="{'collapsed': index + 1 != unitNumber}"
            class="btn btn-link w-100 course-index__item font-weight-bold d-flex justify-content-between px-0 py-2 rounded-0 align-items-center"
          >
            <div class="text-left pr-2">{{chapter.title}}</div>
            <small><i class="far fa-chevron-up"></i></small>
          </button>
        </h5>
      </div>
      <div :id="`course-chapter-${chapter.$id}`" class="collapse" :class="{'show': (index + 1 == unitNumber)}" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body p-0">
          <ul v-if="chapter.chapters" class="list-unstyled">
            <li v-for="(subchapter, subindex) in chapter.chapters" class="p-3 d-flex" :key="`subchapter-${subindex}`" :class="{'course-index__item__active': isChapterActive(subchapter)}">
              <div class="course-sidebar__item-icon pr-3">
                <i class="text-primary far fa-circle"></i>
              </div>
              <a href="javascript: void(0)" class="course-index__item secondary" @click="setBookmark(subchapter, index)">{{subchapter.title}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[course-sidebar-component] {

  .course-sidebar__header {
    color: $white;

    h3 {
      @include font-size($font-size-base);
    }

    .course-sidebar__close {
      color: $white;
    }
  }

  .course-sidebar__item-icon {
    i { color: $white; }
  }

  .course-index__item__active {
    background-color: $white !important;
    a { color: $black !important; }
  }

  .course-index__item {
    text-transform: none;
    color: $white;
    font-weight: bold;
    @include font-size($font-size-base);
    
    .far.fa-chevron-up {
      transition: transform .25s;
    }

    &.collapsed .far.fa-chevron-up {
      transform: rotate(180deg);
    }

    &.secondary {
      font-weight: normal;
    }
  }

  .card {
    border-radius: 0;
    border-right: none;
    border-color: $dark;
  }

  .card-header {
    border-bottom: none;
    border-radius: 0 !important;
  }

  .card:not(:first-child) {
    border-top: none;
  }

}
</style>