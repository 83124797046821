<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { CinqueStagioni } from "../../modules/cinque_stagioni_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    trackTileClick: Array
  },
  mounted() {
    // google analytics
    if (!!this.trackTileClick){
      Vue.nextTick(() => {
        CinqueStagioni.trackLinkVisited(`#ref-${this.easyadminId}-link`, this.trackTileClick[0], this.trackTileClick[1], this.content.name);
      });
    }
  },
  methods: {
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getContentURL: Fandom.getContentURL
  },
  computed: {
    tileUrl() {
      return this.content.url ? this.content.url.url : this.getContentURL(this.content);
    },
    thumbnailUrl() {
      return this.content.thumbnail ? this.getBackgroundUrl(this.content.thumbnail.url) : "";
    }
  }
};
</script> 

<template>
  <div v-easyadmin="easyadminId" :class="contentType" tile-article>
    <a :href="tileUrl" :target="getTarget(content.url)" class="d-flex flex-column tile-url" :id="`ref-${this.easyadminId}-link`">
      <div class="w-100 tile-thumbnail bg-transition position-relative">
        <div class="w-100 h-100 bg-cover-center bg position-absolute-center rounded" :style="{'background-image': thumbnailUrl}"></div>
      </div>
      <div class="w-100 tile-footer py-3">
        <div class="flex-column d-inline-flex justify-content-center mb-4" v-if="decorator">
          <div v-if="decorator.title" class="category font-xs text-red border border-red py-2 px-3 rounded">{{decorator.title}}</div>
        </div>
        <span class="d-block h5 text-black font-xl" v-shave="{ height: 80 }">{{content.title}}</span>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-article] {

  .tile-url {
    @include hover {
      text-decoration: none;
    }
  }

  .tile-thumbnail {
    padding-bottom: 65%;
  }

  .category {
    line-height: 1;
  }
}
</style>
