import { get, findIndex, debounce } from "lodash";
import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";
import Vue from "vue";

const cinqueStagioniBasicMixin = {
  computed: {
    propertyInfo() {
      return get(globalState, ["pageModel", "context", "content_map", "property"], false);
    },
    decoratorColor() {
      return get(this, ["decorator", "color"], "red");
    },
    color() {
      return get(this, ["content", "category_color"], this.decoratorColor);
    },
    outsidePadding() {
      if (this.content.outside_padding) {
        return `py-${this.content.outside_padding}`;
      } else {
        return "py-0";
      }
    }
  },
  methods: {
    getTitleStyle(partial) {
      const partialColor = partial.color !== true ? `text-${partial.color}` : `text-${this.color}`;
      return [
        partial.class ? `title-${partial.class}` : false,
        Fandom.exists(partial.color) ? partialColor : false
      ];
    }
  }
};

const cinqueStagioniAuthorizationMixin = {
  computed: {
    relatedContent() {
      return get(this, ["pageMode", "name_to_content", this.content.sealable_content], false);
    },
    sealedContent() {
      return this.relatedContent ? this.relatedContent : this.content;
    },
    authorization() {
      return get(this.pageModel, ["name_to_content_authorization", this.sealedContent.name], false);
    },
    authorized() {
      return get(this, ["authorization", "authorized"], true);
    },
    authType() {
      return get(this, ["authorization", "type"], false);
    },
  },
  methods: {
    mayRedirectOnFreeContent(redirectWhenUnlocked = false) {
      sendFandomAnalyticsEvent("Content Interaction", "Obtain Button Click", this.containerContent.name);
      if (this.authorized || (Fandom.isUserRegistered() && this.authType === "user_contents")) {
        sendFandomAnalyticsEvent("Content Interaction", "Content Obtained", this.containerContent.name);
      } else {
        if (this.authType === "registration" || !Fandom.isUserRegistered()) {
          $("#modal-authentication-required").modal("show");
        } else if (this.authType === "user_group"){
          Fandom.emit("modal-user-group-required", { data: this.sealedContent, redirectWhenUnlocked });
        }
      }
    }
  }
};

const cinqueStagioniRelatedMixin = {
  data() {
    return {
      resultContent: null,
      tag: undefined
    };
  },
  methods: {
    getRelatedContentsByNames(names, layout = "tile", limit = 4) {
      if (Fandom.exists(names)) {
        const getContentsParams = {
          include_rich_info: true,
          include_children: true,
          include_content_children: true,
          exclude_content_ids: [this.content.id],
          layout_extra_field_name: "$empty",
          layout,
          limit,
          contents_names: names
        };
        Fandom.getContentsByNames(this.successCallback, getContentsParams);
      }
    },
    getRelatedContents(tag, layout = "tile", limit = 4) {
      if (tag) {
        this.tag = tag;
        const getContentsParams = {
          include_rich_info: true,
          include_children: true,
          include_content_children: true,
          exclude_content_ids: [this.content.id],
          layout_extra_field_name: layout,
          layout,
          content_filtering: [this.tag],
          include_extra_field_content_filtering: true,
          limit
        };
        Fandom.getSearchableContents(this.successCallback, getContentsParams);
      }
    },
    successCallback(data) {
      this.resultContent = data.name_to_content[data.main_content_name];
      Vue.set(this.resultContent, "has_more_children", false);
      Vue.nextTick(() => Fandom.emit("dom-items-loaded"));
    }
  }
};

const multiLangMixin = {
  methods: {
    redirectTolang(lang) {
      return get(this, ["mainContentSlugs", lang], `/${lang}`);
    }
  },
  computed: {
    langs() {
      return get(globalState, ["pageModel", "langs"], []).filter(lang => lang !== this.currentLang);
    },
    currentLang() {
      return get(globalState, "lang", "it");
    },
    mainContentSlugs() {
      return get(globalState, ["pageModel", "name_to_content", globalState.pageModel.main_content_name, "canonical"], {});
    }
  }
};

const formAutocompleteInputMixin = {
  data() {
    return {
      autocompleteContents: [],
      autocompleteErrors: [],
    }
  },
  methods: {
    autocompleteGetLabel(content) {
      return (content && content.title) || "";
    },
    autocompleteUpdateContents(field, searchText = "") {
      const search_text = field.value || searchText;
      const successBlock = data => {
        console.log(data);
        const items = get(data, "result", []);
        Vue.set(this, "autocompleteErrors", []);
        Vue.set(this, "autocompleteContents", items.map(item => {
          const title = Fandom.exists(item["province_initials"]) ? `${item[field.autocomplete_extra_field]} (${item["province_initials"]})` : item[field.autocomplete_extra_field];
          return {
            title,
            name: item.name,
            simpleFormField: field.name,
            enableThumbnail: false
          }
        }).sort((a, b) => { return a.title > b.title ? 1 : -1 }));
      };
      
      const minLength = field.min_length === undefined ? 3 : field.min_length;
      if (search_text.length >= minLength) {
        const params = {
          autocomplete_tag: field.autocomplete_tag,
          autocomplete_extra_field: field.autocomplete_extra_field,
          search_text: search_text,
          min_length: minLength
        };

        if (!field.country_field_name) params.skip_country_check = true;
        if (!!field.country_field_name) {
          try {
            params["country"] = this.form.data[field.country_field_name];
          } catch {
            params["country"] = $(`#user_${field.country_field_name}`)[0].value;
          }
        }

        Fandom.ajax({
          method: "POST",
          url: Fandom.applyContextToUrl("/api/v5/autocomplete_field_search"),
          data: params,
          success: successBlock,
          error: (err) => {
            Vue.set(this, 'autocompleteErrors', err.responseJSON.errors)
          }
        })
      }
    },
    debounceAutocomplete: debounce(function(field, searchText = "") { 
      this.autocompleteUpdateContents(field, searchText);
    }, 500),
    autocompleteItemClicked(content) {
      const { simpleFormField, title, name } = content;
      if (Fandom.exists(simpleFormField)) {
        Vue.set(this.form.data, simpleFormField, { title, name });
      }
    },
    getAutocompleteIndex(name) {
      return findIndex(this.autocompleteFields, item => item.name === name);
    },
    autocompleteValidateSelection(field, ref = null, propName = "") {
      const fieldValue = !!ref ? ref[propName] : field.value;
      const titles = this.autocompleteContents.map(item => item.title);
      if (!(titles.includes(fieldValue))) {
        if (!!ref) Vue.delete(ref, propName);
        else { field.value = ""; }
      }
    },
    getMLInfo(info) {
      return (typeof info === 'object') ? info[globalState.lang] : info;
    },
  },
  computed: {
     autocompleteFields() {
      return this.formFields.filter(field => field.type === "autocomplete");
    }
  }
}

export {
  cinqueStagioniBasicMixin,
  cinqueStagioniRelatedMixin,
  cinqueStagioniAuthorizationMixin,
  multiLangMixin,
  formAutocompleteInputMixin
};
