<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { cinqueStagioniBasicMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { Youtube } from "src/modules/youtube_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin, interactionMixin],
  mounted() { },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getContentURL: Fandom.getContentURL,
    toggleYoutubePopup: Youtube.toggleYoutubePopup,
    mayToggleYoutubePopup() {
      if (this.isYoutubePresent) {
        this.toggleYoutubePopup();
      }
    }
  },
  computed: {
    isYoutubePresent() {
      const mediaType = get(this.content, ["play", "media_type"], false);
      return mediaType == "youtube";
    },
    play() {
      return get(this.content, ["play"], null);
    },
    decoratorTitle() {
      return get(this.decorator, ["title"], false);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" page-article>
    <breadcrumb/>
    <div class="container px-3 px-sm-0 py-4">
      <div class="row justify-content-center">
        <div v-if="content.title" class="col-12 py-2 col-lg-9 article-description">
          <h1 class="h2 text-red text-center">{{content.title}}</h1>
        </div>
        <div v-if="content.description" class="col-12 col-lg-9 py-2 article-description italia-lt-font" v-html="content.description"></div>
      </div>
    </div>
    <div class="video-content-cover" v-if="isYoutubePresent">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-10 px-0 px-sm-3">
            <div class="overflow-hidden position-relative">
              <div class="ar-16by9">
                <embed-youtube class="position-absolute-center" :content="content" :opt-container-content="containerContent"></embed-youtube>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
   <stripe-related-articles
      :content="content"
      tagExtraField="related_content_article_tag"
      :optionalRelatedContentNames="content.related_content_articles"
    ></stripe-related-articles>
    <stripe-related-recipes
      v-if="!!content.related_content_reciepe_tag || !!content.related_content_reciepes"
      :content="content"
      :tagExtraField="!!content.related_content_reciepe_tag ? 'related_content_reciepe_tag' : 'course'"
      :optionalRelatedContentNames="content.related_content_reciepes"
      :optionalViewMoreLink="(content.related_view_more_link || {}).url"
    ></stripe-related-recipes>
  </div>
</template>

<style lang="scss" scoped>
[page-article] {

  .play-icon {
    @include font-size(4.5rem);
    color: $white;
    text-shadow: 1px 1px 7px $black;
    opacity: 0.9;
    transition: 0.6s all;

    &:hover {
      opacity: 0.6;
    }
  }

  @include media-breakpoint-down(xs) {
    .play-icon {
      @include font-size(3.5rem);
    }
  }
}


</style>
