<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin]
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" class="position-relative" page-blank-component>
    <div v-if="!content.hide_breadcrumb">
      <breadcrumb/>
    </div>
    <component :is="stripe.layout" :content="stripe" :owned-by="content.owned_by" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>
