<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      autocompleteContents: [],
      autocompleteSelectedContent: null,
      userMail: null
    }
  },
  methods: {
    autocompleteGetLabel(content) {
      return (content && content.title) || "";
    },
    autocompleteItemClicked(content) {
      Vue.set(this, "autocompleteSelectedContent", this.autocompleteGetLabel(content));
    },
    autocompleteUpdateContents() {
      const searchText = get(this.$refs, ["v-autocomplete-ref", "searchText"], "");
      if (searchText.length < 3) return;

      const field = this.content.autocomplete_extra_field;
      const tag = this.content.autocomplete_tag;

      const successBlock = data => {
        const items = get(data, "result", []);
        this.autocompleteContents = items.map(item => {
          return {
            title: item[field],
            enableThumbnail: false
          }
        });
      };

      const params = {
        autocomplete_tag: tag,
        autocomplete_extra_field: field,
        model: this.content.autocomplete_model,
        search_text: searchText,
        skip_country_check: true
      };

      Fandom.ajax({
        method: "POST",
        url: Fandom.applyContextToUrl("/api/v5/autocomplete_field_search"),
        data: params,
        success: successBlock
      });
    },
    autocompleteInputChanged() {
      const ref = this.$refs["v-autocomplete-ref"]
      if (ref && ref.searchText.length < 3) {
        this.autocompleteContents = [];
      }
    },
    submit() {
      Fandom.ajax({
        method: "POST",
        url: "/api/v5/distributor/sign_up",
        data: {
          business_name: this.autocompleteSelectedContent.title,
          update_password_url: this.content.update_password_url.url
        },
        success: (data) => {
          console.log(data);
          Vue.set(this, 'userMail', data.email);
          $("#modal-distributor").modal('show');
        }
      });
    },
  },
  computed: {
    modalDescription() {
      return get(this.content, ["modal", "description"], "").replace("%{email}", this.userMail);
    }
  }
};

</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    v-if="isContentVisibleToUser()" 
    :class="[contentType]" 
    class="w-100"
    page-distributor-registration
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6 my-4">
          <div class="my-4">
            <h3 class="mb-4 h5 text-uppercase text-center text-red" v-if="content.title">{{content.title}}</h3>
            <div class="text-center mb-4" v-html="content.description" v-if="content.description"></div>
            <div class="w-100 d-flex justify-content-center flex-wrap">
              <v-autocomplete
                ref="v-autocomplete-ref"
                id="autocomplete"
                class="form-control search-input p-2 d-flex align-items-center" 
                :items="autocompleteContents"
                v-model="autocompleteSelectedContent"
                :get-label="autocompleteGetLabel"
                component-item="stripe-search-autocomplete-item"
                @item-selected="autocompleteItemClicked"
                @update-items="autocompleteUpdateContents"
                @input="autocompleteInputChanged"
                :auto-select-one-item="false"
                >
              </v-autocomplete>
              <button class="btn btn-primary mx-auto mt-5" @click="submit">{{ft("globals.sign_up")}}</button>
            </div>  
          </div>
        </div>  
      </div>
    </div>
    <div modal-thankyou-component modal-components>
      <div id="modal-distributor" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span class="close-icon italia-lt-font" aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
              <div class="row justify-content-center">
                <div class="col-lg-10 pb-4">
                  <h5 class="modal-title text-uppercase text-red">{{content.modal.title}}</h5>
                  <div v-if="content.modal.description" class="user-edit-description font-lg" v-html="modalDescription"></div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[page-distributor-registration] {
  .v-autocomplete-input-group {
    width: 100%;
    height: 100%;

    .v-autocomplete-input {
      border: none;
      box-shadow: none;
      width: 100%;
      height: 100%;
      outline: none !important;
    }
  }

  .v-autocomplete-list {
    top: 4.0625rem;
    left: 0;
    right: 0;
    outline: 1px solid $gray-300;
    z-index: $zindex-dropdown;

    .v-autocomplete-list-item {
      background-color: $white;
    }
  }
}
</style>