<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { sendFandomAnalyticsEvent }  from 'src/modules/fandom_analytics_module.js';
import { generateToken } from 'src/modules/recaptcha_v3_module.js';
import { merge } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      userData: {},
      currentStepIndex: 0,
      currentStep: this.content.stripes[0],
    }
  },
  methods: {
    updateUserData(event) {
      Vue.set(this, 'userData', merge({}, this.userData, event.data));
      sendFandomAnalyticsEvent('registration', 'step', `${this.userData[this.content.user_type_param] || 'undefined'}__${this.currentStepIndex}`);
      if (this.currentStep.last_step) {
        this.submitForm();
      } else {
        this.currentStepIndex += 1;
        this.currentStep = null;
      }
    },
    setCurrentStep() {
      Vue.set(this, 'currentStep', this.steps[this.currentStepIndex]);
      $("html, body").animate({
        scrollTop: 0
      }, 0, "linear");
    },
    submitForm() {
      const func = (token) => {
        if (token)
          $(this.$refs.form).prepend("<input type='hidden' name='recaptcha_token' value=" + token + ">");
        const {password, ...dataToSave} = this.userData;
        localStorage.setItem('userData', JSON.stringify(dataToSave));

        $(this.$refs.form).submit();
      }
      
      if (globalState.pageModel.aux.recaptcha_enterprise) {
        generateToken(func, "signup");
      } else {
        func();
      }
    } 
  },
  computed: {
    progressPercentage() {
      return (this.stepNumber / this.stepsNumber) * 100;
    },
    stepsNumber() {
      return this.steps.filter(s => !s.exclude_from_progress).length;
    },
    stepNumber() {
      return (this.currentStepIndex + 1 - this.steps.filter(s => s.exclude_from_progress).length);
    },
    steps() {
      return this.content.stripes.filter(step => {
        return !(step.hide_to_key && step.hide_to_value && this.userData[step.hide_to_key] === step.hide_to_value)
      });
    },
    authenticityToken() {
      return $("meta[name=\"csrf-token\"]").attr("content");
    }
  }
};
</script>

<template>
  <div class="position-relative" v-easyadmin="easyadminId" :class="contentType" page-pizzaiolo-registration-component>
    <div v-if="!currentStep || !currentStep.exclude_from_progress" class="position-absolute progress-bar bg-primary" :style="{'width': `${progressPercentage}%`}"></div>
    <div v-if="!currentStep || !currentStep.exclude_from_progress" class="row justify-content-center pt-4">
      <div class="col-12 col-lg-6 text-center">
        <small class="font-weight-bold text-medium-grey">
          {{ft('globals.step')}} {{stepNumber}} {{ft('globals.of')}} {{stepsNumber}}
        </small>
      </div>
    </div>
    <div v-if="globalState.errors.length > 0" class="row justify-content-center pt-4 pt-md-5">
      <div class="col-12 col-lg-6">
        <form-feedback :errors="globalState.errors" :success="false"></form-feedback>
      </div>
    </div>
    <transition
      enter-active-class="animated fadeInRight faster"
      leave-active-class="animated fadeOutLeft faster"
      @after-leave="setCurrentStep"
    >
      <component
        v-if="currentStep"
        :is="currentStep.layout"
        :key="currentStepIndex"
        :content="currentStep"
        :init-data="pageModel.user"
        :opt-container-content="containerContent"
        @update="updateUserData"
      ></component>
    </transition>
    <form
      ref="form"
      class="d-none"
      method="post"
      :action="applyContextToUrl('/users')"
      @submit.prevent="submitForm" >
      <input type="hidden" name="authenticity_token" :value="authenticityToken"/>
      <input type="hidden" name="utf8" value="✓"/>
      <input
        v-for="(value, name) in userData"
        :key="`user_${name}`"
        :name="`user[${name}]`"
        :id="`user_${name}`"
        :value="value"
        type="hidden" />
      <button type="submit" id="submit-button" ref="submitButton"></button>
    </form>
  </div>
</template>

<style lang="scss">
[page-pizzaiolo-registration-component] {
  .progress-bar {
    top: 0;
    left: 0;
    height: .3rem;
    transition: width .5s ease-in-out;
  }

  [text-section-component] {
    h2 {
      @include font-size(2.5rem);
      font-family: $italia-lt-font;
    }
  }
}
</style>
