<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  data: function() {
    return {
      agugiaroFignaColors:[
        "red","gold","light-red",
        "light-grey","medium-grey","dark-grey",
        "white","black"
      ]
    };
  },
  mixins: [fandomBasicMixin],
  methods: {},
  computed: {},
};
</script>

<template>
  <div test-layout >
    <div class="container py-4">
      <div class="row">
        <div class="col-12">
          <h1 class="title">Test Layout - Elements</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h1>&lt;H1&gt; Il nuovo mondo Agugiaro e Figna in esclusiva.</h1>
          <h2>&lt;H2&gt; Il nuovo mondo Agugiaro e Figna in esclusiva.</h2>
          <h3>&lt;H3&gt; Il nuovo mondo Agugiaro e Figna in esclusiva.</h3>
          <h4>&lt;H4&gt; Il nuovo mondo Agugiaro e Figna in esclusiva.</h4>
          <h5>&lt;H5&gt; Il nuovo mondo Agugiaro e Figna in esclusiva.</h5>
          <h6>&lt;H6&gt; Il nuovo mondo Agugiaro e Figna in esclusiva.</h6>
          <p>&lt;p&gt; I macarons sono un tipico dolce francese entrato a far parte della pasticceria di alta classe. Alcune fonti attestano la creazione dei primi macarons nel 1791 in un convento vicino Cormery, altre più antiche ritengono che nacquero a Venezia nel secolo XVI e furono portati in Francia da Caterina De Medici.</p>
          <p class="font-sm">(.font-sm) Macarons sono un tipico dolce francese entrato a far parte della pasticceria di alta classe.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2>Colors</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex flex-wrap">
          <div v-for="colorName in agugiaroFignaColors" class="p-2">
            <div class="color-box" :class="[`bg-${colorName}`]"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2>btn-lg</h2>
        </div>
      </div>
      <div class="row">
        <div v-for="colorName in agugiaroFignaColors" class="col-12 col-lg-3 text-center py-2">
          <button class="btn btn-lg" :class="[`btn-${colorName}`]" >LOREM Ipsum</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2>btn</h2>
        </div>
      </div>
      <div class="row">
        <div v-for="colorName in agugiaroFignaColors" class="col-12 col-lg-3 text-center py-2">
          <button class="btn" :class="[`btn-${colorName}`]" >VOTE</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[test-layout] {
  h1, h2, h3, h4, h5, h6, p {
    &:hover {
      background-color: #eee;
    }
  }
  .title {
    color: $red;
  }

  .color-box {
    width: 96px;
    height: 96px;
    border: 1px solid #eee;
  }
}
</style>
