<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      carousel: null,
    }
  },
  mounted() {
    Vue.nextTick(() => {
      let flickityOptions =  {
        draggable: ">1",
        prevNextButtons: false,
        pageDots: true,
        cellAlign: "left",
        wrapAround: false,
        groupCells: true,
      }
      this.carousel = new Flickity(this.$refs["recipe-categories-carousel"], flickityOptions);
      this.carousel.on("resize", () => {
        this.carousel.options.draggable = (this.carousel.slides.length > 1);
        this.carousel.isDraggable = (this.carousel.slides.length > 1);
        this.carousel.reloadCells();
      });
    });
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
  },
  computed: {
  },
};

</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="contentType"
    class="container" 
    v-if="isContentVisibleToUser()" 
    :style="{'background-color': content.background_color}"
    stripe-recipe-categories-component
  >
   <stripe-header :content="content"></stripe-header>

   <div class="recipe-categories-carousel" ref="recipe-categories-carousel">
    <div class="recipe-categories-carousel-item">
      <div class="d-flex flex-column justify-content-begin align-items-center mx-3 px-4">
        <p class="font-xs text-uppercase mb-4">
          {{ft("globals.recipe_categories.search_by_course")}}
        </p>
        <div class="pt-4">
          <a 
            v-for="(item, key) in content.left_menu"
            class="link-black"
            :href="item.url.url" 
            :target="getTarget(item.url)"
            :key="key"
            >
            <h4 class="text-center mb-3">{{item.url.title}}</h4>
          </a>
        </div>
      </div>
    </div>
    <div class="recipe-categories-carousel-item h-100">
      <div class="d-flex flex-column justify-content-begin align-items-center mx-sm-3 px-sm-4 h-100">
        <div class="mx-lg-4 w-100 flex-grow-1 position-relative">
          <div class="bg-cover-center position-absolute-center lazy-bg" :lazy-src="getBackgroundUrl(content.image.url)">       
          </div>
        </div>
      </div>
    </div>
    <div class="recipe-categories-carousel-item">
      <div class="d-flex flex-column justify-content-begin align-items-center mx-3 px-4">
        <p class="font-xs text-uppercase mb-4">
          {{ft("globals.recipe_categories.search_by_category")}}
        </p>
        <div class="pt-4">
          <a 
            v-for="(item, key) in content.right_menu"
            class="link-black"
            :href="item.url.url"
            :target="getTarget(item.url)"
            :key="`item-${key}`"
            >
            <h4 class="text-center mb-3">{{item.url.title}}</h4>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="d-md-none pt-4 mt-3 pb-4 mb-1"></div>

   <div class="py-4 w-100 d-flex justify-content-center align-items-center">
    <a 
      role="button" 
      class="btn py-3 px-5" 
      :class="getPrimaryButtonClass(content.button_class)" 
      :target="getTarget(content.button)" 
      :href="content.button.url"
      v-if="content.button"
    >{{content.button.title}}</a>
  </div>
 </div>
</template>

<style lang="scss">
[stripe-recipe-categories-component] {

  .recipe-categories-carousel-item {
    width: 33.3333%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .flickity-page-dots {
    bottom: -4rem;
  }

  @include media-breakpoint-up(md) {
    .recipe-categories-carousel {

      .flickity-page-dots {
        display: none;
      } 

    }
  }

}
</style>
