<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { CinqueStagioni } from "../../modules/cinque_stagioni_module.js";
import { cinqueStagioniAuthorizationMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin, cinqueStagioniAuthorizationMixin],
  props: {
    trackTileClick: Array
  },
  mounted() {
    // google analytics
    if (!!this.trackTileClick){
      Vue.nextTick(() => {
        CinqueStagioni.trackLinkVisited(`#ref-${this.easyadminId}-link`, this.trackTileClick[0], this.trackTileClick[1], this.content.name);
      });
    }
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl
  },
  computed: {
    owner() {
      return get(this.pageModel, ["id_to_user", this.content.owned_by], false);
    },
    favourite() {
      return get(this.content, ["interactions", "favourite"], false);
    },
    share() {
      return get(this.content, ["interactions", "share"], false);
    },
    like() {
      return get(this.content, ["interactions", "like"], false);
    }
  }
};
</script> 

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="w-100" tile-recipe>
    <div class="recipe-content d-flex flex-column">
      <div class="bg-transition d-block recipe-img-wrapper position-relative overflow-hidden w-100 cursor-pointer" @click.prevent="mayRedirectOnFreeContent(true)" v-if="!authorized">
        <div class="locked-layer w-100 h-100 position-absolute-center d-flex align-items-center justify-content-center">
          <i class="fa fa-lock font-xl text-white"></i>
        </div>
        <div
          class="recipe-img-content rounded cursor-pointer bg-cover-center position-absolute-center bg w-100 lazy-bg"
          :lazy-src="getTileThumbnailUrl('thumbnail')"
          >
        </div>
      </div>
      <a class="bg-transition d-block recipe-img-wrapper position-relative overflow-hidden w-100" :id="`ref-${easyadminId}-link`" :href="applyContextToUrl(content.slug)" v-else>
        <div
          class="recipe-img-content rounded cursor-pointer bg-cover-center position-absolute-center bg w-100 lazy-bg"
          :lazy-src="getTileThumbnailUrl('thumbnail')"
          >
        </div>
      </a>
      <div class="w-100">
        <div class="recipe-info-content pt-3 w-100 d-flex justify-content-between">
          <div class="category-wrapper flex-column d-flex justify-content-center" v-if="decorator">
            <div v-if="decorator.title" class="font-xs text-red border border-red py-2 px-3 rounded">{{decorator.title}}</div>
          </div>
          <div class="interactions-box-wrapper d-flex justify-content-end">
            <div v-if="like || favourite" class="font-lg d-flex align-items-center">
              <div v-if="like" class="interactions-like d-flex align-items-center">
                <span class="font-md">{{getLikeCounter(containerContent, like.interaction, "")}}</span>
                <i
                  class="px-2 icon cursor-pointer fa-heart"
                  :class="[isLikeDone(containerContent, like.interaction) ? 'fas text-red' : 'fal']"
                  @click="updateLikeInteraction(containerContent, like, like.interaction)"
                  >
                </i>
              </div>
              <div v-if="favourite" class="interactions-favourite d-flex">
                <i
                  class="fa-bookmark icon cursor-pointer px-2"
                  :class="[isFavourite(containerContent, favourite.interaction) ? 'fas text-red' : 'fal']"
                  @click="openFavouriteModal(containerContent, favourite, favourite.interaction)"
                  >
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="recipe-title-content w-100">
        <p class="tile-title font-weight-bold px-0 py-3" v-shave="{ height: 120 }">{{content.title}}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[tile-recipe] {
  $img-padding: 67.5%;
  $avatar-size: 25%;
  $avatar-img-cover-size: 90%;

  .locked-layer {
    background-color: rgba($black, .3);
    z-index: 2;
  }

  .category-wrapper {
    line-height: 1;
  }

  .recipe-img-wrapper {
    padding-bottom: $img-padding;
  }

  .interactions-like,
  .interactions-favourite {
    color: $medium-grey;

    .icon {
      @include font-size(1.5rem);

      @include hover {
        color: $red;
      }
    }
  }

  .recipe-img-content {
    background-color: $light-grey;
  }
}
</style>
