<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  props: {
    customShareSlug: {
      type: String,
      default: ""
    },
    leftPadding: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    facebookUrl: Fandom.facebookUrl,
    getButtonClass: Fandom.getButtonClass,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    getSecondaryButtonClass: Fandom.getSecondaryButtonClass,
    copyToClipboard() {
      this.$refs["copy-text-modal"].$emit("copy");
    },
    setAvailablePoints(data) {
      const counter = get(data.user_interaction, ["outcome", "reward_name_to_counter"], false);
      const mainRewardName = Fandom.getMainRewardName();
      this.mainRewardCounter = get(counter, mainRewardName, 0);
    },
    downloadInteractionHandler() {
      const url = get(this, ["downloadRecord", "attachment", "url"], "");
      this.updateDownloadInteraction(this.containerContent, this.downloadRecord, this.downloadRecord.interaction, this.setAvailablePoints, url);
    },
  },
  computed: {
    downloadRecord() {
      return get(this, ["content", "download"], false);
    },
    downloadButtonTitle() {
      return get(this, ["downloadRecord", "title"], this.ft("globals.download"));
    },
    canShareReferral() {
      return this.content.referral_button && this.user && !this.user.anonymous;
    },
    user() {
      return get(globalState, ["pageModel", "user"], false);
    },
    userId() {
      return get(globalState, ["pageModel", "user", "id"], false);
    },
    referralUrl() {
      const text = get(this.content, ["copy_link_text"], "");
      const url = Fandom.applyContextToUrl(`/users/sign_up?distributor_referrer=${this.userId}`);
      return `${text} ${window.location.href.replace(window.location.pathname, url)}`;
    },
    textAlign() {
      // Allowed values: left, right and center.
      return (this.content.text_align) ? `text-${this.content.text_align}` : null;
    },
    hasShareInteraction() {
      return get(this, "content.share.interaction.type", false) === "Share";
    },
    category() {
      return Fandom.exists(this.content.category) ? this.content.category : this.content.miniformat_title;
    },
    signInRequired() {
      return !Fandom.isUserRegistered() && this.content.enable_sign_in;
    },
    buttonsRequired() {
      return (this.content.button || this.content.buttons || this.canShareReferral || this.downloadRecord) && !this.signInRequired;
    },
    titleColor() {
      return this.content.title_color ? this.content.title_color : this.content.text_color;
    },
    titleClasses() {
      const color = this.titleColor ? `text-${this.titleColor}` : "text-red";
      return [color, this.content.title_class];
    },
    mainClasses() {
      return [
        this.textAlign
      ];
    }
  }
};
</script>

<template>
  <div :class="mainClasses" class="my-3" text-section-component>
    <img v-if="content.icon" :style="{width: content.icon_width}" class="icon mt-2 mb-4" :alt="content.icon.alt" :src="content.icon.url">
    <p v-if="category" class="category" :style="{'color': content.text_color}">{{category}}</p>
    <div class="label h4 py-2 px-1 d-inline-block my-4" :style="{'color': `${content.label_color || content.text_color}`}" v-if="content.label">{{content.label}}</div>
    <component v-if="content.title" :class="titleClasses" :is="content.title_tag || 'h2'">
      {{content.title}}
    </component>
    <component v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" :style="{'color': `${content.text_color}`}">{{content.subtitle}}</component>
    <component :is="content.description_tag || 'div'" class="description" :class="{'pl-md-4': leftPadding}" v-if="content.description" :style="{'color': content.text_color}" v-html="content.description"></component>
    <div v-if="buttonsRequired" class="align-btn-group my-4">
      <a 
        v-if="content.button" 
        class="btn shadow-none m-2 my-2" 
        :class="getPrimaryButtonClass(content.button_class, content.theme)" 
        :target="getTarget(content.button)" 
        :href="content.button.url"
      >
        {{content.button.title}}
      </a>
      <template v-if="content.buttons">
        <a 
          class="btn shadow-none m-2" 
          v-for="(buttonRecord, index) in content.buttons" 
          :key="index" 
          :class="getPrimaryButtonClass(buttonRecord.button_class, content.theme)" 
          :target="getTarget(buttonRecord.button)" 
          :href="buttonRecord.button.url"
        >
          {{buttonRecord.button.title}}
        </a>
      </template>
      <template v-if="canShareReferral">
        <button 
          class="btn shadow-none m-2 my-2" 
          :class="getPrimaryButtonClass(content.button_class, content.theme)" 
          @click="copyToClipboard"
          >
          {{ft("globals.referral_button")}}
        </button>
        <copy-text-modal :title="ft('globals.share_referral')" :share-text="referralUrl" ref="copy-text-modal"></copy-text-modal>
      </template>
      <button
        class="btn my-3 download-button"
        :class="getPrimaryButtonClass(downloadRecord.button_class, downloadRecord.theme)"
        @click="downloadInteractionHandler"
        :disabled="!(checkRepeatable(containerContent, downloadRecord, downloadRecord.interaction) || ajaxLoading)"
        v-if="downloadRecord"
        >
        {{downloadButtonTitle}}
      </button>
    </div>
    <div v-if="signInRequired" class="sign-in-section d-flex flex-wrap justify-content-center flex-column">
      <div class="sign-in-button-group-section">
        <a class="btn shadow-none btn-dark mr-md-2 my-2 col-8 col-md-auto" rel="nofollow" :href="applyContextToUrl('/users/sign_up')">
          {{ft("globals.login.sign_up")}}
        </a>
        <a class="btn shadow-none btn-facebook ml-md-2 my-2 col-8 col-md-auto" :href="facebookUrl()">
          {{ft("globals.login.facebook")}}
        </a>
      </div>
      <p v-html="ft('globals.login.sign_in')"></p>
    </div>
    <share-icons 
      class="align-btn-group mb-2 mt-4 m-0" 
      v-if="hasShareInteraction" 
      :content="content" 
      :container-content="containerContent" 
      :custom-share-slug="customShareSlug"
    ></share-icons>
  </div>
</template>

<style lang="scss" scoped>
[text-section-component] {
  .label {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .icon {
    max-width: 100%;
    width: 6rem;
  }
}
</style>
