<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'

export default {
  mixins: [fandomBasicMixin]
};
</script>

<template>
  <div :easyadmin="easyadminId" :class="contentType" class="position-absolute-center bg-transition" property-component>
    <a :href="`/${globalState.lang}/${content.name}`" class="deco-none position-absolute-center">
      <div class="bg-cover-center position-absolute-center" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}">
      </div>
      <div class="position-absolute-center linear-gradient-y"></div>
      <div class="position-absolute-center d-flex align-items-end justify-content-center text-center p-3">
        <h2 class="text-white property-title">{{content.title}}</h2>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[property-component] {
  @include media-breakpoint-down(xs) {
  }
}
</style>

