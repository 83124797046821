<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getContentURL: Fandom.getContentURL,
    emit: Fandom.emit,
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" tile-pizzeria-component>
    <a :href="getContentURL(content)" :target="getTarget(content.url)" class="deco-none">
      <div class="thumbnail bg-cover-center" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
      <h4 class="d-flex align-items-center mt-3">
        {{content.title}}
        <a href="javascript:void(0)" @click="emit('map-pin-selection', content.id)"><i class="ml-2 fas fa-map-marker-alt"></i></a>
        <a :href="`tel:${content.phone}`"><i class="ml-2 fas fa-phone"></i></a>
      </h4>
      <div class="address pb-3 mb-3 w-100">{{content.address}}</div>
      <p v-html="content.description"></p> 
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-pizzeria-component] {
  color: $white;

  .thumbnail {
    padding-bottom: 43.6%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  h4 {
    @include font-size(1.5rem);
    color: $red;
    font-family: $italia-lt-font;
  }

  .address {
    @include font-size(.875rem);
    color: $black;
    line-height: 1.5;
    font-family: $neutra-text-font;
    font-weight: bold;
    border-bottom: 1px solid rgba(200,65,73,0.42);
  }

  p {
    @include font-size(1.125rem);
    color: $black;
  }
}
</style>


