<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      users: [],
      tagNameToContent: {},
      hasMore: false,
      page: 0,
      loading: false
    }
  },
  mounted() {
    this.loadUsers();
  },
  computed: { },
  methods: {
    loadUsers() {
      this.loading = true;
      Fandom.ajax({
        method: "GET",
        url: `/api/v5/users/${this.content.main_tag}?page=${this.page + 1}`,
        success: (data) => {
          Vue.set(this, 'users', [...this.users, ...data.users]);
          this.hasMore = data.has_more;
          this.page += 1;
          this.loading = false;
        }
      })
    }
  }
};
</script>

<template>
  <div 
    :class="contentType" 
    v-easyadmin="easyadminId" 
    v-if="isContentVisibleToUser()"
    list-users-pizzaiolo-del-cambiamento-component
  >
    <div class="container">
      <div class="row row-cols-2 row-cols-md-4 row-cols-lg-5" v-if="users && users.length > 0">
        <div v-for="user in users" :key="`user-${user.id}`" class="d-flex flex-column col mb-4 mb-md5">
          <div class="avatar-container mx-auto mb-3">
            <div class="w-100 ar-1by1 rounded-circle avatar d-flex justify-content-center align-items-center bg-cover-center position-relative" :style="{'background-image': `url(${user.avatar})`}">
              <div class="position-absolute-center d-flex justify-content-center align-items-center">
                <h3 v-if="!user.avatar" class="text-uppercase text-white mb-0">{{user.first_name[0]}}{{user.last_name[0]}}</h3>
              </div>
            </div>
          </div>
          <div class="text-center mt-1 user-text">
            <h6 class="text-uppercase" v-if="user.first_name || user.last_name">{{user.first_name}} {{user.last_name}}</h6>
            <span v-if="!!user.province && user.province != 'not provided'">{{user.province}}</span>
            <span v-else>{{user.country}}</span>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center mt-4">
        <button class="mx-auto btn btn-military-green" :disabled="loading" v-if="hasMore" @click="loadUsers">
          {{ft('globals.load_more')}}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[list-users-pizzaiolo-del-cambiamento-component] {
  h6 {
    font-size: 1.375 rem;
  }

  h3 {
    font-family: $neutra-display-font;
  }

  .avatar-container {
    width: 65%;
  }

  .avatar {
    background-color: $military-green;
  }

  .user-text {
    text-transform: capitalize;
  }
}

</style>
