<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity-bg-lazyload";
import { get } from "lodash";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      carousel: null,
      currentActiveStory: null,
      currentActiveStoryType: null,
      carouselStory: null,
      muted: true
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (Fandom.exists(this.content.children)) {
        Vue.nextTick(() => {
          this.startCarousel();
          this.carousel.on("dragStart", (event) => {
            event.preventDefault();
            $(this.$refs["flickity-carousel"]).find(".flickity-carousel-item").css("pointer-events", "none");
          });

          this.carousel.on("dragEnd", (event) => {
            event.preventDefault();
            $(this.$refs["flickity-carousel"]).find(".flickity-carousel-item").css("pointer-events", "all");
          });
          const url = new URL(window.location.href);
          const storyIndex = url.searchParams.get("story");
          if (Fandom.exists(storyIndex)) {
            const storyContent = this.getContent(this.content.children[storyIndex]);
            this.openStoryModal(storyContent, storyIndex);
          }
        });

        $(window).on("debouncedResize", () => {
          this.carousel.destroy();
          Vue.nextTick(this.startCarousel);
        });
      }
    });
  },

  
  methods: {
    getContentURL: Fandom.getContentURL,
    getTarget: Fandom.getTarget,
    getRelNoFollow: Fandom.getRelNoFollow,
    startCarousel() {
      this.carousel = new Flickity(this.$refs["flickity-carousel"], {
        prevNextButtons: this.enoughChildren,
        pageDots: this.enoughChildren,
        cellAlign: "left",
        bgLazyLoad: this.isViewportSmall ? 1 : 2,
        // groupCells: true,
        draggable: this.enoughChildren,
        wrapAround: true
      });
    },
    getChapterType(chapter) {
      return chapter.video ? "video" : "image";
    },
    stopStoriesVideo() {
      $(".flickity-carousel-story-item video").each((index, video) => {
        video.pause();
        video.currentTime = 0;
      });
    },
    nextStory(index) {
      this.carouselStory.select(index);
    },
    initStoryElement(index) {
      this.stopStoriesVideo();
      let video = $(".flickity-carousel-story-item.is-selected video")[0];
      if(video) {
        video.play();
        $(video).on("ended", (e) => {
          this.nextStory(index + 1);
        });
      }
    },
    openStoryModal(content, index) {
      this.currentActiveStory = content;
      const elem = $("#story-modal");
      let alreadySeenVideoIndexes = new Set();
      disableBodyScroll(elem);
      
      Vue.nextTick(() => {
        if (Fandom.exists(this.currentActiveStory.chapters)) {
          const cmp = this;
          this.carouselStory = new Flickity(this.$refs["flickity-carousel-story"], {
            draggable: ">1",
            prevNextButtons: true,
            pageDots: true,
            cellAlign: "center",
            // wrapAround: true
            on: {
              ready: function() {
                cmp.initStoryElement(0);
                cmp.$el.style.setProperty("--dot-width-mobile", `calc(${100/cmp.currentActiveStory.chapters.length}% - 10px)`);
                if(!alreadySeenVideoIndexes.has(1)) {
                  alreadySeenVideoIndexes.add(1);
                  const eventLabel = `${cmp.currentActiveStory.name}_chapter_1_of_${cmp.currentActiveStory.chapters.length}`;
                  sendFandomAnalyticsEvent("Interaction", "StoriesPlay", eventLabel);
                }
              },
              change: function( index ) {
                $(".flickity-carousel-story-item").addClass("with-transition");
                cmp.initStoryElement(index);
                if(!alreadySeenVideoIndexes.has(index+1)) {
                  alreadySeenVideoIndexes.add(index+1);
                  const eventLabel = `${cmp.currentActiveStory.name}_chapter_${index+1}_of_${cmp.currentActiveStory.chapters.length}`;
                  sendFandomAnalyticsEvent("Interaction", "StoriesPlay", eventLabel);
                }
              }
            },
          });
        }
      });
    },
    closeStoryModal(content, index) {
      this.currentActiveStory = null;
      this.muted = true;
      clearAllBodyScrollLocks();
    },
    toggleMute() {
      this.muted = !this.muted;
      Array.from(document.getElementsByClassName('story-modal-video')).forEach(video => {video.muted = this.muted})
    },
    getStoryType: function(content) {
      if(content.video) {
        return "video";
      } else {
        return "image";
      }
    },
    getChapterBackgroundImage(chapter) {
      return Fandom.getBackgroundUrl(chapter.image.url);
    },
    getImageExtraFieldName() {
      return Fandom.exists(this.currentActiveStory.image) ? "image" : "thumbnail";
    }
  },
  computed: {
    enoughChildren() {
      const children = get(this, ["content", "children"], []);
      const childrenLengthCheck = this.isViewportSmall ? 1 : 4;
      return children.length > childrenLengthCheck;
    },
    componentClasses() {
      return [
        this.contentType,
        this.content.theme ? `${this.content.theme}-theme` : ""
      ];
    },
    contentStyle() {
      return {
        "background-color": this.content.background_color,
        "color": this.content.color
      };
    },
    pagerClass() {
      return `per-page-${get(this, "content.per_page", 3)}`;
    },
    isViewportSmall() {
      return globalState.viewport.xs || globalState.viewport.sm || globalState.viewport.md;
    },
    tileUrl() {
      return this.content.url ? this.content.url.url : this.getContentURL(this.content);
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container pr-0 pr-sm-3 position-relative overflow-hidden" :class="componentClasses" :style="contentStyle" stripe-carousel-story-component>
    <stripe-header :content="content"></stripe-header>
    <div class="row">
      <div class="col-12 pl-0 px-md-0">
        <div class="flickity-carousel" ref="flickity-carousel" :class="pagerClass">
          <div class="flickity-carousel-item" v-for="(child, index) in content.children" :key="`carousel-slide-${index}`" :child_name="child">
            <div class="w-100">
              <component :lazyload-bg="true" :is="getContent(child).layout" :content="getContent(child)" :index="index" @tile-story-opened="openStoryModal"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center my-4" v-if="content.button">
        <a class="btn" :class="getPrimaryButtonClass(content.button_class)" :href="content.button.url" :target="getTarget(content.button)">
          {{content.button.title}}
        </a>
      </div>
    </div>
    
    <div class="story-modal d-flex align-items-center justify-content-center bg-black" id="story-modal" v-if="currentActiveStory">
      <a class="story-modal-close text-white position-absolute" href="javascript:void(0)" @click="closeStoryModal()">
        <i class="fal fa-times"></i>
      </a>

      <a class="story-modal-muted text-white position-absolute" @click="toggleMute()">
        <i class="fas" :class="muted ? 'fa-volume-mute' : 'fa-volume'"></i>
      </a>
      
      <div class="flickity-carousel-story w-100" ref="flickity-carousel-story">
        <div class="flickity-carousel-story-item" v-for="(chapter, index) in currentActiveStory.chapters" :key="`carousel-story-slide-${index}`">
          <div class="story-modal-image-container w-100 h-100 bg-cover-center" v-if="getChapterType(chapter) == 'image'" :style="{'background-image': getChapterBackgroundImage(chapter)}"></div>
          <div class="story-modal-video-container w-100 h-100 bg-cover-center" v-else-if="getChapterType(chapter) == 'video'">
            <video class="story-modal-video w-100 h-100" muted webkit-playsinline playsinline>
              <source type="video/mp4" :src="chapter.video.url">
            </video>
          </div>
          <div class="story-modal-button-container position-absolute" v-if="chapter.button">
            <a :href="chapter.button.url" :rel="getRelNoFollow(chapter.button)" :target="getTarget(chapter.button)" class="story-modal-link-area w-100 d-flex flex-column align-items-center">
              <button class="btn px-5" :class="chapter.button_class || 'btn-primary'">{{chapter.button.title}}</button>
              <span class="story-modal-button-arrow text-primary"><i class="fal fa-angle-down"></i></span>
            </a>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<style lang="scss">
[stripe-carousel-story-component] {

  .flickity-page-dots {
    bottom: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;

    .dot {
      margin: 0 .25rem;
    }
  }

  .flickity-prev-next-button {
    color: $white;
    background-color: $red;
  }


  .flickity-carousel-item {
    width: 80%;

    @media (orientation: landscape) {
      width: 40%;
    }

    @include media-breakpoint-up(sm) {
      width: 40%;
    }

    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }
  
  .story-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;

    &-close, &-muted {
      text-decoration: none;
      @include font-size(2rem);
      top: 1rem;
      right: 1.7rem;
      z-index: 100;

      @include media-breakpoint-up(sm) {
        right: 1rem;
      }
    }

    &-muted {
      right: 3.5rem;
    }

    &-video {
      object-fit: cover;
    }
  }

  @include media-breakpoint-up(lg) {
    @for $i from 1 through 6 {
      .per-page-#{$i} {
        .flickity-carousel-item {
          width: 100% / $i;
        }
      }
    }
  }
  
  .flickity-carousel-story {
    .flickity-page-dots {
      .dot {
        width: 1.5rem;
        max-width: var(--dot-width-mobile);
        height: .125rem;
        margin: 0 .25rem;
        background: $white;
        border-radius: 0;
        opacity: 0.25;
        cursor: pointer;

        &.is-selected {
          opacity: 1;
        }

        @include media-breakpoint-up(sm) {
          width: 1.25rem;
          max-width: auto;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      .flickity-page-dots {
        position: absolute;
        top: 0;
        bottom: auto;
        margin-top: 0;
      }

      .flickity-prev-next-button {
        opacity: 0;
        height: 100%;
        width: 20%;
      }
    }
  }

  .flickity-carousel-story-item {
    height: 100vh;
    width: 100%;

    .story-modal-button-container {
      width: 90%;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);

      @include media-breakpoint-up(sm) {
        width: 80%;
        bottom: 0;
      }
    }

    &.with-transition {
      transition: none;

      @include media-breakpoint-up(sm) {
        transition: padding 250ms linear;
      }
    }

    &:not(.is-selected) {
      opacity: 0.2;
      padding: 0;

      .story-modal-button-container {
        display: none;
      }

      @include media-breakpoint-up(sm) {
        padding: 4rem;
      }
    }

    @media (orientation: landscape) {
      height: 75vh;
      width: 100%;
    }

    @include media-breakpoint-up(sm) {
      height: 75vh;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      height: 85vh;
      width: 30%;
    }
  }

  .story-modal-link-area {
    text-decoration: none;
  }

  .story-modal-button-arrow {
    @include font-size(4rem);
    line-height: 2rem;
  }
}
</style>
