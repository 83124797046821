<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";
require('flickity-imagesloaded');
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      slider: null
    };
  },
  mounted() {
    const initCarousel = () => {
      Vue.nextTick(() => {
        if (Fandom.exists(this.slider)) {
          this.slider.destroy();
        }
        if (this.enableCarousel) {
          const childrenLength = this.slides.length;
          this.slider = new Flickity(this.$refs["carousel"], {
            draggable: ">1",
            wrapAround: true,
            imagesLoaded: true,
            prevNextButtons: false,
            pageDots: (childrenLength > 1)
          });
        }
      });
    }
    $(window).on("debouncedResize", initCarousel);
    Vue.nextTick(() => initCarousel());
  },
  computed: {
    slides() {
      return get(this, ["content", "children"], []);
    },
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    enableCarousel() {
      return this.isMobile || this.slides.length > 2;
    },
    arClass() {
      if (!this.isMobile && this.slides.length === 1) {
        return "ar-32by9";
      }
      return "ar-16by9";
    }
  }
}
</script>

<template>
  <div class="w-100" v-easyadmin="easyadminId" :class="contentType">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 py-4 px-0">
          <div ref="carousel" :class="{'flickity-carousel w-100': enableCarousel, 'container px-0 d-flex': !enableCarousel}">
            <div class="flickity-carousel-item" :class="{'first-child-margin-right': slides.length == 2}" v-for="(child, index) in slides" :child_name="child" :key="`carousel-slide-${index}`">
              <component :is="getContent(child).layout" :content="getContent(child)" :index="index" :ar="arClass"></component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flickity-carousel-item {
  .flickity-carousel & {
    width: calc(100% - 3rem);
    margin: 1.5rem;

    @include media-breakpoint-up(md) {
      width: 45%;
      margin: 0 .5rem;
    }
  }

  .d-flex & {
    position: relative;
    flex: 1;
  }

  &.first-child-margin-right:first-child {
    margin-right: 1rem;
  }
}
</style>
