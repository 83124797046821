<script>
export default {
  data() {
    return {
      title: null,
      breadcrumb: []
    };
  },
  methods: {
    applyContextToUrl: Fandom.applyContextToUrl
  },
  computed: {
    current() {
      const mainContentName = globalState.pageModel.main_content_name;
      return globalState.pageModel.name_to_content[mainContentName].title;
    }
  },
  mounted() {
    if (globalState.pageModel.breadcrumb.length > 0) {
      for (const content of globalState.pageModel.breadcrumb) {
        if (Fandom.exists(content.title)) {
          this.breadcrumb.push({ title: content.title, url: content.slug });
        }
      }
    }
  }
};
</script>

<template>
  <div class="container px-sm-0" breadcrumb-component>
    <div class="row">
      <nav class="col-12 d-flex align-items-center py-3 py-md-4" aria-label="breadcrumb">
        <ol class="breadcrumb d-inline-block p-0 m-0">
          <li class="breadcrumb-item"><a :href="applyContextToUrl('')">Home</a></li>
          <li v-for="(b, i) in breadcrumb" class="breadcrumb-item active" aria-current="page" :key="i">
            <template v-if="b.url">
              <a :href="applyContextToUrl(b.url)">{{b.title}}</a>
            </template>
            <template v-else>
              {{b.title}}
            </template>
          </li>
          <li class="breadcrumb-item" aria-current="page">{{current}}</li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[breadcrumb-component] {
  background-color: transparent;

  .breadcrumb {
    background-color: transparent;
    font-family: $neutra-text-font;
    @include font-size(.75rem);
    line-height: 1.25rem;
    text-transform: uppercase;
    li {
      display: inline;
    }
    *, .breadcrumb-item > a, .breadcrumb-item + .breadcrumb-item::before {
      color: $medium-grey !important;
      font-weight: 300;
    }

    .breadcrumb-item {
      text-transform: initial;
      font-weight: normal !important;
    }

    .breadcrumb-item:last-child {
      font-weight: 600;
      color: $medium-grey !important;
    }

    .breadcrumb-item + .breadcrumb-item {
      padding-left: .125rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
    }

    @include media-breakpoint-down(xs) {
      li {
        padding-left: 0.2rem;
      } 
      
      li:first-child {
        padding-left: 0rem;
      }
    }
  }
}
</style>
