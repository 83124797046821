<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { cinqueStagioniBasicMixin, cinqueStagioniAuthorizationMixin } from '../../modules/cinque_stagioni_mixins_module.js';
import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin, cinqueStagioniAuthorizationMixin, interactionMixin, fandomExtendedMixin],
  methods: {
    getContentURL: Fandom.getContentURL,
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    trackingUrl(event) {
      sendFandomAnalyticsEvent("Content Interaction", "Discover More Button Click", this.containerContent.name);
      window.location.href = $(event.currentTarget).attr("href");
      return false;
    }
  },
  computed: {
    themeType() {
      return "dark";
    },
    contentUrl() {
      return  Fandom.getContentURL(this.content);
    },
    contentColor() {
      return get(this, ["decorator","color"], false);
    },
    contentBackgroundColor() {
      return get(this, ["content","background_color"], this.contentColor);
    },
    category() {
      return get(this, ["decorator", "title"], this.content.category);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" class="row h-100 flex-lg-row-reverse mx-0" :style="[{'background-color': contentBackgroundColor}, theme]" course-tile-component>
    <div class="col-12 col-lg-6 px-0 bg-dark bg-transition">
      <a :href="contentUrl" @click.prevent="trackingUrl($event)" class="w-100 h-100 d-flex align-items-center">
        <div class="ar-16by10 bg-cover-center w-100 h-100 bg" :style="{'background-image': getContentResponsiveImageURL('image')}"></div>
        <div class="video-icon position-absolute text-white text-center border border-white rounded-circle">
          <i class="fas fa-play"></i>
        </div>
      </a>
    </div>
    <div class="col-12 col-lg-6 p-4 d-flex flex-column justify-content-between">
      <div class="pb-3 pb-sm-0">
        <div v-if="category" class="category d-inline-block mb-3 text-uppercase"><small>{{category}}</small></div>
        <div v-if="content.title" class="mb-3">
          <span class="d-inline-block h1 mb-0">{{content.title}}</span>
        </div>
        <p class="d-none d-sm-block" v-if="content.subtitle">{{content.subtitle}}</p>
      </div>
      <div class="">
        <div class="button-group d-flex flex-column flex-md-row flex-wrap">
          <div v-if="!authorized && relatedContent">
            <button @click.prevent="mayRedirectOnFreeContent" class="btn w-100" :class="themeButtonClass">
              <i class="fal fa-play mr-2"></i>{{ft("globals.login.login")}}
            </button>
          </div>
          <a
            :href="getContentURL(relatedContent)"
            class="btn d-flex align-items-center justify-content-center"
            :class="themeButtonClass"
            v-if="authorized && relatedContent"
            >
            <i class="far fa-play mr-2"></i>
            {{ft('globals.login.login')}}
          </a>
          <a :href="contentUrl" @click.prevent="trackingUrl($event)" class="text-uppercase p-3 d-flex align-items-center justify-content-center">
            <small>
              {{ft('globals.view_more')}}
              <i class="fal fa-chevron-right ml-2"></i>
            </small>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[course-tile-component] {
  color: var(--theme-color);
  background-color: $gray-800;

  a:not(.btn) {
    color: var(--theme-color) !important;
  }

  .video-icon {
    right: 1rem;
    bottom: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5;
    padding-left: 4px;
  }

  .ar-16by10 {
    padding-bottom: 62.5%;
  }


  .category {
    text-transform: uppercase;
  }

  .button-group {
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    .btn {
      margin-left: 0.25rem;
      margin-right: 0.25rem;

      @include media-breakpoint-down(sm) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
      }
    }
  }
}
</style>
