<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";
import { get } from "lodash";

export default {
  name: "stripe-carousel-fw",
  mixins: [fandomBasicMixin],
  data() {
    return {
      currentSlide: null
    };
  },
  computed: {
    miniformatTitle() {
      return get(this.containerContent, ["decorators", "miniformat"], null);
    },
  },
  methods: {
    scrollToBottom: Fandom.scrollToBottom,
    getContentURL: Fandom.getContentURL,
    getContentURLByContentName(contentName) {
      return Fandom.getContentURL(Fandom.getContent(contentName));
    },
    autoPlaySelectedSlide(slider) {
      const selectedSliderVideos = slider.selectedElement.querySelectorAll("video");
      for (const video of selectedSliderVideos) {
        video.muted = true; // Muted attribute seems to not be parsed correctly by vue.
        video.play();
      }
    },
  },
  mounted() {
    if (Fandom.exists(this.content.children)) {
      Vue.nextTick(() => {
        const childrenLength = this.content.children.length;
        const sliderOptions = {
          draggable: ">1",
          prevNextButtons: (childrenLength > 1),
          wrapAround: true,
          pageDots: (childrenLength > 1)
        };
        if (this.content.autoplay) {
          sliderOptions.autoPlay = parseInt(this.content.autoplay);
        }
        const slider = new Flickity(this.$refs["flickity-carousel"], sliderOptions);

        slider.on("select", () => {
          const currentSlideVideos = this.currentSlide.querySelectorAll("video");
          for (const video of currentSlideVideos) {
            video.pause();
          };
          this.autoPlaySelectedSlide(slider);
          this.currentSlide = slider.selectedElement;
        });

        this.currentSlide = slider.selectedElement;
        this.autoPlaySelectedSlide(slider);
      });
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" stripe-carousel-fullscreen-component>
    <div class="flickity-carousel" ref="flickity-carousel">
      <div class="flickity-carousel-item w-100" v-for="(item, index) in content.children" :child_name="item" :key="`carousel-slide-${index}`">
        <a :href="getContentURL(getContent(item))" class="deco-none">
          <stripe-main-banner :miniformatTitle="miniformatTitle" :content="getContent(item)"></stripe-main-banner>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-carousel-fullscreen-component] {
  position: relative;

  .flickity-carousel {
    position: relative;
    color: $white;
    background-color: $black;
  }

  .flickity-page-dots {
    bottom: .5rem;
    .dot {
      background-color: $white;
      border: 1px solid $white;
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-prev-next-button,
    .flickity-prev-next-button {
      display: none;
    }
  }
}
</style>

