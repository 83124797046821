<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  props: ["keypoint"],
  data() {
    return { }
  },
  methods: { },
  computed: {
    getWrapperTag() {
      return Fandom.exists(this.keypoint.url) ? "a" : "div";
    },
    getPointUrl() {
      return Fandom.exists(this.keypoint.url) ? this.keypoint.url.url : null;
    },
    getPointTitle() {
      return get(this.keypoint, ["url", "title"], null); 
    }
  },
};

</script>

<template>
  <component
    class="d-flex flex-column align-items-center text-center"
    :is="getWrapperTag"
    :href="getPointUrl"
    :title="getPointTitle"
    :target="getTarget(keypoint.url)"
    :class="{'has-url': keypoint.url}"
    keypoint-item
  >
    <div class="mx-0 mx-sm-2 mb-3">
      <div v-if="keypoint.amount" class="amount">{{keypoint.amount}}</div>
      <img class="point-image pb-4" :src="keypoint.image.url" v-if="keypoint.image">
      <p class="point-title mb-2" v-if="keypoint.title">{{keypoint.title}}</p>
      <p class="point-description" v-if="keypoint.description" v-html="keypoint.description"></p>
      <a role="button" class="btn shadow-none mt-3" :class="getPrimaryButtonClass(keypoint.button_class)" v-if="keypoint.button" :href="keypoint.button.url" :target="getTarget(keypoint.button)">
        <i :class="keypoint.button_icon" class="mx-2" v-if="keypoint.button_icon"></i>
        {{point.button.title}}
      </a>
    </div>
  </component> 
</template>

<style lang="scss">
[keypoint-item] {

  p {
    margin-bottom: 0;
  }

  .point-image {
    width: 100%;
    height: auto;
  }

  .amount {
    @include font-size(4.5rem);
    font-weight: 400;
  }

  .point-title,
  .point-description {
    @include font-size(1rem);
    color: $medium-grey !important;
  }

  .point-title {
    text-transform: uppercase;
  }

  .point-description {
    font-weight: 300;
    font-style: italic;

    &, & > p {
      opacity: .6;
    }
  }

  .has-url {
    .point-image {
      transition: transform .5s ease-in-out;
    }
    .point-title {
      transition: color .5s ease-in-out;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $primary;

      .point-image {
        transform: scale(1.1);
      }

      .point-title {
        color: $primary;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .point-title,
    .point-description {
      @include font-size(.875rem);
    }
  }
}
</style>