<script>
import { fandomTranslate } from "src/modules/common_module";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    allergens: {
      type: Array,
      default: () => []
    },
    ingredients: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ft: fandomTranslate
  }
};
</script>

<template>
  <div class="recipe-ingredients premium-variable-bg-transparent rounded py-3 py-lg-4">
    <div class="col-12 ingredients-head premium-variable-text cursor-pointer px-0">
      <h3 class="font-xl px-4">{{title}}</h3>
    </div>
    <div v-if="allergens.length > 0" class="d-block font-sm px-4 allergens">
      <div class="d-inline">{{ft("recipe.allergens")}}:&nbsp;</div>
      <div v-for="(allergen, index) in allergens" class="d-inline" :key="`allergen-${index}`">
        {{allergen}}{{(allergens.length - 1) > index ? ", " : ""}}
      </div>
    </div>
    <ul v-if="ingredients.length > 0" class="ingredients p-0 mt-3 mx-3">
      <li
        v-for="(ingredient, index) in ingredients"
        class="d-block ingredient font-lg border-bottom premium-variable-border pt-2 pl-2"
        :class="{'border-top': index === 0}"
        :key="`ingredient-${index}`"
        >
        <div class="pt-4"><strong>{{ingredient.quantity}}</strong> {{ingredient.title}}</div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.ingredient {
  line-height: 1;
}
</style>
