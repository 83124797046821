import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";
import { debounce } from "lodash";

// exports

function getScrollPercent() {
  let h = document.documentElement, 
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight';
  return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
}

function checkPercentageScroll() {
  const scrollPercentage = { 25: false, 50: false, 75: false };
  $(window).on("scroll", debounce(() => {
    Object.keys(scrollPercentage).forEach(p => {
      if ((getScrollPercent() > p || getScrollPercent() > p) && !scrollPercentage[p]) {
        scrollPercentage[p] = true;
        sendFandomAnalyticsEvent("Scroll", `${p}% scrolled`, globalState.pageModel.main_content_name);
      }
    });
  }, 150));
}

function trackLinkVisited(linkSelector, category, action, label) {
  $(linkSelector).click((event) => {
    event.preventDefault();
    const href = $(event.currentTarget).attr("href");
    const target = $(event.currentTarget).attr("target");
    sendFandomAnalyticsEvent(category, action, label);
    if (target == "_blank") {
      window.open(href);
    } else {
      window.location = href;
    }
  });
}

const CinqueStagioni = {
  trackLinkVisited,
  checkPercentageScroll
};

export { CinqueStagioni };
