<script>
import { get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { cinqueStagioniBasicMixin, cinqueStagioniRelatedMixin } from "../../modules/cinque_stagioni_mixins_module.js";

export default {
  // force deploy
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin, cinqueStagioniRelatedMixin],
  props: {
    tagExtraField: String,
    optionalRelatedContentNames: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    const tag = get(this.content, this.tagExtraField, this.content.decorators.default);
    if (this.optionalRelatedContentNames.length === 0) {
      this.getRelatedContents(tag, "tile-article");
    } else {
      this.getRelatedContentsByNames(this.optionalRelatedContentNames, "tile-article");
    }
  }
};
</script>

<template>
  <div stripe-related-articles>
    <div class="container">
      <div class="row justify-content-md-center pt-4">
        <div class="col-sm-8 text-center pt-2 mx-auto">
          <span class="title d-inline-block font-weight-bold text-uppercase text-red mb-3 mb-md-4">
            {{ft('globals.more_on')}}
          </span>
        </div>
      </div>
    </div>
    <grid-fixed :content="resultContent" v-if="resultContent" :trackTileClick="['Interaction', 'Related Click']"></grid-fixed>
  </div>
</template>

<style lang="scss" scoped>
.title {
  @include font-size(1.875rem);
}
</style>
