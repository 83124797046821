<script>
import { get } from "lodash";
const formatMatchingText = (text, match) => {
  if (typeof text === "string" && typeof match === "string") {
    const regex = match.replace(/\W+/, "|");
    return text.replace(new RegExp(regex, "gi"), "<span class=\"match\">$&</span>");
  } else {
    return text;
  }
};

export default {
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      content: this.item,
      tileHeight: 30
    };
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
  },
  computed: {
    thumbnail() {
      return get(this, ["content", "thumbnail", "url"], false);
    },
    searchText() {
      return get(this, ["$attrs", "searchText"], false);
    },
    matchedTitle() {
      return formatMatchingText(this.item.title, this.searchText);
    },
    matchedDescription() {
      const headline = get(this, ["content", "headline"], "");
      return headline.replace(/(<b>)/ig, "<b class=\"match\">");
    },
    enableThumbnail() {
      return get(this, ["content", "enableThumbnail"], false);
    }
  }
};
</script>

<template>
  <div v-if="!content.searchText" class="p-2 d-flex align-items-center" :key="item.name" stripe-search-autocomplete-item-component>
    <div class="mr-2" v-if="enableThumbnail">
     <div v-if="thumbnail" class="bg-cover-center item-thumbnail" :style="{'background-image': getBackgroundUrl(thumbnail)}">
      </div>
      <div class="item-thumbnail d-flex align-items-center justify-content-center" v-else>
        <i class="far fa-image fa-2x"></i>
      </div>
    </div>
    <div>
      <p class="mb-0 item-title" v-html="matchedTitle"></p>
      <div class="mb-0 item-description" v-html="matchedDescription"></div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-search-autocomplete-item-component] {
  .item-description {
    .match {
      font-weight: bold;
      color: $red !important;
    }
  }
}
</style>

<style lang="scss" scoped>
$stripe-search-autocomplete-item-thumbnail-size: 5rem;
$light-gray: #a4a4a4;

[stripe-search-autocomplete-item-component] {
  .item-thumbnail {
    width: $stripe-search-autocomplete-item-thumbnail-size;
    height: $stripe-search-autocomplete-item-thumbnail-size;
    background-color: $gray-300;
  }

  .item-description {
    @include font-size(.75rem);
    color: $light-gray;
    white-space: nowrap;
    max-width: 50vw;
    width: $stripe-search-autocomplete-item-thumbnail-size * 4;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
