<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";
require('flickity-imagesloaded');
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      slider: null
    };
  },
  computed: {
    slides() {
      let slidesMobile = [];
      let slideDesktop = [];
      this.content.children.forEach(slide => {
        if(this.getContent(slide).parent_names.indexOf('desktop-slide') > -1) {
          slideDesktop.push(slide);
        }
        if(this.getContent(slide).parent_names.indexOf('mobile-slide') > -1) {
          slidesMobile.push(slide);
        }
      });
      return globalState.viewport.xs ? slidesMobile : slideDesktop;
    }
  },
  methods: {
    getContentStyle () {
      var styleObject = {};
      if (Fandom.exists(this.content.background_color)) {
        styleObject["background-color"] = this.content.background_color;
      }
      return styleObject;
    }
  },
  mounted() {
    const initCarousel = () => {
      if (Fandom.exists(this.content.children)) {
        Vue.nextTick(() => {
          const childrenLength = this.content.children.length;
          if (Fandom.exists(this.slider)) {
            this.slider.destroy();
          }
          this.slider = new Flickity(this.$refs["flickity-carousel"], {
            arrowShape: {x0: 10, x1: 60, y1: 50, x2: 65, y2: 45, x3: 20},
            draggable: ">1",
            prevNextButtons: (childrenLength > 1),
            wrapAround: true,
            imagesLoaded: true,
            pageDots: (childrenLength > 1)
          });
        });
      }
    }
    $(window).on("debouncedResize", initCarousel);
    Vue.nextTick(() => initCarousel());
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :style="getContentStyle()" class="w-100" stripe-carousel-component>
    <div class="container py-5">
      <div class="flickity-carousel" ref="flickity-carousel">
        <div class="flickity-carousel-item w-100" v-for="(child, index) in slides" :child_name="child" :key="`carousel-slide-${index}`">
          <component :is="getContent(child).layout" :content="getContent(child)" :index="index"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[stripe-carousel-component] {
  position: relative;

  .flickity-carousel {
    position: relative;

    .flickity-button {
      background: transparent;
    }

    .flickity-prev-next-button {
      height: 75px;
      width: 75px;
    }

    .flickity-prev-next-button.previous {
      left: -65px;
    }
    .flickity-prev-next-button.next {
      right: -65px;
    }

    .flickity-page-dots {
      bottom: .5rem;
      & .dot.is-selected {
        background-color: $black;
        border: 1px solid $black;
      }

      .dot {
        background-color: $white;
        border: 1px solid $white;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-prev-next-button,
    .flickity-prev-next-button {
      display: none;
    }
  }
}
</style>
