<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
    	bookmark: this.content.chapters[0].chapters[0],
      showSidebar: false,
      unitNumber: 1,
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.initalBookark();
    });
  },
  watch: {
    bookmark(newValue, previousValue) {
      if (this.$refs["course-sidebar-desktop"]) {
        this.$refs["course-sidebar-desktop"].bookmark = newValue;
      }
      if (this.$refs["course-sidebar-mobile"]) {
        this.$refs["course-sidebar-mobile"].bookmark = newValue;
      }
    },
    unitNumber(newValue, previousValue) {
      if (this.$refs["course-sidebar-desktop"]) {
        this.$refs["course-sidebar-desktop"].unitNumber = newValue;
      }
      if (this.$refs["course-sidebar-mobile"]) {
        this.$refs["course-sidebar-mobile"].unitNumber = newValue;
      }
    }
  },
  methods: {
    initalBookark() {
      const location = window.location.href;
      const bookmarkAnchorPrefix = `bookmark-${this.containerContent.id}-`;
      if (location.includes(`#${bookmarkAnchorPrefix}`)) {
        const anchorChapterId = location.split("#")[1].replace(bookmarkAnchorPrefix, "");
        let tmpUnitNumber = 1;

        this.content.chapters.forEach(chapter => {
          chapter.chapters.forEach(chapter => {
            if (chapter.$id == anchorChapterId) {
              this.bookmark = chapter;
              this.unitNumber = tmpUnitNumber;
            } 
          });
          tmpUnitNumber++;
        });
      }
    },
  	setBookmark(data) {
  		this.bookmark = data.chapter;
      this.unitNumber = data.unitNumber;
      if (this.showSidebar) {
        this.toggleSidebar();
      }
  	},
    toggleSidebar() {
      if (!this.showSidebar) {
        this.showSidebar = true;
        Vue.nextTick(() => {
          disableBodyScroll(this.$refs["sidebar"]);
        });
      } else {
        this.showSidebar = false;
        clearAllBodyScrollLocks();      
      }
    },
    goToNextBookmark() {
      const unit = this.content.chapters[this.unitNumber - 1];

      const activeIndex = unit.chapters.findIndex(chapter => {
        return chapter.$id === this.bookmark.$id;
      });
      if (activeIndex < (unit.chapters.length - 1)) {
        this.bookmark = unit.chapters[activeIndex + 1];
      } else if (this.unitNumber < this.content.chapters.length) {
        this.bookmark = this.content.chapters[this.unitNumber].chapters[0];
        this.unitNumber += 1;
      } else {
        /* do nothing */
      }
    },
    lastBookmark() {
      const unit = this.content.chapters[this.unitNumber - 1];
      const activeIndex = unit.chapters.findIndex(chapter => {
        return chapter.$id === this.bookmark.$id;
      });
      return this.unitNumber === this.content.chapters.length && activeIndex === (unit.chapters.length - 1);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" course-product-component>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-auto px-0">
          <div v-show="globalState.viewport.md || globalState.viewport.lg || globalState.viewport.xl" class="sidebar h-100 bg-super-dark-gray">
            <course-sidebar class="w-100" ref="course-sidebar-desktop" :content="content" :propBookmark="bookmark" @set-bookmark="setBookmark" @toggle-sidebar="toggleSidebar()"></course-sidebar>
          </div>
          <transition 
            enter-active-class="animated faster slideInLeft" 
            leave-active-class="animated faster slideOutLeft"
          >
            <div class="sidebar h-100 bg-super-dark-gray" v-show="showSidebar && !(globalState.viewport.md || globalState.viewport.lg || globalState.viewport.xl)" ref="sidebar">
              <course-sidebar class="w-100" ref="course-sidebar-mobile" :content="content" :propBookmark="bookmark" @set-bookmark="setBookmark" @toggle-sidebar="toggleSidebar()"></course-sidebar>
            </div>
          </transition>
        </div>
        <div class="col-12 col-md px-0">
          <div
            v-if="bookmark.layout"
            :is="bookmark.layout"
            v-on:next-bookmark="goToNextBookmark"
            :content="bookmark"
            :lastBookmark="lastBookmark()"
            :opt-container-content="containerContent"
            :animation="false"
            :unitNumber="unitNumber"
            :key="bookmark.$id"
            bookmark
          ></div>
          <component :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent" class="bookmark__footer"></component>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="position-fixed course-product__sidebar" v-if="showSidebar" @click="toggleSidebar()"></div>
    </transition>
    <button class="btn btn-light show-sidebar px-3 d-md-none" @click="toggleSidebar()">
      <i class="fal fa-bars mr-2"></i>{{ft('ecommerce.content')}}
    </button>
  </div>
</template>

<style lang="scss">
[course-product-component] {

  .sidebar {
    width: 26.5rem;
    max-width: 100%;
  }

  [bookmark] .container, .bookmark__footer .container {
    width: 100% !important;
    max-width: none;
  }

  .show-sidebar {
    left: 1rem;
    z-index: 1;
    position: fixed;
    border-radius: 0;
    bottom: 1rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .course-product__sidebar {
    background-color: rgba($gray-200, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include media-breakpoint-down(sm) {
      
    .sidebar {
      position: fixed;
      width: 85%;
      top: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      z-index: $zindex-youtube-popup;
    }  
  }

}
</style>