<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { sendFandomAnalyticsEvent }  from "src/modules/fandom_analytics_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  methods: {
    formSubmitDone (data) {
      if (data.success) {
        sendFandomAnalyticsEvent("Complete Profile", "Complete Profile", `content_${this.contentId}_interaction_${this.content.interaction.id}`);
      }
    }
  },
  computed: {
    componentStyle() {
      return {
        "background-color": this.content.background_color
      };
    },
    userEditContainerContent() {
      return get(this.pageModel, ["user", "tag"], this.containerContent);
    },
    userEditContent() {
      return get(this.pageModel, ["user", "tag", "user_edit_form"], this.content);
    }
  }
};
</script>

<template>
  <div class="w-100" user-edit-component :style="componentStyle">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-6 my-4">
          <div class="mb-4">
            <h3 class="mb-3 h5 text-uppercase text-center text-red">{{ft("globals.user_profile.user_edit_title")}}</h3>
            <generic-form :content="userEditContent" :opt-container-content="userEditContainerContent" @done="formSubmitDone"></generic-form>
          </div>
          <h3 class="mb-3 h5 text-uppercase text-center text-red">{{ft("globals.user_profile.password_edit_title")}}</h3>
          <user-password-editing-form :content="content" :opt-container-content="containerContent"></user-password-editing-form>
        </div>  
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>
