<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { map, get } from "lodash";
export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      resultContent: {}
    };
  },
  mounted() {
    this.getRecipes(false);
  },
  methods: {
    getRecipes(append) {
      const params = {
        layout: "tile-recipe",
        layout_extra_field_name: "$empty",
        include_rich_info: true,
        exclude_content_ids: map(this.resultContent.children, recipe => this.getContent(recipe.id)),
        content_filtering: ["ricette"],
        include_user_ids: [globalState.pageModel.user.id],
        include_extra_field_content_filtering: true,
        max_depth: 1
      };
      Fandom.getSearchableContents((data) => {
        this.resultContent = data.name_to_content[data.main_content_name];
      }, params, append);
    },
    alternativeAppendContents() {
      this.getRecipes(true);
    }
  },
  computed: {
    userRecipes() {
      return get(this, ["resultContent", "children"], []);
    },
    btnClass() {
      return get(this, ["content", "button_class"], "btn btn-white");
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="container" v-if="isContentVisibleToUser()" user-recipes-grid-component>
    <transition enter-active-class="animated faster fade" leave-active-class="animated faster fade">
      <div class="row justify-content-center" v-if="userRecipes.length > 0" key="grid-row">
        <div class="col-12 text-center">
          <h3>{{content.title}}</h3>
        </div>
        <grid-fixed
          class="col-12 pb-3 pb-md-4"
          :ref="`grid-isotope-${this.resultContent.name}`"
          :content="resultContent"
          :key="resultContent.name"
          :alternative-append-contents="alternativeAppendContents"
          ></grid-fixed>
      </div>
      <div class="row justify-content-center" key="publish-row" v-else>
        <div class="col-12 row justify-content-center py-5 bg-red text-white" :style="{'background_color': content.background_color}">
          <div class="col-12 col-md-10 d-flex flex-column align-items-center text-center">
            <img class="icon" :src="expandUrl(content.icon.url)" />
            <h3 class="mt-3 mb-5" v-html="content.description"></h3>
            <a class="py-3 px-4" :class="btnClass" :href="expandUrl(content.button.url)" v-if="content.button">
              {{content.button.title}}
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[user-recipes-grid-component] {
  .icon {
    width: 6rem;
    max-width: 100%;
  }
}
</style>
