<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { CinqueStagioni } from "../../modules/cinque_stagioni_module.js";
import { cinqueStagioniAuthorizationMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, cinqueStagioniAuthorizationMixin],
  props: {
    trackTileClick: Array,
    ar: {
      type: String,
      default: "ar-16by9"
    }
  },
  mounted() {
    // google analytics
    if (!!this.trackTileClick){
      Vue.nextTick(() => {
        CinqueStagioni.trackLinkVisited(`#ref-${this.easyadminId}-link`, this.trackTileClick[0], this.trackTileClick[1], this.content.name);
      });
    }
  },
  methods: {
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getContentURL: Fandom.getContentURL
  },
  computed: {
    tileUrl() {
      return this.content.url ? this.content.url.url : this.getContentURL(this.content);
    },
    thumbnailUrl() {
      return this.content.thumbnail ? this.getBackgroundUrl(this.content.thumbnail.url) : "";
    },
    decoratorColorClass() {
      const color = get(this, ["decorator", "color"], "red");
      return `bg-${color}`;
    },
    iconClass() {
      return this.authorized ? get(this, ["content", "icon"], "fa-play") : "fa-lock";
    }
  }
};
</script> 

<template>
  <div v-easyadmin="easyadminId" :class="contentType" tile-article>
    <a :href="tileUrl" :target="getTarget(content.url)" class="d-flex flex-column tile-url cursor-pointer" :id="`ref-${this.easyadminId}-link`">
      <div class="w-100 bg-transition position-relative rounded overflow-hidden" :class="ar">
        <div class="w-100 h-100 bg-cover-center bg position-absolute-center" :style="{'background-image': thumbnailUrl}"></div>
        <div class="w-100 h-100 position-absolute-center d-flex align-items-center justify-content-center" :class="{'dark-layer': !authorized}">
          <span class="border border-white rounded d-inline-flex align-items-center justify-content-center icon-container">
            <i class="far fa-lock text-white" :class="iconClass"></i>
          </span>
        </div>
        <div v-if="decorator" :class="decoratorColorClass" class="category font-xs text-white py-2 px-3 rounded position-absolute">
          {{decorator.title}}
        </div>
        <div class="text-white title-wrapper position-absolute">
          <span class="d-block font-weight-bold font-xl" v-shave="{ height: 80 }">
            {{content.title}}
          </span>
          <span class="d-block subtitle" v-if="content.subtitle">{{content.subtitle}}</span>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
$icon-size: 3.5rem;

.tile-url {
  @include hover {
    text-decoration: none;
  }
}

.category {
  line-height: 1;
  top: 1rem;
  left: 1rem;
}

.subtitle {
  @include font-size(1.25rem);
  @include media-breakpoint-down(xs) {
    line-height: 0.5;
  }
}

.title-wrapper {
  bottom: 1rem;
  left: 1rem;
}

.dark-layer {
  background-color: rgba($black, .3);
}

.icon-container {
  @include font-size($icon-size / 2);
  width: $icon-size;
  height: $icon-size;
  border-width: 2px !important;
}
</style>
