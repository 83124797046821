<script>
import Flickity from "flickity";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get, union } from "lodash";

const minimizeMenuItem = content => {
  return {
    slug: get(content, ["url", "url"], content.slug),
    icon: content.icon,
    title: content.title,
    name: content.name
  };
};

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      carousel: false
    };
  },
  mounted() {
    if (this.menu.length > 0) {
      Vue.nextTick(this.toggleCarousel);
      $(window).on("debouncedResize", this.toggleCarousel);
    }
  },
  beforeDestroy() {
    $(window).off("debouncedResize");
  },
  methods: {
    isActive(menuItem) {
      return this.mainContent.name.includes(menuItem.name);
    },
    toggleCarousel() {
      if (!this.carousel && this.isMobile) {
        this.carousel = new Flickity(this.$refs["menu"], {
          draggable: ">1",
          prevNextButtons: false,
          cellAlign: "left",
          wrapAround: false,
          freeSCroll: false,
          pageDots: false
        });
      }
      if (this.carousel && !this.isMobile) {
        this.carousel.destroy();
        this.carousel = false;
      }
    },
  },
  computed: {
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    menu() {
      return this.childrenMenu.concat(this.contentMenu).map(minimizeMenuItem);
    },
    childrenMenu() {
      const children = get(this, ["content", "children"], []);
      const ordering = get(this, ["content", "ordering"], []);
      const menuFilters = union(typeof ordering === "string" ? ordering.split(",") : ordering, children);
      return menuFilters.map(Fandom.getContent).filter(Fandom.exists);
    },
    contentMenu() {
      return get(this, ["content", "icons"], []);
    },
    viewAll() {
      const data = get(this, ["content", "view_all"], false);
      if (data) {
        return {
          title: data.title,
          icon: data.icon,
          slug: get(data, ["url", "url"], ""),
          name: get(data, ["url", "content"], "")
        };
      }
      return data;
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="row justify-content-end" v-if="menu.length > 0">
      <div class="col-12 flickity-carousel" :class="{'d-flex justify-content-center flex-wrap': !isMobile}" ref="menu">
        <a
          v-for="(menuItem, index) in menu"
          class="d-flex flex-column align-items-center text-center menu-url py-3" 
          :href="applyContextToUrl(menuItem.slug)"
          :class="{'active': isActive(menuItem)}"
          :title="menuItem.title"
          :key="index"
          >
          <svg class="menu-icon" viewBox="0 0 40 40">
            <use :xlink:href="`#${menuItem.icon}`"></use>
          </svg>
          <span class="menu-title d-flex align-items-center text-uppercase mt-3 px-3">{{menuItem.title}}</span>
        </a>
        <a
          v-if="viewAll"
          class="d-flex flex-column align-items-center text-center menu-url py-3" 
          :href="applyContextToUrl(viewAll.slug)"
          :class="{'active': isActive(viewAll)}"
          :title="viewAll.title"
          >
          <svg class="menu-icon" viewBox="0 0 40 40">
            <use :xlink:href="`#${viewAll.icon}`"></use>
          </svg>
          <span class="menu-title d-flex align-items-center text-uppercase mt-3 px-3">{{viewAll.title}}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.w08 {
  stroke-width: .8;
}

.fs {
  fill: currentColor;
}

.ss {
  stroke: currentColor;
}
</style>

<style lang="scss" scoped>
$transition-time: .5s;

.menu-url {
  color: $black;
  transition: color $transition-time ease-in-out;
  text-decoration: none;
  width: calc(45% - 1rem);

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: $red;
  }

  @include media-breakpoint-up(sm) {
    width: 20%;
  }

  @include media-breakpoint-up(lg) {
    width: 7.25rem;
  }
}

.menu-icon {
  width: 3rem;
  height: 3rem;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.menu-title {
  height: 1.75rem;
  @include font-size(1rem);
  white-space: nowrap;
}
</style>
