<script>
import { initMap, loadMap, createInfoWindow } from 'src/modules/google_maps_module.js';
import { fandomTranslate } from "src/modules/common_module.js";

export default {
  data() {
    return {
      autocompleteDisabled: true,
      activeMarker: null,
      infoWindow: null,
      place: {},
      map: null,
      zoom: 6,
    }
  },
  mounted() {
    initMap(() => {
      Vue.nextTick(() => {
        if (this.$refs["google-map"]) {
          const map = loadMap(this.$refs["google-map"], { center: { lat: 41.9, lng: 12.6 }, zoom: this.zoom });
          Vue.set(this, "map", map);
          this.infoWindow = createInfoWindow(this.$refs["info-content"]);
          this.searchCity();
        }
      });
    }, true);
  },
  computed: {
    rating() {
      let ratingHtml = "";

      for (let i = 0; i < 5; i++) {
        ratingHtml += (this.place.rating < i + 0.5) ? "&#10025;" : "&#10029;";
      }
      return ratingHtml;
    },
    website() {
      const hostnameRegexp = new RegExp("^https?://.+?/");
      return String(hostnameRegexp.exec(this.place.website));
    },
  },
  methods: {
    ft: fandomTranslate,
    setLocation(place) {
      if (!!this.activeMarker) {
        this.activeMarker.setMap(null);
      }
      this.map.setZoom(16);
      this.map.setCenter(place.geometry.location);
      const marker = new google.maps.Marker({
        position: place.geometry.location
      });
      marker.placeResult = place;
      marker.setMap(this.map);
      google.maps.event.addListener(marker, "click", () => {
        Vue.set(this, 'place', marker.placeResult);
        this.infoWindow.open(this.map, marker);
      });
      Vue.set(this, 'activeMarker', marker);
      console.log(place);
      const data = {
        name: place.name,
        address: place.formatted_address,
        province: place.address_components.find(ac => ac.types.includes("administrative_area_level_2")).short_name
      }
      this.$emit('set-location', JSON.stringify(data));
    },
    searchCity() {
      if (!!this.map) {
        const params = {
          componentRestrictions: { country: ["it"] },
          fields: ["geometry"],
          types: ["(cities)"]
        };
        const autocomplete = new google.maps.places.Autocomplete(this.$refs["city"], params);
        google.maps.event.addDomListener(this.$refs["city"], 'keydown', function(event) { 
          if (event.keyCode === 13) { 
            event.preventDefault(); 
          }
        });
        autocomplete.addListener("place_changed", (event) => {
          const city = autocomplete.getPlace();
          this.map.setCenter(city.geometry.location);
          this.map.setZoom(14);
          this.searchPizzeria(city);
          this.autocompleteDisabled = false;
          this.$refs["pizzeria"].value = "";
          this.$refs["pizzeria"].focus();
        });
      }
    },
    searchPizzeria(city) {
      if (!!this.map) {  
        const params = {
          componentRestrictions: { country: ["it"] },
          types: ["establishment"],
          bounds: this.map.getBounds(),
          origin: this.map.getCenter()
        };
        const autocomplete = new google.maps.places.Autocomplete(this.$refs["pizzeria"], params);
        google.maps.event.addDomListener(this.$refs["pizzeria"], 'keydown', function(event) { 
          if (event.keyCode === 13) { 
            event.preventDefault(); 
          }
        });
        autocomplete.addListener("place_changed", () => {
          const pizzeria = autocomplete.getPlace();
          this.setLocation(pizzeria);
        });
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="d-flex flex-column flex-md-row align-items-md-end mb-3">
      <div class="form-group mb-md-0 mr-3 w-100">
        <label class="form-label">{{ft("maps.search_city")}}</label>
        <input type="text" class="form-control" :placeholder="ft('maps.search_city')" ref="city" />
      </div>
      <div class="form-group mb-0 w-100">
        <label class="form-label">{{ft("maps.search_place")}}</label>
        <input :disabled="autocompleteDisabled" type="text" class="form-control" :placeholder="ft('maps.search_place')" ref="pizzeria" />
      </div>
    </div>
    <div class="w-100 position-relative mb-3">
      <div class="ar-16by9">
        <div class="position-absolute w-100 h-100" ref="google-map"></div>
      </div>
    </div>
    <div class="d-none">
      <div ref="info-content">
        <table>
          <tr class="table-row">
            <td class="text-right">
            <img class="place-icon" :src="place.icon"/>
            </td>
            <td>
              <b>
                <a :href="place.url">{{place.name}}</a>
              </b>
            </td>
          </tr>
          <tr class="table-row">
            <td class="font-weight-bold text-right">{{ft("maps.address")}}:</td>
            <td>{{place.vicinity}}</td>
          </tr>
          <tr class="table-row" v-if="place.formatted_phone_number">
            <td class="font-weight-bold text-right">{{ft("maps.telephone")}}:</td>
            <td>{{place.formatted_phone_number}}</td>
          </tr>
          <tr class="table-row" v-if="place.rating">
            <td class="font-weight-bold text-right">{{ft("maps.rating")}}:</td>
            <td v-html="rating"></td>
          </tr>
          <tr class="table-row" v-if="place.website">
            <td class="font-weight-bold text-right">{{ft("maps.website")}}:</td>
            <td>
              <a :href="website">{{website}}</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-row {
  height: 1.125rem;
}

.place-icon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
}
</style>
