<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      videoPlayer: {},
      countDownStopped: false
    };
  },
  watch: {
    "videoPlayer.end"(newValue, previousValue) {
      if (newValue) {
        this.countDownStopped = false;
      }
    }
  },
  methods: {
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" course-product-video-chapter-component>
    <div class="bg-black position-relative">
      <embed-advanced :content="content" 
                      :opt-container-content="containerContent"
                      @video-player-handler="videoPlayer = arguments[0]"
                      ref="embed-advanced"
      ></embed-advanced>
      <div class="d-flex justify-content-center align-items-center flex-column py-4 text-center text-white bg-black position-absolute-center" v-if="videoPlayer.end && !countDownStopped">
        <course-countdown
          :content="content"
          :opt-container-content="containerContent"
          :initalCountdown="3"
          @next-bookmark="$emit('next-bookmark')"
          @countdown-stopped="countDownStopped = true"
        >  
        </course-countdown>
      </div>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
	</div>
</template>

<style lang="scss">
[course-product-video-chapter-component] {
}
</style>