<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { sendFandomAnalyticsEvent }  from "src/modules/fandom_analytics_module.js";

export default {
  mixins: [fandomBasicMixin],
  computed: {
    showItemsBorders() {
      return Fandom.exists(this.content.show_items_borders) ? this.content.show_items_borders : false;
    }
  },
  methods: {
    formSubmitDone (data) {
      if (data.success) {
        sendFandomAnalyticsEvent("Simple Form", "Submit", `content_${this.contentId}_interaction_${this.content.interaction.id}`);
      }
    },
    getContentStyle () {
      var styleObject = {};
      if (Fandom.exists(this.content.background_color)) {
        styleObject["background-color"] = this.content.background_color;
      }
      if (Fandom.exists(this.content.background_image)) {
        styleObject["background-image"] = this.getContentResponsiveImageURL("background_image");
      }
      if (Fandom.exists(this.content.text_color)) {
        styleObject["color"] = this.content.text_color;
      }
      return styleObject;
    }
  }
};
</script>

<template>
  <div :id="content.name" v-easyadmin="easyadminId" class="bg-cover-center" :style="getContentStyle()" simple-form-component>
    <div class="container" :class="{'borderless-items': !showItemsBorders}">
      <div class="row justify-content-center align-items-center py-4">
        <div class="col-sm-12 col-md-8 col-lg-6">
          <p class="text-center font-sm pb-4 mb-1 text-medium-grey">{{content.title}}</p>
          <generic-form :content="content" :opt-container-content="containerContent" ref="form" @done="formSubmitDone"></generic-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[simple-form-component] {
  .borderless-items {
    .form-control,
    .custom-select,
    .cuustom-file-input {
      border-width: 0px;
    }
  }
}
</style>
  
