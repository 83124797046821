<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  mounted() {

  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" tile-carousel>
    <div class="tile-carousel d-flex flex-column align-items-center justify-content-center pt-3 pb-5">
      <div class="abstract text-center abstract-text text-black py-1">{{content.abstract}}</div>
      <h2 class="text-center title-text text-black py-1">{{content.title}}</h2>
      <div class="img-container text-center">
        <img class="img-fluid" :src="content.image.url" />
      </div>
      <div class="description-text description text-center pb-md-4 mw-900" v-html="content.description"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[tile-carousel] {

  .img-container {
    max-width: 1000px;
  }

  .abstract-text {
    font-size: 26px;
    font-weight: bold;
  }

  .title-text {
    font-size: 38px;
  }

  .description-text {
    font-size: 20px;
    color: $medium-grey;
  }

  .mw-900 {
    max-width: 900px;
  }

  @include media-breakpoint-down(xs) { 
    .abstract-text {
      font-size: 20px;
    }

    .title-text {
      font-size: 34px;
    }

    .description-text {
      font-size: 18px;
      line-height: 26px;
    }

  }
}
</style>
