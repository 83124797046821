<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getContentURL: Fandom.getContentURL,
  },
  computed: {
    owner() {
      if(Fandom.exists(this.content.owned_by) && this.pageModel.id_to_user[this.content.owned_by]) {
        return this.pageModel.id_to_user[this.content.owned_by];
      } else {
        return null;
      }
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="position-absolute-center bg-transition" tile-component>
    <a :href="getContentURL(content)" :target="getTarget(content.url)" class="deco-none position-absolute-center">
      <div class="bg-cover-center position-absolute-center bg" :style="{'background-image': getTileThumbnailUrl(content)}"></div>
      <div v-if="!content.hide_gradient" class="position-absolute-center linear-gradient-y"></div>
      <reward-marker class="mb-3" :content="content" :container-content="containerContent"></reward-marker>
      <div class="tile-footer p-2">
        <miniformat-badge v-if="decorator" :miniformat="decorator" class="mb-2"></miniformat-badge>
        <div class="tile-footer__user-info" v-if="owner">
          <img v-if="owner.avatar" :src="expandUrl(owner.avatar.url)" class="tile-footer__user-info__avatar" />
          <p class="tile-footer__user-info__name">{{owner.first_name}} {{owner.last_name}}</p>
        </div>
        <span class="d-inline-block h3 m-0 tile-title">{{content.title}}</span>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-component] {
  color: $white;

  [reward-marker-component] {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .tile-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .tile-title {
      @include font-size(1rem);
    }

    .tile-footer__user-info {
      display: flex;
      align-items: center;
      margin: 1rem 0;
    }

    .tile-footer__user-info__avatar {
      max-width: 40px;
      border-radius: 100%;
      border: 2px solid gray;
      margin-right: 1rem;
    }

    .tile-footer__user-info__name {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
