<script>
import Flickity from "flickity";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get, union } from "lodash";

const minimizeMenuItem = content => {
  return {
    slug: get(content, ["url", "url"], content.slug),
    color: content.color,
    title: content.title,
    name: content.name
  };
};

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      carousel: false
    };
  },
  mounted() {
    if (this.menu.length > 0) {
      Vue.nextTick(this.toggleCarousel);
      $(window).on("debouncedResize", this.toggleCarousel);
    }
  },
  beforeDestroy() {
    $(window).off("debouncedResize");
  },
  methods: {
    isActive(menuItem) {
      return this.mainContent.name.includes(menuItem.name);
    },
    toggleCarousel() {
      if (!this.carousel && this.isMobile) {
        this.carousel = new Flickity(this.$refs["menu"], {
          draggable: ">1",
          prevNextButtons: false,
          cellAlign: "left",
          wrapAround: false,
          freeSCroll: false,
          pageDots: false
        });
      }
      if (this.carousel && !this.isMobile) {
        this.carousel.destroy();
        this.carousel = false;
      }
    },
  },
  computed: {
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    menu() {
      return this.childrenMenu.concat(this.contentMenu).map(minimizeMenuItem);
    },
    ordering() {
      const ordering = get(this, ["content", "ordering"], []);
      return typeof ordering === "string" ? ordering.split(",") : ordering;
    },
    childrenMenu() {
      const children = get(this, ["content", "children"], []);
      return union(this.ordering, children).map(Fandom.getContent).filter(Fandom.exists);
    },
    contentMenu() {
      return get(this, ["content", "icons"], []);
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="row justify-content-end" v-if="menu.length > 0">
      <div class="col-12 flickity-carousel" :class="{'d-flex justify-content-center flex-wrap': !isMobile}" ref="menu">
        <a
          v-for="(item, index) in menu"
          class="d-flex flex-column align-items-center text-center url py-3" 
          :href="applyContextToUrl(item.slug)"
          :class="{'active': isActive(item)}"
          :title="item.title"
          :key="index"
          :style="{'--color': item.color}"
          >
          <div class="position-relative rounded-circle">
            <div class="dot position-absolute-center w-100 h-100 rounded-circle"></div>
            <div class="color-dot rounded-circle"></div>
          </div>
          <span class="title d-flex align-items-center text-uppercase mt-3 px-3 font-weight-bold">{{item.title}}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$size: 2rem;
$transition-time: .5s;
$scale: 1.5;

.url {
  color: $black;
  transition: color $transition-time ease-in-out;
  text-decoration: none;
  width: 7rem;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: var(--color);

    .dot {
      transform: scale3d($scale, $scale, $scale);
    }
  }
}

.dot {
  border: 1px solid var(--color);
  transition: transform $transition-time ease-in-out;
}

.color-dot {
  width: $size;
  height: $size;
  background-color: var(--color);
  transition: background-color $transition-time ease-in-out;
}

.title {
  height: $size * 0.875;
  @include font-size($size / 2);
  white-space: nowrap;
}
</style>
