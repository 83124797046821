<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    openModal() {
      $("#modal-thankyou").modal("show");
    }
  },
  mounted() {
    Vue.nextTick(() => {
      if (Fandom.readCookie("signup-thankyou-modal")) {
        this.openModal();
        Fandom.deleteCookie("signup-thankyou-modal");
      }
    });
  }
};
</script>

<template>
  <div modal-thankyou-component modal-components>
    <div id="modal-thankyou" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" v-easyadmin="easyadminId" :class="contentType">
          <div class="modal-header border-bottom-0 pb-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span class="close-icon italia-lt-font" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <div class="row justify-content-center">
              <div class="col-lg-10 pb-4">
                <h5 class="modal-title text-uppercase text-red">{{content.title}}</h5>
                <div v-if="content.description" class="user-edit-description font-lg" v-html="content.description"></div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[modal-thankyou-component] {
  .close-icon {
    font-family: $italia-lt-font;
  }
}
</style>