<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js"

export default {
  props: {
    index: Number,
    lazyloadBg: {
      type: Boolean,
      default: false
    }
  },
  mixins: [fandomBasicMixin, interactionMixin],
  methods: {
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    openStory() {
      if(this.content.chapters.length > 0) {
        this.$emit("tile-story-opened", this.content);
        sendFandomAnalyticsEvent("Interaction", "Stories Click", this.content.title);
      }
    }
  },
  computed: {
    thumbStyle() {
      const alreadyLoaded = Fandom.exists(this.$refs["thumbnail"]) ? !this.$refs["thumbnail"].classList.contains("flickity-bg-lazyloaded") : false;
      return {
        "background-image": (this.lazyloadBg && alreadyLoaded) ? false : this.getContentResponsiveImageURL("thumbnail")
      };
    },
    lazyThumb() {
      return this.lazyloadBg ? this.getContentResponsiveImage("thumbnail") : false;
    }
  }
};
</script> 

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" class="w-100 position-relative px-2" tile-story>
    <a class="position-absolute-center open-story" href="javascript:void(0)" @click="openStory"></a>
    <div class="story-image position-relative overflow-hidden rounded">
      <div class="position-absolute-center bg-cover-center bg-transition w-100" ref="thumbnail" :style="thumbStyle" :data-flickity-bg-lazyload="lazyThumb"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.open-story {
  z-index: 2;
}

.story-image {
  padding-bottom: 140%;
}
</style>
