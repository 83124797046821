<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { get } from 'lodash';
import { multiLangMixin } from "../../modules/cinque_stagioni_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, multiLangMixin],
  data: function() {
    return {
      langOpen: false,
    };
  },
  mounted() {
    Vue.nextTick(() => {
      disableBodyScroll(this.$el);
      if (Fandom.exists(this.$refs["langs"])) {
        $(this.$refs["langs"]).on("show.bs.collapse", () => {
          this.langOpen = true;
        });
        $(this.$refs["langs"]).on("hide.bs.collapse", () => {
          this.langOpen = false;
        });
      }
    });
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    getTarget: Fandom.getTarget,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getItemUrl(item) {
      return item.url.url == "#" ? "javascript:void(0)" : item.url.url;
    }
  },
  computed: {
    user() {
      return get(this.globalState.pageModel, ["user"], false);
    },
  },
};
</script>

<template>
  <div class="position-fixed mh-100vh" sidebar-component>
    <div class="position-absolute h-100 w-100 bg-body-bg">
      <div class="px-3 py-2 border-bottom w-100 font-weight-bold position-relative">
        <div class="mx-3 my-1">
          {{ ft("globals.menu") }}
        </div>
        <div class="close-icon position-absolute" @click="$emit('sidebarClose', true)">
          <i class="fal fa-times"></i>
        </div>
      </div>
      <div v-for="(item, index) in content.menu" class="px-3 py-2 font-weight-bold border-bottom w-100" :key="index">
        <div class="my-1 d-flex justify-content-between align-items-center">
          <a class="mx-3 link-black w-100 text-uppercase" :href="getItemUrl(item)" :target="getTarget(item.url)">
            <span v-for="(word, wIndex) in (item.url.title || '').split(' ')" :class="{'text-uppercase': wIndex > 0, 'text-lowercase': wIndex === 0}" :key="`${index}-${wIndex}-word`">{{word}} </span>
          </a>
          <a class="collapsed d-flex align-items-center" :href="`#submenu-mobile-${index}`" data-toggle="collapse">
            <i v-if="item.menu" class="far far fa-chevron-down text-black"></i>
          </a>
        </div>
        <div v-if="item.menu" class="collapse font-weight-bold w-100" :id="`submenu-mobile-${index}`">
          <div class="py-2">
            <div class="mx-3 my-1 subitem" v-for="(subitem, subindex) in item.menu" :key="`menu-${index}-${subindex}`">
              <a class="link-black w-100" :href="subitem.url.url" :class="subitem.custom_class" :target="getTarget(subitem.url)">
                {{subitem.url.title}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="px-3 py-2 font-weight-bold border-bottom w-100" v-if="content.support_url">
        <div class="mx-3 my-1 d-flex justify-content-between align-items-center">
          <a class="link-black w-100 text-uppercase" :href="applyContextToUrl(content.support_url.url)" :target="getTarget(content.support_url)">
            {{ content.support_url.title }}
          </a>
        </div>
      </div>
      <template v-if="!user || user.anonymous">
        <div class="px-3 py-2 font-weight-bold border-bottom w-100">
          <div class="mx-3 my-1">
            <a class="link-black w-100 text-uppercase" :href="applyContextToUrl('/users/sign_in')">
              {{ ft("globals.login.login") }}
            </a>
          </div>
        </div>
        <div class="px-3 py-2 font-weight-bold border-bottom w-100 bg-red">
          <div class="mx-3 my-1">
            <a class="link-white w-100 text-uppercase" :href="applyContextToUrl('/users/sign_up')">
              {{ ft("globals.login.sign_up") }}
            </a>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="px-3 py-2 border-bottom font-weight-bold w-100 bg-red" v-if="content.profile_menu" sidebar-item-component>
          <a class="pl-3 py-1 d-flex justify-content-between align-items-center text-uppercase subitem link-white text-uppercase collapsed" href="#profile-menu-mobile" data-toggle="collapse">
            {{ft("globals.profile")}}
            <i class="far fa-chevron-down"></i>
          </a>
        </div>
        <div class="collapse px-3 py-2 border-bottom font-weight-bold w-100" id="profile-menu-mobile">
          <div class="mx-3 my-1 text-uppercase subitem" v-for="(item, index) in content.profile_menu" :key="`profile-menu-${index}`">
            <a class="link-black w-100 text-uppercase" :href="item.url.url" :class="item.custom_class" :target="getTarget(item.url)">
              {{item.url.title}}
            </a>
          </div>
        </div>
      </template>
      <template v-if="langs.length > 0">
        <a
          class="mx-3 py-3 mb-1 d-flex justify-content-between align-items-center link-black text-uppercase font-weight-bold border-bottom collapsed"
          :class="{'opacity-35': langOpen}"
          href="#lang-dropdown"
          data-toggle="collapse"
          >
          <span class="d-flex align-items-center pl-3">
            <svg class="lang-icon border border-dark rounded-circle">
              <use :xlink:href="`#${currentLang}`"></use>
            </svg>
            <span class="ml-3">{{ft(`globals.langs.${currentLang}`)}}</span>
          </span>
          <i class="far" :class="{'fa-chevron-down': !langOpen, 'fa-check mirrored': langOpen }"></i>
        </a>
        <div class="collapse border-bottom" id="lang-dropdown" ref="langs">
          <a
            class="px-3 py-2 link-black w-100 d-block text-uppercase font-weight-bold collapsed"
            v-for="(lang, index) in langs"
            :key="`lang-${index}-${lang}`"
            :href="redirectTolang(lang)"
            >
            <span class="d-flex align-items-center pl-3">
              <svg class="lang-icon border border-dark rounded-circle">
                <use :xlink:href="`#${lang}`"></use>
              </svg>
              <span class="ml-3">{{ft(`globals.langs.${lang}`)}}</span>
            </span>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
[sidebar-component] {
  [sidebar-item-component], & {
    @each $color-name, $color-val in $agugiaro-figna-colors {
      .sidebar-link-#{$color-name} {
        transition: all 0.3s;
        color: $black;
        &:hover, &:active, &:focus, &:visited {
          color: $color-val;
        }
        &.collapsed {
          color: $black;
        }
      }
    }

    .subitem {
      & > a:not(.collapsed),
      & > a:not(.collapsed) > i {
        transition: all .25s;
      }

      & > a:not(.collapsed) > i {
        transform: rotate(90deg);
      }
    }

    .url-lg {
      font-family: $italia-lt-font;
    }
  }
}
</style>
